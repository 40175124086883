import {
    collection,
    doc,
    getCountFromServer,
    getDocs,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    QuerySnapshot,
    setDoc,
    Unsubscribe,
    where,
    writeBatch
} from "firebase/firestore";
import INotification, {NotificationEvent} from "../model/firestore/Notification";
import {tt} from "../core/Localization";
import {getEmployeeJobStatusTitle} from "./VisitService";
import {JobEmployeeStatus} from "../generated/graphql/graphql";

const kNotificationsCollection = 'notifications';

/**
 * Create new or update existing Notification on Firestore.
 */
export async function saveNotification(notification: INotification): Promise<void> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kNotificationsCollection);

    if (notification.id) {
        await setDoc(doc(theCollection, notification.id), notification);
    } else {
        await setDoc(doc(theCollection), notification);
    }
}

/**
 * Count number of unRead Notifications in Firestore.
 */
export async function countUnReadNotifications(employeeId: number): Promise<number> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kNotificationsCollection);

    const theQuery = query(
        theCollection,
        where('read', '==', false),
        where('forEmployeeId', '==', employeeId),
    );

    const snapshot = await getCountFromServer(
        theQuery,
    );

    return snapshot.data().count;
}

export const kNotificationsPageSize = 10;
export const kMaxNotificationsPages = 10;

export interface ISubscribeNotificationsParams {
    employeeId: number;
    onNext: (snapshot: QuerySnapshot) => void;
    read?: boolean;
    unreadOnly?: boolean;
    loadPages?: number;
    queryLimit?: number;
}

/**
 * Create subscription to Notifications collection.
 */
export function subscribeNotifications(params: ISubscribeNotificationsParams): Unsubscribe {
    const {employeeId, onNext, read, unreadOnly, loadPages, queryLimit} = params;

    const theRead = read === undefined ? false : read;

    const firestore = getFirestore();
    const theCollection = collection(firestore, kNotificationsCollection);

    if (unreadOnly !== undefined && unreadOnly) {
        const theQuery = loadPages || queryLimit ? query(
            theCollection,
            where('read', '==', false),
            where('forEmployeeId', '==', employeeId),
            orderBy('createdAt', 'desc'),
            limit(queryLimit ? queryLimit : kNotificationsPageSize * loadPages!),
        ) : query(
            theCollection,
            where('read', '==', false),
            where('forEmployeeId', '==', employeeId),
            orderBy('createdAt', 'desc'),
        );

        return onSnapshot(theQuery, onNext);
    } else if (unreadOnly !== undefined && !unreadOnly) {
        const theQuery = loadPages || queryLimit ? query(
            theCollection,
            where('forEmployeeId', '==', employeeId),
            orderBy('createdAt', 'desc'),
            limit(queryLimit ? queryLimit : kNotificationsPageSize * loadPages!),
        ) : query(
            theCollection,
            where('forEmployeeId', '==', employeeId),
            orderBy('createdAt', 'desc'),
        );

        return onSnapshot(theQuery, onNext);
    } else {
        const theQuery = loadPages || queryLimit ? query(
            theCollection,
            where('read', '==', theRead),
            where('forEmployeeId', '==', employeeId),
            orderBy('createdAt', 'desc'),
            limit(queryLimit ? queryLimit : kNotificationsPageSize * loadPages!),
        ) : query(
            theCollection,
            where('read', '==', theRead),
            where('forEmployeeId', '==', employeeId),
            orderBy('createdAt', 'desc'),
        );

        return onSnapshot(theQuery, onNext);
    }
}

/**
 * Use batches to mark all notifications as read.
 */
export async function markAllNotificationsAsRead(employeeId: number): Promise<void> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kNotificationsCollection);

    const theQuery = query(
        theCollection,
        where('read', '==', false),
        where('forEmployeeId', '==', employeeId),
    );

    const snapshot = await getDocs(theQuery);

    let theBatch = writeBatch(firestore);
    let batchCount = 0;

    for (const doc of snapshot.docs) {
        theBatch.update(doc.ref, {read: true});
        batchCount++;

        if (batchCount >= 450) {
            await theBatch.commit();

            theBatch = writeBatch(firestore);
            batchCount = 0;
        }
    }

    if (batchCount > 0) {
        await theBatch.commit();
    }
}

/**
 * Display NotificationEvent as translated string.
 */
export function notificationEventToString(notification: INotification): string {
    switch (notification.event) {
        case NotificationEvent.EmployeeUpdateEmployeeStatus:
            return tt('common.notification.employeeUpdateEmployeeStatus')
                .replace('{employeeStatus}', getEmployeeJobStatusTitle(notification.employeeStatus || JobEmployeeStatus.None));
        case NotificationEvent.JobFormElementDoneStateChangedByWorker:
            return tt('common.notification.jobFormElementDoneStateChangedByWorker');
        case NotificationEvent.EmployeeResponseToJobOfferSeatAccepted:
            return tt('common.notification.employeeResponseToJobOfferSeatAccepted');
        case NotificationEvent.EmployeeResponseToJobOfferSeatRejected:
            return tt('common.notification.employeeResponseToJobOfferSeatRejected');
        case NotificationEvent.RemoveSubstituteFromJobOfferSeat:
            return tt('common.notification.removeSubstituteFromJobOfferSeat');
        case NotificationEvent.AddDefectToJob:
            return tt('common.notification.addDefectToJob');
        case NotificationEvent.LayoffEmployeeBySelf:
            return tt('common.notification.layoffEmployeeBySelf');
        case NotificationEvent.ConsumeInvite:
            return tt('common.notification.consumeInvite');
        case NotificationEvent.AddJobEmployeeTimesheetItemByWorker:
            return tt('common.notification.addJobEmployeeTimesheetItemByWorker');
        case NotificationEvent.UpdateJobEmployeeTimesheetItemByWorker:
            return tt('common.notification.updateJobEmployeeTimesheetItemByWorker');
        case NotificationEvent.AddProtocolToJob:
            return tt('common.notification.addProtocolToJob');
        case NotificationEvent.AddMaterialToVisit:
            return tt('common.notification.addMaterialToVisit');
        case NotificationEvent.AddProductToVisit:
            return tt('common.notification.addProductToVisit');
        case NotificationEvent.CreateVisitForJob:
            return tt('common.notification.createVisitForJob');
        case NotificationEvent.ScheduleVisitForJob:
            return tt('common.notification.scheduleVisitForJob');
        case NotificationEvent.CancelVisitByManager:
            return tt('common.notification.cancelVisitByManager');
        case NotificationEvent.ReactivateVisit:
            return tt('common.notification.reactivateVisit');
        case NotificationEvent.DeleteVisit:
            return tt('common.notification.deleteVisit');
        case NotificationEvent.VisitStatusToSolve:
            return tt('common.notification.visitStatusToSolve');
        case NotificationEvent.AddOutputDocumentToVisit:
            return tt('common.notification.addOutputDocumentToVisit');
        case NotificationEvent.DeleteOutputDocumentFromVisit:
            return tt('common.notification.deleteOutputDocumentFromVisit');
        default:
            return '';
    }
}
