import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import {AppDataContext} from "../../../AppData";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import GreyLabel from "../../components/decorations/GreyLabel";
import AppListItem from "../../components/listItems/AppListItem";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../service/UserService";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import {Box, Divider, Theme} from "@mui/material";
import HeadlineWithButton from "../../screenSections/detailListPreviewSection/HeadlineWithButton";
import AppTabsComponent from "../../components/AppTabsComponent";
import {makeStyles} from "tss-react/mui";
import ChooseWorkersModalBottomSheet from "../../components/modals/job/editJob/ChooseWorkersModalBottomSheet";
import NewJobChosenWorkerItem from "../../components/jobs/newJob/NewJobChosenWorkerItem";
import {
    ClientContactResponsePage,
    ClientResponse,
    CompanyConfigurationResponsePage,
    CompanyConfigurationType,
    CreateCompanyConfigurationInput,
    CreateJobDocument,
    CreateJobEmployeeTimesheetItemInput,
    CreateJobMutation,
    CreateJobMutationVariables,
    CreateJobOfferSeatInput,
    CreateMaterialInput,
    CreateProductInput,
    CreateVisitForJobInput,
    CreateVisitInput,
    CreateVisitJobFormInput,
    CustomDateInput,
    EmployeeDetailResponse,
    EmployeeJoinedUserResponsePage,
    EmployeePermissionsMapResponse,
    FileResponsePage,
    GetClientContactsForIdsInput,
    GetClientInput,
    GetEmployeeDetailInput,
    GetEmployeesJoinedUsersInput,
    GetFilesByIdsInput,
    GetJobFormsByIdsInput,
    GetJobFormsInput,
    GetJobInput,
    GetLocationInput,
    GetLocationPlacesByIdsInput,
    GetVisitInput,
    GetVisitJoinedOthersConflictsInput,
    ItemPaymentType,
    JobDetailResponse,
    JobFormResponse,
    JobFormResponsePage,
    JobOfferSeatResponse,
    LocationPlaceResponsePage,
    LocationPureResponse,
    PaymentType,
    ScheduleVisitForJobDocument,
    ScheduleVisitForJobMutationVariables,
    VisitDetailResponse,
    VisitJoinedOthersConflictsResponsePage, VisitListPureJoinedOthersConflictsResponsePage, VisitListPureResponse,
    VisitRepeating,
    VisitRepeatModifier,
    VisitResponse,
    VisitStatus
} from "../../../generated/graphql/graphql";
import ChooseLocationModalBottomSheet from "../../components/modals/job/editJob/ChooseLocationModalBottomSheet";
import ChoosePlaceModalBottomSheet from "../../components/modals/job/editJob/ChoosePlaceModalBottomSheet";
import {useMutation} from "@apollo/client";
import {DateTime} from "luxon";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import SplitButton from "../../components/buttons/SplitButton";
import Icons8Location from "../../../icons/Icons8Location";
import JobFormItem from "../../components/jobs/newJob/JobFormItem";
import ChooseJobFormModalBottomSheet, {
    IChooseJobFormModalBottomSheetOnSaveParams
} from "../../components/modals/job/editJob/ChooseJobFormModalBottomSheet";
import NewJobPlaceItem from "../../components/jobs/newJob/NewJobPlaceItem";
import VisitOfferItem from "../../components/jobs/visits/visitDetail/VisitOfferItem";
import JobOfferModalBottomSheet, {IJobOfferData} from "../../components/modals/job/JobOfferModalBottomSheet";
import {arrayUnique, getBackRoute} from "../../../utils/Utils";
import IFileState, {IFileStateType} from "../../../model/FileState";
import {v4 as uuidv4} from 'uuid';
import AttachmentListItem from "../../components/jobs/attachments/AttachmentListItem";
import PreviewFileModal from "../../components/modals/documents/PreviewFileModal";
import {kStorageCategoryJobDocuments} from "../../../service/StorageService";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import NewVisitCalendar from "../../components/jobs/visits/NewVisitCalendar";
import VisitForm from "../../components/jobs/visits/VisitForm";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import IVisitEvent from "../../../model/VisitEvent";
import Icons8Reset from "../../../icons/Icons8Reset";
import Icons8Company from "../../../icons/Icons8Company";
import UserIcon from "../../../icons/UserIcon";
import PhoneMenu from "../../components/menus/PhoneMenu";
import NewJobContactPerson from "../../components/jobs/newJob/NewJobContactPerson";
import ChooseClientContactModalBottomSheet
    from "../../components/modals/job/editJob/ChooseClientContactModalBottomSheet";
import CreateLocationModal from "../../components/locations/CreateLocationModal";
import CreateLocationPlaceModal from "../../components/places/CreateLocationPlaceModal";
import CreateContactPersonModal from "../../components/contactPerson/CreateContactPersonModal";
import CreateJobFormModal, {
    INewJobFormModalPropsOnCreateParams
} from "../../components/modals/jobForms/CreateJobFormModal";
import VisitEmployeesTotalSection from "../../components/jobs/visits/VisitEmployeesTotalSection";
import {JobOfferSeatResponseToCreateInput, visitDateTimes, VisitsProcess} from "../../../service/VisitService";
import Icons8Plus from "../../../icons/Icons8Plus";
import Icons8Contacts from "../../../icons/Icons8Contacts";
import Icons8Here from "../../../icons/Icons8-here";
import Icons8Upload from "../../../icons/Icons8Upload";
import Icons8ClipBoardList from "../../../icons/Icons8ClipBoardList";
import Icons8Reseller from "../../../icons/Icons8Reseller";
import CreateWorkerModal from "../../components/workers/CreateWorkerModal";
import {jobDetailRoute} from "./JobDetailScreen";
import AppButton from "../../components/buttons/AppButton";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import Icons8Change from "../../../icons/Icons8Change";
import ChooseResponsiblePersonModalBottomSheet
    from "../../components/modals/job/editJob/ChooseResponsiblePersonModalBottomSheet";
import ChooseClientModalBottomSheet from "../../components/modals/job/editJob/ChooseClientModalBottomSheet";
import CreateClientModal from "../../components/clients/CreateClientModal";
import {clientDetailRoute} from "../clients/ClientDetailScreen";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {convertJobEmployeeTimesheetItemToCreateInput} from "../../../service/TimesheetService";
import {convertProductResponseToCreateInput} from "../../../service/ProductService";
import {kDashboardRoute} from "../dashboard/DashboardScreen";
import AssignToExistingJobSection from "../../components/jobs/newJob/AssignToExistingJobSection";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import DebouncedForm from "../../components/form/DebouncedForm";
import {IDuplicateOptionsProps} from "../../components/modals/job/visits/DuplicateVisitOptionsModal";
import NewJobLocationItem from "../../components/jobs/newJob/NewJobLocationItem";
import {convertMaterialResponseToCreateInput} from "../../../service/MaterialService";
import {
    kActionCreate,
    kActionUpdate,
    kActionView,
    kCompanyConfigurationAutoJobEmployeeTimesheets,
    kCompanyConfigurationOverrideForVisitsEmployeeTimesheets,
    kPermissionsClients,
    kPermissionsCompanyConfiguration,
    kPermissionsForms,
    kPermissionsMaterials,
    kPermissionsProducts,
    kPermissionsVisitAttachments,
    kPermissionsVisitCanBeResponsible,
    kPermissionsWorkers,
    kTopicCompanyEmployees,
    kTopicJobFormTemplates,
    kTopicVisits,
    kUserPreferencesNewJobCalendarVisible
} from "../../../core/constants";
import PermissionValid, {hasPermission} from "../../components/permissions/PermissionValid";
import {displayClientTaxInfoAsText} from "../../../service/ClientService";
import {
    companyConfigurationJobEmployeeTimesheets,
    subscribeToCompanyConfigurationResponsePage
} from "../../../service/CompanyConfigurationService";
import NewVisitConfigurationsModalBottomSheet
    from "../../components/modals/job/visits/NewVisitConfigurationsModalBottomSheet";
import SettingsIcon from "../../../icons/SettingsIcon";
import NewJobProductMaterialsSection from "../../components/jobs/newJob/NewJobProductMaterialsSection";
import VisitTotalSection from "../../components/jobs/visits/VisitTotalSection";

export const kNewJobAndVisitRoute = '/jobs/new';
export const kNewJobAndVisitForJobRoute = '/jobs/:jobId/visit/new';
export const kNewJobAndVisitForClientRoute = '/clients/:clientId/jobs/new';

export enum NewJobAndVisitRouteKind {
    default,
    fromJobDetail,
    fromClientDetail,
}

export interface INewJobAndVisitRouteParams {
    kind?: NewJobAndVisitRouteKind;
    jobId?: number | string;
    visitId?: number | string;
    urlParams?: string[];
    clientId?: number | string;
    fromDate?: DateTime;
    toDate?: DateTime;
    wholeDay?: boolean;
    duplicateVisitId?: number;
    duplicateRepeatingDay?: number;
    duplicateOptions?: IDuplicateOptionsProps;
}

/**
 * Create route with params.
 */
export function newJobAndVisitRoute(
    params: INewJobAndVisitRouteParams,
): string {
    const {
        kind,
        jobId,
        visitId,
        urlParams,
        clientId,
        fromDate,
        toDate,
        wholeDay,
        duplicateVisitId,
        duplicateRepeatingDay,
        duplicateOptions,
    } = params;

    let route;

    if (kind === NewJobAndVisitRouteKind.fromJobDetail) {
        route = kNewJobAndVisitForJobRoute
            .replace(':jobId', `${jobId}`);
    } else if (kind === NewJobAndVisitRouteKind.fromClientDetail) {
        route = kNewJobAndVisitForClientRoute
            .replace(':clientId', `${clientId}`);
    } else {
        route = kNewJobAndVisitRoute;
    }

    const theUrlsParams: string[] = urlParams || [];

    if (visitId) {
        theUrlsParams.push(`visit-id=${visitId}`);
    }

    if (clientId) {
        theUrlsParams.push(`client-id=${clientId}`);
    }

    if (fromDate) {
        theUrlsParams.push(`from-date=${fromDate.toMillis()}`);
    }

    if (toDate) {
        theUrlsParams.push(`to-date=${toDate.toMillis()}`);
    }

    if (wholeDay) {
        theUrlsParams.push(`whole-day=${wholeDay}`);
    }

    if (duplicateVisitId) {
        theUrlsParams.push(`duplicate-visit-id=${duplicateVisitId}`);
    }

    if (duplicateRepeatingDay) {
        theUrlsParams.push(`duplicate-repeating-day=${duplicateRepeatingDay}`);
    }

    if (duplicateOptions?.workers) {
        theUrlsParams.push(`duplicate-workers=${duplicateOptions?.workers}`);
    }
    if (duplicateOptions?.forms) {
        theUrlsParams.push(`duplicate-forms=${duplicateOptions?.forms}`);
    }
    if (duplicateOptions?.attachments) {
        theUrlsParams.push(`duplicate-attachments=${duplicateOptions?.attachments}`);
    }
    if (duplicateOptions?.products) {
        theUrlsParams.push(`duplicate-services=${duplicateOptions?.products}`);
    }

    if (theUrlsParams.length > 0) {
        route += `?${theUrlsParams.join('&')}`;
    }

    return route;
}

const useStyles = makeStyles()((theme: Theme) => ({
    tabContainer: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    removeVerticalMargins: {
        marginTop: -16,
        marginBottom: -16,
    },
    marginBottom8: {
        marginBottom: 8,
    },
    screenContentInner: {
        width: '100%',
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "center",
        '> .MuiPaper-root': {
            paddingTop: 8,
        },
    },
    dashedDivider: {
        borderBottom: `1px dashed ${kAppColors.border(theme.palette.mode === "dark")}`,
    },
    switchesContainer: {
        '.MuiGrid-item': {
            flexBasis: "unset",
        },
        "@media (max-width: 767px)": {
            '.MuiGrid-item': {
                flexBasis: "100%",
            },
        }
    },
    leftContentPaper: {
        maxWidth: kContentWidthMedium,
        "@media (max-width: 767px)": {
            maxWidth: '100%',
        },
    },
    leftContentPaperCalendarOpen: {
        "@media (min-width: 1700px)": {
            maxWidth: '35%',
        }
    },
}));

export default function NewJobAndVisitScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet, subscribe, restApiPost} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, company, setStorage, employeeId: currentEmployeeId} = appDataContext;
    const timeZone = company?.timeZone;

    const navigate = useNavigate();
    const {jobId, clientId: clientIdInRouteParams} = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const visitIdInParams = searchParams.get('visit-id') as any;
    const clientIdInParams = searchParams.get('client-id') as any;
    const fromDateInParams = searchParams.get('from-date') as any;
    const toDateInParams = searchParams.get('to-date') as any;
    const fromDate = useMemo(() => {
        return fromDateInParams ? DateTime.fromMillis(parseInt(fromDateInParams)) : undefined;
    }, [fromDateInParams]);
    const toDate = useMemo(() => {
        return toDateInParams ? DateTime.fromMillis(parseInt(toDateInParams)) : undefined;
    }, [toDateInParams]);
    const wholeDay = useMemo(() => {
        return searchParams.get('whole-day') === 'true';
    }, [searchParams.get('whole-day')]);
    const duplicateVisitIdInParams = searchParams.get('duplicate-visit-id') ? parseInt(searchParams.get('duplicate-visit-id') as any) : undefined;
    const duplicateRepeatingDayInParams = searchParams.get('duplicate-repeating-day') ? parseInt(searchParams.get('duplicate-repeating-day') as any) : undefined;
    const duplicateVisitOptionsInParams: IDuplicateOptionsProps = {
        workers: searchParams.get('duplicate-workers') === 'true',
        products: searchParams.get('duplicate-services') === 'true',
        forms: searchParams.get('duplicate-forms') === 'true',
        attachments: searchParams.get('duplicate-attachments') === 'true',
    };

    const theClientIdOfParams = clientIdInRouteParams || clientIdInParams;

    let kindOfRoute = NewJobAndVisitRouteKind.default;

    if (jobId && newJobAndVisitRoute({
        jobId,
        kind: NewJobAndVisitRouteKind.fromJobDetail
    }) === window.location.pathname) {
        kindOfRoute = NewJobAndVisitRouteKind.fromJobDetail;
    }
    if (clientIdInParams && newJobAndVisitRoute({
        clientId: clientIdInParams,
        kind: NewJobAndVisitRouteKind.fromClientDetail
    }) === window.location.pathname) {
        kindOfRoute = NewJobAndVisitRouteKind.fromClientDetail;
    }

    const [formInitialized, setFormInitialized] = useState<boolean>(kindOfRoute !== NewJobAndVisitRouteKind.fromJobDetail);
    const [theJobId, setTheJobId] = useState<string | undefined>(jobId);
    const [clientId, setClientId] = useState<number | undefined>(theClientIdOfParams ? parseInt(theClientIdOfParams) : undefined);
    const [resetForms, setResetForms] = useState<number>(0);
    const [locationId, setLocationId] = useState<number>();
    const [locationPlaceId, setLocationPlaceId] = useState<number>();
    const [clientContactIds, setClientContactIds] = useState<number[]>([]);
    const [selectedTab, setSelectedTab] = useState<string>('0');
    const [responsiblePerson, setResponsiblePerson] = useState<number>();
    const [requiredEmployeeIds, setRequiredEmployeeIds] = useState<number[]>([]);
    const [employeeTimesheets, setEmployeeTimesheets] = useState<CreateJobEmployeeTimesheetItemInput[]>([]);
    const [jobOfferSeats, setJobOfferSeats] = useState<CreateJobOfferSeatInput[]>([]);
    const [attachments, setAttachments] = useState<IFileState[]>([]);
    const [existingAttachments, setExistingAttachments] = useState<number[]>([]);
    const [documentsModal, setDocumentsModal] = useState<boolean>(false);
    const [documentsModalIndexOnOpen, setDocumentsModalIndexOnOpen] = useState<number>(0);
    const [products, setProducts] = useState<CreateProductInput[]>([]);
    const [materials, setMaterials] = useState<CreateMaterialInput[]>([]);
    const [visitConfigurationUuid] = useState<string>(uuidv4());
    const [visitConfigurations, setVisitConfigurations] = useState<CreateCompanyConfigurationInput[]>([]);
    const [companyConfigurationOverrideWorkers, setCompanyConfigurationOverrideWorkers] = useState<boolean>(false);

    const [chooseContacts, setChooseContacts] = useState(false);
    const [contactsForClientId, setContactsForClientId] = useState<number>();
    const [contactsForLocationId, setContactsForLocationId] = useState<number>();
    const [contactsForPlaceId, setContactsForPlaceId] = useState<number>();

    const [jobForms, setJobForms] = useState<CreateVisitJobFormInput[]>([]);
    const [formsForLocationId, setFormsForLocationId] = useState<number>();
    const [formsForPlaceId, setFormsForPlaceId] = useState<number>();

    const [companyConfigurationResponsePage, setCompanyConfigurationResponsePage] = useState<CompanyConfigurationResponsePage | NullOrUndefined>();
    const [companyConfigurationLoading, setCompanyConfigurationLoading] = useState<boolean>(false);
    useEffect(() => {
        if (companyId) {
            const subscription = subscribeToCompanyConfigurationResponsePage(
                restApiClientContext,
                appDataContext,
                {
                    input: {
                        companyId,
                        typeIn: [CompanyConfigurationType.JobEmployeeTimesheets],
                    },
                    setLoading: setCompanyConfigurationLoading,
                    onData: setCompanyConfigurationResponsePage,
                },
            );

            return () => {
                subscription.cancel();
            };
        }
    }, [companyId]);

    const [responsiblePersonsLoading, setResponsiblePersonsLoading] = useState<boolean>(false);
    useEffect(() => {
        if (companyId && currentEmployeeId) {
            const subscription = subscribe(
                kTopicCompanyEmployees,
                {
                    uri: '/company/employee/search-joined-users',
                    params: {
                        companyId: companyId,
                        active: true,
                        includePermissionsMapJSON: true,
                    } as GetEmployeesJoinedUsersInput,
                    setLoading: setResponsiblePersonsLoading,
                    onData: (data: EmployeeJoinedUserResponsePage | NullOrUndefined) => {
                        if (data && currentEmployeeId) {
                            const validEmployees = data.content.filter(person => {
                                const permissionsForEmployee = data!.employeePermissions?.find((permissions: EmployeePermissionsMapResponse) => permissions.employeeId === person.id);

                                return permissionsForEmployee && hasPermission(kPermissionsVisitCanBeResponsible, [kActionUpdate], JSON.parse(permissionsForEmployee.permissionsMapJSON));
                            });

                            const validEmployee = validEmployees.find(person => person.id === currentEmployeeId);

                            if (validEmployee) {
                                setResponsiblePerson(currentEmployeeId);
                            } else {
                                setResponsiblePerson(validEmployees[0].id);
                            }
                        }
                    },
                    onError: (error) => {
                        processQueryError(appDataContext, error);
                    },
                },
                () => true,
            );

            return () => {
                subscription.cancel();
            };
        }
    }, [companyId, currentEmployeeId]);

    const [clientLoading, setClientLoading] = useState<boolean>(false);
    const [clientData, setClientData] = useState<ClientResponse | NullOrUndefined>();
    useEffect(() => {
        if (clientId) {
            restApiGet({
                uri: '/client',
                params: {
                    clientId,
                    requestLastLocationId: true,
                    requestJobsCount: true,
                } as GetClientInput,
                setLoading: setClientLoading,
                onData: setClientData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        } else {
            setClientData(undefined);
        }
    }, [clientId]);

    const [locationLoading, setLocationLoading] = useState<boolean>(false);
    const [locationData, setLocationData] = useState<LocationPureResponse | NullOrUndefined>();
    useEffect(() => {
        if (locationId) {
            restApiGet({
                uri: '/location/pure',
                params: {
                    locationId,
                } as GetLocationInput,
                setLoading: setLocationLoading,
                onData: setLocationData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        } else {
            setLocationData(undefined);
        }
    }, [locationId]);

    const [placesLoading, setPlacesLoading] = useState<boolean>(false);
    const [placesData, setPlacesData] = useState<LocationPlaceResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (locationPlaceId) {
            restApiGet({
                uri: '/location/place/search-by-ids',
                params: {
                    locationPlaceIds: locationPlaceId ? [locationPlaceId] : [],
                } as GetLocationPlacesByIdsInput,
                setLoading: setPlacesLoading,
                onData: setPlacesData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        } else {
            setPlacesData(undefined);
        }
    }, [locationPlaceId]);

    const [contactsLoading, setContactsLoading] = useState<boolean>(false);
    const [contactsData, setContactsData] = useState<ClientContactResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (clientContactIds.length > 0) {
            restApiGet({
                uri: '/client/contacts-for-ids',
                params: {
                    clientContactIds,
                } as GetClientContactsForIdsInput,
                setLoading: setContactsLoading,
                onData: setContactsData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        } else {
            setContactsData(undefined);
        }
    }, [clientContactIds]);

    const [responsiblePersonLoading, setResponsiblePersonLoading] = useState<boolean>(false);
    const [responsiblePersonData, setResponsiblePersonData] = useState<EmployeeDetailResponse | undefined>();
    useEffect(() => {
        if (responsiblePerson) {
            restApiGet({
                uri: '/company/employee/detail',
                params: {
                    employeeId: responsiblePerson,
                } as GetEmployeeDetailInput,
                setLoading: setResponsiblePersonLoading,
                onData: setResponsiblePersonData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        } else {
            setResponsiblePersonData(undefined);
        }
    }, [responsiblePerson]);

    const [requiredEmployeesLoading, setRequiredEmployeesLoading] = useState<boolean>(false);
    const [requiredEmployeesData, setRequiredEmployeesData] = useState<EmployeeJoinedUserResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (requiredEmployeeIds.length > 0 && companyId) {
            restApiGet({
                uri: '/company/employee/search-joined-users',
                params: {
                    companyId: companyId,
                    active: true,
                    employeeIds: requiredEmployeeIds,
                } as GetEmployeesJoinedUsersInput,
                setLoading: setRequiredEmployeesLoading,
                onData: setRequiredEmployeesData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        } else {
            setRequiredEmployeesData(undefined);
        }
    }, [requiredEmployeeIds, companyId]);

    useEffect(() => {
        if (responsiblePersonData || requiredEmployeesData) {
            const theFiles = requiredEmployeesData?.files || [];
            if (responsiblePersonData?.customPhotoFile) {
                theFiles.push(responsiblePersonData.customPhotoFile);
            }

            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...theFiles,
                    ],
                };
            });
        }
    }, [responsiblePersonData, requiredEmployeesData]);

    const [jobOffersEmployeesLoading, setJobOffersEmployeesLoading] = useState<boolean>(false);
    const [jobOffersEmployeesData, setJobOffersEmployeesData] = useState<EmployeeJoinedUserResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (jobOfferSeats.length > 0 && companyId) {
            const employeeIds: number[] = [];
            jobOfferSeats.forEach((seat) => {
                employeeIds.push(...seat.employeeIds);
            });

            restApiGet({
                uri: '/company/employee/search-joined-users',
                params: {
                    companyId: companyId,
                    active: true,
                    employeeIds: arrayUnique(employeeIds),
                } as GetEmployeesJoinedUsersInput,
                setLoading: setJobOffersEmployeesLoading,
                onData: setJobOffersEmployeesData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        } else {
            setJobOffersEmployeesData(undefined);
        }
    }, [jobOfferSeats, companyId]);

    const [jobFormsLoading, setJobFormsLoading] = useState<boolean>(false);
    const [jobFormsData, setJobFormsData] = useState<JobFormResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicJobFormTemplates,
                {
                    uri: '/job-form/search-by-company',
                    params: {
                        companyId: companyId,
                        isTemplate: true,
                    } as GetJobFormsInput,
                    setLoading: setJobFormsLoading,
                    onData: setJobFormsData,
                    onError: (error) => {
                        processQueryError(appDataContext, error);
                    },
                },
                (notifications) => true,
            );

            return () => subscription.cancel();
        } else {
            setJobFormsData(undefined);
        }
    }, [companyId]);

    const [jobFormsForIdsLoading, setJobFormsForIdsLoading] = useState<boolean>(false);
    const [jobFormsForIdsData, setJobFormsForIdsData] = useState<JobFormResponsePage>();
    useEffect(() => {
        const theJobFormIds = jobForms
            .filter(jobForm => jobForm.jobFormId)
            .map(jobForm => jobForm.jobFormId!);

        if (theJobFormIds.length > 0) {
            restApiGet({
                uri: '/job-form/search-by-ids',
                params: {
                    jobFormIds: theJobFormIds,
                } as GetJobFormsByIdsInput,
                setLoading: setJobFormsForIdsLoading,
                onData: setJobFormsForIdsData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        } else {
            setJobFormsForIdsData(undefined);
        }
    }, [jobForms]);

    const combinedJobForms = useMemo(() => {
        const theJobForms: JobFormResponse[] = [];

        if (jobFormsData) {
            theJobForms.push(...jobFormsData.content);
        }

        if (jobFormsForIdsData) {
            theJobForms.push(...jobFormsForIdsData.content);
        }

        return theJobForms;
    }, [jobFormsData, jobFormsForIdsData]);

    const [getFilesByIdsLoading, setGetFilesByIdsLoading] = useState<boolean>(false);
    const [getFilesByIdsData, setGetFilesByIdsData] = useState<FileResponsePage | undefined>();
    useEffect(() => {
        if (existingAttachments.length > 0) {
            restApiGet({
                uri: '/storage/search',
                params: {
                    fileIds: existingAttachments,
                } as GetFilesByIdsInput,
                setLoading: setGetFilesByIdsLoading,
                onData: setGetFilesByIdsData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        } else {
            setGetFilesByIdsData(undefined);
        }
    }, [existingAttachments]);

    const [getJobDataLoading, setGetJobDataLoading] = useState<boolean>(false);
    const [getJobDataData, setGetJobDataData] = useState<JobDetailResponse | undefined>();

    useEffect(() => {
        if (theJobId) {
            restApiGet({
                uri: '/job/detail',
                params: {
                    jobId: parseInt(theJobId),
                } as GetJobInput,
                setLoading: setGetJobDataLoading,
                onData: setGetJobDataData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        } else {
            setGetJobDataData(undefined);
        }
    }, [theJobId]);

    const [getVisitDataLoading, setGetVisitDataLoading] = useState<boolean>(false);
    const [getVisitDataData, setGetVisitDataData] = useState<VisitResponse | undefined>();
    useEffect(() => {
        if (visitIdInParams) {
            restApiGet({
                uri: '/job/visit',
                params: {
                    visitId: parseInt(visitIdInParams),
                } as GetVisitInput,
                setLoading: setGetVisitDataLoading,
                onData: setGetVisitDataData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [visitIdInParams]);

    const [getVisitDetailLoading, setGetVisitDetailLoading] = useState<boolean>(false);
    const [getVisitDetailData, setGetVisitDetailData] = useState<VisitDetailResponse | undefined>();
    useEffect(() => {
        if (duplicateVisitIdInParams) {
            restApiGet({
                uri: '/job/visit/detail',
                params: {
                    visitId: duplicateVisitIdInParams,
                    repeatingDay: duplicateRepeatingDayInParams,
                } as GetVisitInput,
                setLoading: setGetVisitDetailLoading,
                onData: setGetVisitDetailData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [duplicateVisitIdInParams, duplicateRepeatingDayInParams]);

    const [mutateCreateJob, {
        loading: createJobLoading,
    }] = useResettableMutation<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument);

    const [mutateScheduleVisitForJob, {
        loading: scheduleVisitLoading,
    }] = useMutation(ScheduleVisitForJobDocument);

    /**
     * Mutate Job creation to BE.
     */
    const CreateJob = async (input: CreateVisitInput, sendNotifications: boolean, startAnother?: boolean) => {
        try {
            const variables: CreateJobMutationVariables = {
                input: {
                    companyId: companyId!,
                    clientId: clientId!,
                    name: input.name,
                    responsiblePersonId: responsiblePerson!,
                    clientContactIds: input.clientContactIds,
                    visits: [input],
                    sendNotifications,
                    timeZone: timeZone!,
                },
            };

            const result = await mutateCreateJob({variables});

            if (!result.errors) {
                SuccessToast(tt('newJobScreen.success.jobCreated'));

                if (startAnother) {
                    resetJobData();
                } else {
                    navigate(jobDetailRoute({
                        jobId: result.data!.createJob.job.id,
                    }));
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    const [createVisitLoading, setCreateVisitLoading] = useState<boolean>(false);
    /**
     * Mutate to BE new Visit for Job and on success go to JobDetailScreen.
     */
    const CreateVisitForJob = async (input: CreateVisitInput, sendNotifications: boolean, startAnother?: boolean) => {
        try {
            setCreateVisitLoading(true);

            restApiPost({
                uri: '/job/create-visit',
                params: {
                    jobId: parseInt(theJobId!),
                    visit: input,
                    sendNotifications,
                } as CreateVisitForJobInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading: (value) => setCreateVisitLoading(value),
                onData: (data) => {
                    if (data) {
                        SuccessToast(tt('newVisit.screen.createVisitSuccess'));

                        if (startAnother) {
                            resetJobData();
                        } else {
                            navigate(getBackRoute(
                                jobDetailRoute({
                                    jobId: parseInt(theJobId!),
                                })
                            ));
                        }
                    }
                },
                onError: (error: any) => processMutationError(error),
            });
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate to BE schedule Visit for Job and on success go to JobDetailScreen.
     */
    const ScheduleVisitForJob = async (input: CreateVisitInput, sendNotifications: boolean) => {
        try {
            const variables: ScheduleVisitForJobMutationVariables = {
                input: {
                    jobId: parseInt(theJobId!),
                    visitId: parseInt(visitIdInParams!),
                    visit: input,
                    sendNotifications,
                },
            };

            const result = await mutateScheduleVisitForJob({variables});

            if (!result.errors) {
                SuccessToast(tt('newVisit.screen.scheduleVisitSuccess'));

                navigate(getBackRoute(
                    jobDetailRoute({
                        jobId: parseInt(theJobId!),
                    })
                ));
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Update Contacts from Client or Location or Places, cascade logic is used.
     */
    const UpdateContacts = (params: IUpdateContactsParams) => {
        if (params.clientId) {
            setContactsForClientId(params.clientId);
            setContactsForLocationId(undefined);
            setContactsForPlaceId(undefined);
        }

        if (params.locationId) {
            setContactsForClientId(undefined);
            setContactsForLocationId(params.locationId);
            setContactsForPlaceId(undefined);
        }

        if (params.placesId) {
            setContactsForClientId(undefined);
            setContactsForLocationId(undefined);
            setContactsForPlaceId(params.placesId);
        }
    };

    /**
     * Update Forms from Location or Place, cascade logic is used.
     */
    const UpdateForms = (params: IUpdateFormsParams) => {
        if (params.locationId) {
            setFormsForLocationId(params.locationId);
            setFormsForPlaceId(undefined);
        }

        if (params.placesId) {
            setFormsForLocationId(undefined);
            setFormsForPlaceId(params.placesId);
        }
    };

    useEffect(() => {
        if (theClientIdOfParams && clientData && !clientData.lastLocationId) {
            setTimeout(() => {
                UpdateContacts({
                    clientId: parseInt(theClientIdOfParams),
                });
            }, 1);
        }
    }, [clientData]);

    useEffect(() => {
        if (clientData && contactsForClientId) {
            setClientContactIds(
                clientData.contacts
                    .filter(contact => contact.assignedToClient)
                    .map(contact => contact.id)
            );

            setContactsForClientId(undefined);
        }

        if (locationData && contactsForLocationId) {
            if (locationData.clientContactIds.length > 0) {
                setClientContactIds(locationData!.clientContactIds);
            }

            setContactsForLocationId(undefined);
        }

        if (placesData && contactsForPlaceId) {
            let newClientContactIds: number[] = [];

            placesData!.content.forEach(place => {
                place.clientContactIds.forEach(contactId => {
                    if (!newClientContactIds.includes(contactId)) {
                        newClientContactIds.push(contactId);
                    }
                });
            });

            if (newClientContactIds.length > 0) {
                setClientContactIds(newClientContactIds);
            } else if (locationData) {
                setContactsForLocationId(locationData.id);
            }

            setContactsForPlaceId(undefined);
        }
    }, [clientData, contactsForClientId, locationData, contactsForLocationId, placesData, contactsForPlaceId]);

    useEffect(() => {
        if (locationData && formsForLocationId) {
            if (locationData.jobFormIds.length > 0) {
                setJobForms(locationData!.jobFormIds.map(jobFormId => ({
                    jobFormId,
                })));
            }

            setFormsForLocationId(undefined);
        }

        if (placesData && formsForPlaceId) {
            let newJobFormIds: number[] = [];

            placesData!.content.forEach(place => {
                if (place.jobFormIds.length > 0) {
                    newJobFormIds.push(place.jobFormIds[0]);
                }
            });

            if (newJobFormIds.length > 0) {
                setJobForms(newJobFormIds.map(jobFormId => ({
                    jobFormId,
                })));
            } else if (locationData && locationData.jobFormIds.length > 0) {
                setJobForms(locationData!.jobFormIds.map(jobFormId => ({
                    jobFormId,
                })));
            }

            setFormsForPlaceId(undefined);
        }
    }, [locationData, formsForLocationId, placesData, formsForPlaceId]);

    useEffect(() => {
        if (kindOfRoute === NewJobAndVisitRouteKind.fromJobDetail && getJobDataData) {
            setClientId(getJobDataData.job.clientId || undefined);

            setClientContactIds(getJobDataData.job.clientContactIds || []);

            setBaseInputs(prev => {
                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        value: getJobDataData.job.name || '',
                    },
                };
            });

            setTimeout(() => {
                setFormInitialized(true);
            }, 1);
        }
    }, [getJobDataData, kindOfRoute]);

    useEffect(() => {
        if (kindOfRoute === NewJobAndVisitRouteKind.fromJobDetail && getVisitDataData) {
            let createVisitParams = getVisitDataData?.createVisitParams ? JSON.parse(getVisitDataData.createVisitParams) : undefined;

            setLocationId(getVisitDataData.locationId || undefined);

            setLocationPlaceId(getVisitDataData.locationPlaceId || undefined);

            setBaseInputs(prev => {
                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        value: getVisitDataData.name || getJobDataData?.job.name || '',
                    },
                    description: {
                        ...prev.description,
                        value: getVisitDataData.description || '',
                    },
                };
            });

            if (getVisitDataData.attachmentFileIds && (duplicateVisitIdInParams ? duplicateVisitOptionsInParams?.attachments : true)) {
                setAttachments(getVisitDataData.attachmentFileIds.map(fileId => ({
                    type: IFileStateType.File,
                    uuid: uuidv4(),
                    fileId,
                })));

                setExistingAttachments(getVisitDataData.attachmentFileIds);
            } else {
                setAttachments([]);

                setExistingAttachments([]);
            }

            if (createVisitParams?.jobForms && (duplicateVisitIdInParams ? duplicateVisitOptionsInParams?.forms : true)) {
                setJobForms(createVisitParams.jobForms);
            } else {
                setJobForms([]);
            }

            setRequiredEmployeeIds((duplicateVisitIdInParams ? duplicateVisitOptionsInParams?.workers : true) ? (getVisitDataData.requiredEmployeeIds || []) : []);

            if (createVisitParams?.employeeTimesheets && (duplicateVisitIdInParams ? duplicateVisitOptionsInParams?.workers : true)) {
                setEmployeeTimesheets(createVisitParams.employeeTimesheets);
            } else {
                setEmployeeTimesheets([]);
            }

            if (createVisitParams?.jobOfferSeats && (duplicateVisitIdInParams ? duplicateVisitOptionsInParams?.workers : true)) {
                setJobOfferSeats(createVisitParams.jobOfferSeats);
            } else {
                setJobOfferSeats([]);
            }

            if (createVisitParams?.products && (duplicateVisitIdInParams ? duplicateVisitOptionsInParams?.products : true)) {
                setProducts(createVisitParams.products);
            } else {
                setProducts([]);
            }

            if (createVisitParams?.materials && (duplicateVisitIdInParams ? duplicateVisitOptionsInParams?.materials : true)) {
                setMaterials(createVisitParams.materials);
            } else {
                setMaterials([]);
            }

            if (createVisitParams?.visitConfigurations) {
                setVisitConfigurations(createVisitParams.visitConfigurations);
            } else {
                setVisitConfigurations([]);
            }

            setTimeout(() => {
                setFormInitialized(true);
            }, 1);
        }
    }, [getVisitDataData, kindOfRoute]);

    useEffect(() => {
        if (kindOfRoute === NewJobAndVisitRouteKind.default && fromDate && toDate) {
            setSelectedTab('0');
            setInputsTab1(prev => {
                return {
                    ...prev,
                    startTime: {
                        ...prev.startTime,
                        value: fromDate.toMillis(),
                    },
                    endTime: {
                        ...prev.endTime,
                        value: toDate.toMillis(),
                    },
                    startDate: {
                        ...prev.startDate,
                        value: fromDate.toMillis(),
                    },
                    endDate: {
                        ...prev.endDate,
                        value: toDate.toMillis(),
                    },
                    wholeDay: {
                        ...prev.wholeDay,
                        value: wholeDay || false,
                    },
                };
            });
        }
    }, [fromDate, toDate, wholeDay]);

    useEffect(() => {
        if (kindOfRoute === NewJobAndVisitRouteKind.fromJobDetail && getVisitDetailData && getJobDataData) {
            const theVisit = getVisitDetailData.visit;
            const theRepeatingDayData = theVisit.repeatingDayData;

            setLocationId(theRepeatingDayData?.locationId || theVisit.locationId || undefined);

            setLocationPlaceId(theRepeatingDayData?.locationPlaceId || theVisit.locationPlaceId || undefined);

            let newTitle = `${theRepeatingDayData?.name || theVisit.name || getJobDataData?.job.name || ''}`;
            newTitle = newTitle ? `${newTitle} ${tt('newJobOrVisit.screen.jobTitle.suffix.copy')}` : '';

            setBaseInputs(prev => {
                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        value: newTitle,
                    },
                    description: {
                        ...prev.description,
                        value: theRepeatingDayData?.description || theVisit.description || '',
                    },
                };
            });

            const theAttachmentFileIds = theRepeatingDayData?.attachmentFileIds || theVisit.attachmentFileIds;

            if (theAttachmentFileIds && (duplicateVisitIdInParams ? duplicateVisitOptionsInParams?.attachments : true)) {
                setAttachments(theAttachmentFileIds.map(fileId => ({
                    type: IFileStateType.File,
                    uuid: uuidv4(),
                    fileId,
                })));

                setExistingAttachments(theAttachmentFileIds);
            } else {
                setAttachments([]);

                setExistingAttachments([]);
            }

            if ((duplicateVisitIdInParams ? duplicateVisitOptionsInParams?.forms : true)) {
                setJobForms(getVisitDetailData.jobForms.map(jobForm => ({
                    jobFormId: jobForm.id,
                })));
            }

            const dateTimes = visitDateTimes(
                theVisit,
                theRepeatingDayData,
            );
            setSelectedTab('0');
            setInputsTab1(prev => {
                return {
                    ...prev,
                    startTime: {
                        ...prev.startTime,
                        value: dateTimes.startTime.toMillis(),
                    },
                    endTime: {
                        ...prev.endTime,
                        value: dateTimes.endTime.toMillis(),
                    },
                    startDate: {
                        ...prev.startDate,
                        value: dateTimes.start.toMillis(),
                    },
                    endDate: {
                        ...prev.endDate,
                        value: dateTimes.end?.toMillis() || prev.endDate.value,
                    },
                    wholeDay: {
                        ...prev.wholeDay,
                        value: theRepeatingDayData?.wholeDay || theVisit.wholeDay || false,
                    },
                    skipOtherDaysCheckbox: {
                        ...prev.skipOtherDaysCheckbox,
                        value: theVisit.skipDays.length > 0,
                    },
                    skipOtherDays: {
                        ...prev.skipOtherDays,
                        value: theVisit.skipDays || [],
                    },
                };
            });

            if (duplicateVisitIdInParams ? duplicateVisitOptionsInParams?.workers : true) {
                const theEmployeeIds = theRepeatingDayData?.employeeIds || theVisit.employeeIds || [];
                setRequiredEmployeeIds(theEmployeeIds);

                const newTimesheets: CreateJobEmployeeTimesheetItemInput[] = [];
                if (theEmployeeIds.length > 0) {
                    for (const theEmployeeIdOf of theEmployeeIds) {
                        const theTimesheet = getVisitDetailData?.employeeTimesheet?.find(timesheet => timesheet.employeeId === theEmployeeIdOf);

                        if (theTimesheet && (theTimesheet.paymentType === ItemPaymentType.Hourly || theTimesheet.paymentType === ItemPaymentType.Fixed)) {
                            newTimesheets.push(
                                convertJobEmployeeTimesheetItemToCreateInput(theTimesheet, currentEmployeeId!)
                            );
                        }
                    }
                }
                setEmployeeTimesheets(newTimesheets);

                const newJobOfferSeats: CreateJobOfferSeatInput[] = [];
                if (getVisitDetailData.jobOfferSeat.length > 0) {
                    for (const theJobOfferSeatOf of getVisitDetailData.jobOfferSeat) {
                        newJobOfferSeats.push(
                            JobOfferSeatResponseToCreateInput(theJobOfferSeatOf)
                        );
                    }
                }
                setJobOfferSeats(newJobOfferSeats);
            }

            if ((duplicateVisitIdInParams ? duplicateVisitOptionsInParams?.products : true)) {
                const newProducts: CreateProductInput[] = [];
                if (getVisitDetailData.products.length > 0) {
                    for (const theProductOf of getVisitDetailData.products) {
                        newProducts.push(
                            convertProductResponseToCreateInput(theProductOf)
                        );
                    }
                }
                setProducts(newProducts);
            }

            if ((duplicateVisitIdInParams ? duplicateVisitOptionsInParams?.materials : true)) {
                const newMaterials: CreateMaterialInput[] = [];
                if (getVisitDetailData.materials.length > 0) {
                    for (const theMaterialOf of getVisitDetailData.materials) {
                        newMaterials.push(
                            convertMaterialResponseToCreateInput(theMaterialOf)
                        );
                    }
                }
                setMaterials(newMaterials);
            }
        }
    }, [getVisitDetailData, kindOfRoute, getJobDataData]);

    useEffect(() => {
        if (getFilesByIdsData) {
            let shouldUpdate = false;

            const newAttachments = attachments.map(attachment => {
                const data = getFilesByIdsData.content.find(file => file.id === attachment.fileId);

                if (data && !attachment.data) {
                    shouldUpdate = true;

                    return {
                        ...attachment,
                        data,
                        uuid: data?.uuid || attachment.uuid,
                    };
                } else {
                    return attachment;
                }
            });

            if (shouldUpdate) {
                setAttachments(newAttachments);
            }
        }
    }, [getFilesByIdsData, attachments]);

    useEffect(() => {
        if (clientData && !locationId) {
            setLocationId(clientData.lastLocationId || undefined);

            if (clientData.lastLocationId) {
                setTimeout(() => {
                    UpdateContacts({locationId: clientData.lastLocationId!});

                    UpdateForms({locationId: clientData.lastLocationId!});
                }, 1);
            }
        }
    }, [clientData]);

    const [lastClientId, setLastClientId] = useState<number>();
    useEffect(() => {
        if (clientId) {
            setLastClientId(clientId);

            if (lastClientId) {
                setClientData(undefined);
                setClientContactIds([]);
                setLocationId(undefined);
                setLocationPlaceId(undefined);

                setTheJobId(jobId);

                setTimeout(() => {
                    UpdateContacts({clientId});
                }, 1);
            }
        }
    }, [clientId]);

    useEffect(() => {
        if (requiredEmployeeIds.length > 0 && jobOfferSeats.length > 0) {
            const newJobOfferSeats: CreateJobOfferSeatInput[] = jobOfferSeats.map(seat => {
                const newEmployeeIds = seat.employeeIds.filter(employeeId => !requiredEmployeeIds.includes(employeeId));

                return {
                    ...seat,
                    employeeIds: newEmployeeIds,
                };
            });

            setJobOfferSeats(newJobOfferSeats);
        }
    }, [requiredEmployeeIds]);

    useEffect(() => {
        if (selectedTab === '1' && jobOfferSeats.length > 0) {
            setJobOfferSeats([]);
        }
    }, [selectedTab]);

    /**
     * Add new JobOffer creation input.
     */
    const AddJobOfferSeat = (data: IJobOfferData) => {
        setJobOfferSeats([...jobOfferSeats, {
            employeeIds: data.employeeIds,
            fixedPrice: data.fixedPrice,
            hourRate: data.hourRate,
            hours: data.hours,
            minutes: data.minutes,
            paymentType: data.type,
            substituteCount: data.substituteCount,
        }]);
    };

    useEffect(() => {
        setTitle(tt('newVisit.screen.title'));
    }, []);

    const [baseInputs, setBaseInputs] = useState<IInputsData>({
        name: {
            testId: 'createJobFormAddNameInput',
            type: InputType.Text,
            label: tt('newVisit.screen.nameInput.label'),
            toggleButtonText: tt('newVisit.screen.nameInput.toggleButtonText'),
            value: '',
            maxChars: 70,
            showCharCounter: true,
            changeThisToCloseInput: 0,
        },
        description: {
            type: InputType.Editor,
            toggleButtonText: tt('newVisit.screen.descriptionInput.toggleButtonText'),
            placeholder: tt('newVisit.screen.descriptionInput.placeholder'),
            value: '',
            label: '',
            changeThisToCloseInput: 0,
        },
    });

    const [inputsAssignJob, setInputsAssignJob] = useState<IInputsData>({
        assignToJob: {
            testId: 'assignToExistingJobSwitch',
            type: InputType.Switch,
            switchVariant: "Android12Switch",
            label: tt('newVisit.screen.switch.assignToExistingJob').toUpperCase(),
            value: false,
        },
    });

    useEffect(() => {
        if (!inputsAssignJob.assignToJob.value) {
            setTheJobId(jobId);
        }
    }, [inputsAssignJob.assignToJob.value]);

    const [checkboxes, setCheckboxes] = useState<IInputsData>({
        notifications: {
            testId: 'createJobFormSendNotificationsInput',
            type: InputType.CheckBox,
            label: tt('common.visit.checkbox.sendNotificationsToWorkers'),
            value: true,
        },
    });

    const [inputsTab1, setInputsTab1] = useState<IInputsData>({});
    const [inputsTab2, setInputsTab2] = useState<IInputsData>({});
    const [inputsTab3, setInputsTab3] = useState<IInputsData>({});

    function resetJobData() {
        setLocationId(undefined);
        setLocationPlaceId(undefined);
        setJobForms([]);
        setClientContactIds([]);
        setSelectedTab('0');
        setRequiredEmployeeIds([]);
        setEmployeeTimesheets([]);
        setJobOfferSeats([]);
        setProducts([]);
        setMaterials([]);

        setAttachments([]);

        setBaseInputs(prev => {
            return {
                ...prev,
                name: {
                    ...prev.name,
                    value: '',
                    changeThisToCloseInput: prev.name.changeThisToCloseInput!++,
                },
                description: {
                    ...prev.description,
                    value: '',
                    changeThisToCloseInput: prev.description.changeThisToCloseInput!++,
                },
            };
        });

        setClientContactIds([]);

        setContactsForClientId(undefined);
        setContactsForLocationId(undefined);
        setContactsForPlaceId(undefined);

        setTheJobId(jobId);

        setResetForms(prev => prev + 1);

        setVisitConfigurations([]);
    }

    const customDates: CustomDateInput[] | undefined = useMemo(() => {
        if (selectedTab === '2' && inputsTab3.datesPicker?.value) {
            const newCustomDates: number[] = inputsTab3.datesPicker.value || [];

            if (newCustomDates!.length < 1) {
                return undefined;
            }

            const startTime = DateTime.fromMillis(inputsTab3.startTime.value);
            const endTime = DateTime.fromMillis(inputsTab3.endTime.value);

            return newCustomDates.map(timestamp => {
                return {
                    timestamp,
                    start: DateTime.fromMillis(timestamp).set({
                        hour: startTime!.hour,
                        minute: startTime!.minute
                    }).toMillis(),
                    end: DateTime.fromMillis(timestamp).set({
                        hour: endTime!.hour,
                        minute: endTime!.minute
                    }).toMillis(),
                };
            });
        }

        return undefined;
    }, [inputsTab3, selectedTab]);
    const visitStart = useMemo(() => {
        if (selectedTab === '0' && inputsTab1.startTime?.value && inputsTab1.startDate?.value) {
            const startTime = DateTime.fromMillis(inputsTab1.startTime.value);

            return DateTime.fromMillis(inputsTab1.startDate.value).set({
                hour: startTime.hour,
                minute: startTime.minute
            });
        } else if (selectedTab === '1' && inputsTab2.startTime?.value && inputsTab2.startDate?.value) {
            const startTime = DateTime.fromMillis(inputsTab2.startTime.value);

            return DateTime.fromMillis(inputsTab2.startDate.value).set({
                hour: startTime.hour,
                minute: startTime.minute
            });
        } else if (selectedTab === '2' && customDates && customDates.length > 0) {
            const startTime = DateTime.fromMillis(inputsTab3.startTime.value);

            return DateTime.fromMillis(customDates![0].timestamp)
                .set({hour: startTime.hour, minute: startTime.minute});
        }

        return DateTime.now();
    }, [inputsTab1, inputsTab2, inputsTab3, selectedTab, customDates]);
    const visitEnd = useMemo(() => {
        if (selectedTab === '0' && inputsTab1.endTime?.value && inputsTab1.endDate?.value) {
            const endTime = DateTime.fromMillis(inputsTab1.endTime.value);

            return DateTime.fromMillis(inputsTab1.endDate.value).set({hour: endTime.hour, minute: endTime.minute});
        } else if (selectedTab === '1' && inputsTab2.endTime?.value && inputsTab2.endDate?.value) {
            if (inputsTab2.neverEnding.value) {
                return null;
            } else {
                const endTime = DateTime.fromMillis(inputsTab2.endTime.value);

                return DateTime.fromMillis(inputsTab2.endDate.value).set({hour: endTime.hour, minute: endTime.minute});
            }
        } else if (selectedTab === '2' && customDates && customDates.length > 0) {
            const endTime = DateTime.fromMillis(inputsTab3.endTime.value);

            return DateTime.fromMillis(customDates![customDates!.length - 1].timestamp)
                .set({hour: endTime.hour, minute: endTime.minute});
        }

        return DateTime.now();
    }, [inputsTab1, inputsTab2, inputsTab3, selectedTab, customDates]);
    const visitStartTime = useMemo(() => {
        if (selectedTab === '0') {
            return null;
        } else if (selectedTab === '1' && inputsTab2.startTime?.value) {
            return DateTime.fromMillis(inputsTab2.startTime.value);
        } else if (selectedTab === '2' && inputsTab3.startTime?.value) {
            return DateTime.fromMillis(inputsTab3.startTime.value);
        }

        return null;
    }, [inputsTab1, inputsTab2, inputsTab3, selectedTab, customDates]);
    const visitEndTime = useMemo(() => {
        if (selectedTab === '0') {
            return null;
        } else if (selectedTab === '1' && inputsTab2.endTime?.value) {
            return DateTime.fromMillis(inputsTab2.endTime.value);
        } else if (selectedTab === '2' && inputsTab3.endTime?.value) {
            return DateTime.fromMillis(inputsTab3.endTime.value);
        }

        return null;
    }, [inputsTab1, inputsTab2, inputsTab3, selectedTab, customDates]);

    const [loadingVisitsForConflicts, setLoadingVisitsForConflicts] = useState<boolean>(false);
    const [visitsForConflicts, setVisitsForConflicts] = useState<IVisitEvent[] | null>(null);
    const [visitOfferSeatsForConflicts, setVisitOfferSeatsForConflicts] = useState<JobOfferSeatResponse[] | null>(null);
    useEffect(() => {
        if (requiredEmployeeIds.length > 0 && companyId) {
            const subscription = subscribe(
                kTopicVisits,
                {
                    uri: '/job/visit/search-list-pure-joined-others-conflicts',
                    params: {
                        companyId: companyId!,
                        fromDate: visitStart.startOf('day').toMillis(),
                        toDate: visitEnd ? visitEnd.endOf('day').toMillis() : visitStart.plus({years: 1}).endOf('day').toMillis(),
                    } as GetVisitJoinedOthersConflictsInput,
                    setLoading: setLoadingVisitsForConflicts,
                    onData: (data: VisitListPureJoinedOthersConflictsResponsePage) => {
                        setVisitsForConflicts(
                            VisitsProcess({
                                nonRepeating: data.nonRepeating,
                                repeating: data.repeating,
                                visitRepeatDays: data.repeatingDayData,
                                from: visitStart.startOf('day'),
                                to: visitEnd ? visitEnd.endOf('day') : visitStart.plus({years: 1}).endOf('day'),
                            })
                        );

                        setVisitOfferSeatsForConflicts(data.jobOfferSeats);
                    },
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => true,
            );

            return () => {
                subscription.cancel();
            };
        } else {
            setVisitsForConflicts(null);
            setVisitOfferSeatsForConflicts(null);
        }
    }, [visitStart, visitEnd, requiredEmployeeIds, companyId]);

    const newVisitsEvents = useMemo(() => {
        let calendarInputs = selectedTab === '0' ? inputsTab1 : inputsTab2;
        calendarInputs = selectedTab === '2' ? inputsTab3 : calendarInputs;

        const frequency = calendarInputs.frequency?.value;

        return NewVisitEvents(
            selectedTab,
            frequency,
            calendarInputs,
            baseInputs,
        );
    }, [inputsTab1, inputsTab2, inputsTab3, selectedTab, baseInputs]);

    useEffect(() => {
        let override = false;

        if (companyConfigurationResponsePage) {
            const theConfig = companyConfigurationResponsePage.content
                .find((config) => config.type === CompanyConfigurationType.JobEmployeeTimesheets);

            if (theConfig) {
                override = JSON.parse(theConfig.valueJSON)[kCompanyConfigurationOverrideForVisitsEmployeeTimesheets] === true;
            }
        }

        setCompanyConfigurationOverrideWorkers(override);
    }, [companyConfigurationResponsePage]);

    useEffect(() => {
        if (companyConfigurationResponsePage && visitConfigurations.length > 0) {
            const visitConfig = visitConfigurations.find(v => v.uuid === visitConfigurationUuid);
            const theConfig = companyConfigurationResponsePage.content
                .find((config) => config.type === CompanyConfigurationType.JobEmployeeTimesheets);

            if (visitConfig && theConfig) {
                const visitValue = JSON.parse(visitConfig.valueJSON);
                const theValue = JSON.parse(theConfig.valueJSON);

                if (visitValue[kCompanyConfigurationAutoJobEmployeeTimesheets] === theValue[kCompanyConfigurationAutoJobEmployeeTimesheets]) {
                    const newConfigs = visitConfigurations.filter((v) => v.uuid !== visitConfigurationUuid);

                    setVisitConfigurations(newConfigs);
                }
            }
        }
    }, [companyConfigurationResponsePage, visitConfigurations]);

    /**
     * Recalculate employees timesheets for configuration.
     */
    const recalculateTimesheets = async () => {
        companyConfigurationJobEmployeeTimesheets({
            currentEmployeeId: currentEmployeeId!,
            configurations: companyConfigurationResponsePage,
            requiredEmployeeIds,
            requiredEmployeesData,
            employeeTimesheets,
            setEmployeeTimesheets,
            visitStart,
            visitEnd,
            visitStartTime,
            visitEndTime,
            visitConfiguration: visitConfigurations.find((v) => v.uuid === visitConfigurationUuid),
        });
    };

    useEffect(() => {
        if (currentEmployeeId) {
            recalculateTimesheets();
        }
    }, [currentEmployeeId, companyConfigurationResponsePage, requiredEmployeeIds, requiredEmployeesData, visitStart, visitEnd, visitStartTime, visitEndTime, visitConfigurations]);

    /**
     * Validate correct forms and call callback.
     */
    const Save = (startAnother?: boolean) => {
        if (!clientId) {
            ErrorToast(tt('newJobScreen.error.client'));
            return;
        }

        if (ValidateForm(baseInputs, setBaseInputs) && ((selectedTab === '0' && ValidateForm(inputsTab1, setInputsTab1)) || (selectedTab === '1' && ValidateForm(inputsTab2, setInputsTab2)) || (selectedTab === '2' && ValidateForm(inputsTab3, setInputsTab3)))) {
            try {
                let startDate: DateTime | undefined;
                let endDate: DateTime | undefined;
                let startTime: DateTime | undefined;
                let endTime: DateTime | undefined;
                let repeating = VisitRepeating.Never;
                let repeatWeekDays: number[] = [];
                let repeatEndDate: number | undefined;
                let repeatModifier: VisitRepeatModifier | undefined;
                let monthDays: number[] | undefined;
                let monthWeekDays: string[] | undefined;
                let wholeDay = false;
                let skipDays: number[] = [];

                let skipWeekends = selectedTab === '0' ? inputsTab1.skipWeekends.value : false;
                skipWeekends = selectedTab === '1' ? inputsTab2.skipWeekends.value : skipWeekends;
                let every = selectedTab === '0' ? inputsTab1.every.value : 1;
                every = selectedTab === '1' ? inputsTab2.every.value : every;
                every = parseInt(`${every}`);

                let scheduleLater = false;

                if (selectedTab === '0') {
                    let start = DateTime.fromMillis(inputsTab1.startDate.value);
                    startTime = DateTime.fromMillis(inputsTab1.startTime.value);
                    start = start.set({hour: startTime.hour, minute: startTime.minute});

                    startDate = start;

                    let end = DateTime.fromMillis(inputsTab1.endDate.value);
                    endTime = DateTime.fromMillis(inputsTab1.endTime.value);
                    end = end.set({hour: endTime.hour, minute: endTime.minute});

                    endDate = end;

                    wholeDay = inputsTab1.wholeDay.value;

                    const startOfDayOfStart = start.startOf('day').toMillis();
                    const endOfDayOfEnd = end.endOf('day').toMillis();
                    skipDays = inputsTab1.skipOtherDaysCheckbox.value ? inputsTab1.skipOtherDays.value
                        .filter((timestamp: number) => timestamp >= startOfDayOfStart && timestamp <= endOfDayOfEnd) : [];

                    if (skipWeekends) {
                        repeatModifier = VisitRepeatModifier.SkipWeekends;
                    }

                    scheduleLater = inputsTab1.scheduleLater.value;

                    if (start > end) {
                        if (scheduleLater) {
                            endTime = startTime.plus({hours: 1});
                            end = start.plus({hours: 1});

                            endDate = end;
                        } else {
                            ErrorToast(tt('createJob.dates.error'));
                            return;
                        }
                    }
                }
                if (selectedTab === '1') {
                    let start = DateTime.fromMillis(inputsTab2.startDate.value);
                    startTime = DateTime.fromMillis(inputsTab2.startTime.value);
                    start = start.set({hour: startTime.hour, minute: startTime.minute});

                    startDate = start;

                    endTime = DateTime.fromMillis(inputsTab2.endTime.value);

                    if (!inputsTab2.neverEnding.value) {
                        let end = DateTime.fromMillis(inputsTab2.endDate.value);
                        endTime = DateTime.fromMillis(inputsTab2.endTime.value);
                        end = end.set({hour: endTime.hour, minute: endTime.minute});

                        repeatEndDate = end.toMillis();
                    }

                    wholeDay = inputsTab2.wholeDay.value;

                    repeating = inputsTab2.frequency.value;

                    repeatWeekDays = inputsTab2.weekdayPicker.value.map((day: string) => parseInt(day));

                    const monthDaysType = inputsTab2.monthDaysType.value;
                    monthDays = inputsTab2.monthDayPicker.value.map((day: string) => parseInt(day));
                    monthWeekDays = inputsTab2.monthWeekDayPicker.value;

                    if (skipWeekends && repeating === VisitRepeating.Daily) {
                        repeatModifier = VisitRepeatModifier.SkipWeekends;
                    }

                    if (repeating === VisitRepeating.Monthly) {
                        if (monthDays && monthDaysType === VisitRepeatModifier.DayOfMonth) {
                            if (monthDays.length > 1) {
                                repeatModifier = monthDays.includes(-1) ? VisitRepeatModifier.DayOfMonthOrLastDay : VisitRepeatModifier.DayOfMonth;
                            } else {
                                repeatModifier = monthDays.includes(-1) ? VisitRepeatModifier.LastDayOfMonth : VisitRepeatModifier.DayOfMonth;
                            }
                        }

                        if (monthWeekDays && monthDaysType === VisitRepeatModifier.DayOfWeek) {
                            repeatModifier = VisitRepeatModifier.DayOfWeek;
                        }
                    }

                    if (startTime > endTime) {
                        ErrorToast(tt('createJob.dates.repeating.error'));
                        return;
                    }

                    if (repeating == VisitRepeating.Weekly && repeatWeekDays.length < 1) {
                        ErrorToast(tt('createJob.days.error'));
                        return;
                    }

                    if (repeating == VisitRepeating.Monthly && monthDaysType === VisitRepeatModifier.DayOfMonth && (!monthDays || monthDays.length < 1)) {
                        ErrorToast(tt('createJob.monthDays.error'));
                        return;
                    } else if (repeating == VisitRepeating.Monthly && monthDaysType === VisitRepeatModifier.DayOfWeek && (!monthWeekDays || monthWeekDays.length < 1)) {
                        ErrorToast(tt('createJob.monthWeekDays.error'));
                        return;
                    }
                }
                if (selectedTab === '2') {
                    const newCustomDates: number[] = inputsTab3.datesPicker.value || [];

                    if (newCustomDates!.length < 1) {
                        ErrorToast(tt('createJob.customDates.error'));
                        return;
                    }

                    startTime = DateTime.fromMillis(inputsTab3.startTime.value);
                    endTime = DateTime.fromMillis(inputsTab3.endTime.value);

                    let start = DateTime.fromMillis(customDates![0].timestamp);
                    let end = DateTime.fromMillis(customDates![customDates!.length - 1].timestamp);

                    start = start.set({hour: startTime.hour, minute: startTime.minute});
                    end = end.set({hour: endTime.hour, minute: endTime.minute});

                    startDate = start;
                    endDate = end;

                    wholeDay = inputsTab3.wholeDay.value;

                    repeatModifier = VisitRepeatModifier.CustomDates;

                    if (startTime > endTime) {
                        ErrorToast(tt('createJob.dates.repeating.error'));
                        return;
                    }
                }

                const attachmentFileIds: number[] = attachments
                    .filter(document => document.data?.id)
                    .map(document => document.data!.id!);

                let createVisitParams: string | undefined;

                if (scheduleLater) {
                    // Needs to be in sync with BE processes
                    const params = {
                        jobForms,
                        products,
                        materials,
                        employeeTimesheets,
                        jobOfferSeats,
                        visitConfigurations,
                    };

                    createVisitParams = JSON.stringify(params);
                }

                const visitUuid = getVisitDataData?.uuid || uuidv4();

                if (wholeDay) {
                    startDate = startDate?.startOf('day');
                    endDate = endDate?.endOf('day');
                    startTime = startTime?.startOf('day');
                    endTime = endTime?.endOf('day');
                    repeatEndDate = repeatEndDate ? DateTime.fromMillis(repeatEndDate).endOf('day').toMillis() : undefined;
                }

                const input: CreateVisitInput = {
                    jobId: 0,
                    uuid: visitUuid,
                    companyId: companyId!,
                    clientId: clientId!,
                    locationId: locationId || null,
                    locationPlaceId: locationPlaceId || null,
                    clientContactIds,
                    repeating,
                    name: baseInputs.name.value || null,
                    description: baseInputs.description.value || null,
                    startDate: startDate ? startDate.toMillis() : null,
                    endDate: endDate ? endDate.toMillis() : null,
                    startTime: startTime ? startTime.toMillis() : null,
                    endTime: endTime ? endTime.toMillis() : null,
                    repeatWeekDays,
                    requiredEmployeeIds,
                    employeeTimesheets,
                    jobForms,
                    jobOfferSeats,
                    attachmentFileIds,
                    repeatEndDate: repeatEndDate || null,
                    repeatModifier: repeatModifier || null,
                    products,
                    materials,
                    scheduleLater,
                    every: every,
                    monthDays: monthDays || [],
                    monthWeekDays: monthWeekDays || [],
                    customDates: customDates || [],
                    createVisitParams: createVisitParams || null,
                    wholeDay,
                    skipDays,
                    visitConfigurations,
                };

                if (kindOfRoute === NewJobAndVisitRouteKind.default) {
                    if (inputsAssignJob.assignToJob.value && getJobDataData) {
                        CreateVisitForJob(
                            input,
                            checkboxes.notifications.value,
                            startAnother,
                        );
                    } else {
                        CreateJob(
                            input,
                            checkboxes.notifications.value,
                            startAnother,
                        );
                    }
                }

                if (kindOfRoute === NewJobAndVisitRouteKind.fromJobDetail) {
                    if (visitIdInParams) {
                        ScheduleVisitForJob(
                            input,
                            checkboxes.notifications.value,
                        );
                    } else {
                        CreateVisitForJob(
                            input,
                            checkboxes.notifications.value,
                            startAnother,
                        );
                    }
                }
            } catch (e) {
                processMutationError(e);
            }
        }
    };

    function bodyJSX(isMobile?: boolean, isSmallScreen?: boolean) {
        return (
            <Body
                formInitialized={formInitialized}
                visitIdInParams={visitIdInParams}
                jobId={theJobId}
                kindOfRoute={kindOfRoute}
                isMobile={isMobile}
                isSmallScreen={isSmallScreen}
                clientId={clientId}
                setClientId={setClientId}
                clientData={clientData}
                contactsData={contactsData}
                clientContactIds={clientContactIds}
                setClientContactIds={setClientContactIds}
                setChooseContacts={setChooseContacts}
                updateContacts={UpdateContacts}
                updateForms={UpdateForms}
                locationId={locationId}
                setLocationId={setLocationId}
                jobForms={jobForms}
                setJobForms={setJobForms}
                locationPlaceId={locationPlaceId}
                setLocationPlaceId={setLocationPlaceId}
                requiredEmployeeIds={requiredEmployeeIds}
                setRequiredEmployeeIds={setRequiredEmployeeIds}
                employeeTimesheets={employeeTimesheets}
                setEmployeeTimesheets={setEmployeeTimesheets}
                locationData={locationData}
                placesData={placesData}
                responsiblePerson={responsiblePerson}
                setResponsiblePerson={setResponsiblePerson}
                responsiblePersonData={responsiblePersonData}
                requiredEmployeesData={requiredEmployeesData}
                jobOffersEmployeesData={jobOffersEmployeesData}
                combinedJobForms={combinedJobForms}
                jobOfferSeats={jobOfferSeats}
                setJobOfferSeats={setJobOfferSeats}
                addJobOfferSeat={AddJobOfferSeat}
                attachments={attachments}
                setAttachments={setAttachments}
                documentsModal={documentsModal}
                setDocumentsModal={setDocumentsModal}
                documentsModalIndexOnOpen={documentsModalIndexOnOpen}
                setDocumentsModalIndexOnOpen={setDocumentsModalIndexOnOpen}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                disabledButton={companyConfigurationLoading || responsiblePersonsLoading || clientLoading || locationLoading || placesLoading || contactsLoading || requiredEmployeesLoading || jobFormsLoading || getFilesByIdsLoading || getJobDataLoading || getVisitDataLoading || getFilesByIdsLoading || responsiblePersonLoading || getVisitDetailLoading || jobFormsForIdsLoading || jobOffersEmployeesLoading}
                loading={createJobLoading || createVisitLoading || scheduleVisitLoading}
                products={products}
                setProducts={setProducts}
                materials={materials}
                setMaterials={setMaterials}
                companyConfigurationOverrideWorkers={companyConfigurationOverrideWorkers}
                visitConfigurationUuid={visitConfigurationUuid}
                visitConfigurations={visitConfigurations}
                setVisitConfigurations={setVisitConfigurations}
                save={Save}
                baseInputs={baseInputs}
                setBaseInputs={setBaseInputs}
                checkboxes={checkboxes}
                setCheckboxes={setCheckboxes}
                inputsTab1={inputsTab1}
                setInputsTab1={setInputsTab1}
                inputsTab2={inputsTab2}
                setInputsTab2={setInputsTab2}
                inputsTab3={inputsTab3}
                setInputsTab3={setInputsTab3}
                resetForms={resetForms}
                jobData={getJobDataData}
                setJobId={setTheJobId}
                inputsAssignJob={inputsAssignJob}
                setInputsAssignJob={setInputsAssignJob}
                visitsForConflicts={visitsForConflicts}
                visitOfferSeatsForConflicts={visitOfferSeatsForConflicts}
                visitsNewEvents={newVisitsEvents}
                recalculateTimesheets={recalculateTimesheets}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true, true)}
                largePhoneScreen={bodyJSX(true, true)}
                tabletScreen={bodyJSX(false, true)}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />

            <ChooseClientContactModalBottomSheet
                open={chooseContacts}
                setOpen={setChooseContacts}
                clientId={clientId || 0}
                existingContactIds={clientContactIds}
                onSave={(ids: number[]) => {
                    setClientContactIds(ids);

                    setChooseContacts(false);
                }}
                canSaveToClear={true}
            />
        </>
    );
}

interface IUpdateContactsParams {
    clientId?: number;
    locationId?: number;
    placesId?: number;
}

interface IUpdateFormsParams {
    locationId?: number;
    placesId?: number;
}

interface IBodyProps {
    formInitialized: boolean;
    kindOfRoute: NewJobAndVisitRouteKind;
    jobId?: string | number | NullOrUndefined;
    visitIdInParams?: string;
    isMobile?: boolean;
    isSmallScreen?: boolean;
    clientId?: number | NullOrUndefined;
    setClientId: Dispatch<SetStateAction<number | undefined>>;
    clientData: ClientResponse | NullOrUndefined;
    clientContactIds: number[];
    setClientContactIds: Dispatch<SetStateAction<number[]>>;
    updateContacts: (params: IUpdateContactsParams) => void;
    updateForms: (params: IUpdateFormsParams) => void;
    contactsData: ClientContactResponsePage | NullOrUndefined;
    setChooseContacts: Dispatch<SetStateAction<boolean>>;
    locationId?: number;
    setLocationId: Dispatch<SetStateAction<number | undefined>>;
    jobForms: CreateVisitJobFormInput[];
    setJobForms: Dispatch<SetStateAction<CreateVisitJobFormInput[]>>;
    locationPlaceId: number | undefined;
    setLocationPlaceId: Dispatch<SetStateAction<number | undefined>>;
    responsiblePerson: number | undefined;
    setResponsiblePerson: Dispatch<SetStateAction<number | undefined>>;
    responsiblePersonData: EmployeeDetailResponse | undefined;
    requiredEmployeeIds: number[];
    setRequiredEmployeeIds: Dispatch<SetStateAction<number[]>>;
    employeeTimesheets: CreateJobEmployeeTimesheetItemInput[];
    setEmployeeTimesheets: Dispatch<SetStateAction<CreateJobEmployeeTimesheetItemInput[]>>;
    locationData: LocationPureResponse | NullOrUndefined;
    placesData: LocationPlaceResponsePage | NullOrUndefined;
    requiredEmployeesData: EmployeeJoinedUserResponsePage | NullOrUndefined;
    jobOffersEmployeesData: EmployeeJoinedUserResponsePage | NullOrUndefined;
    combinedJobForms: JobFormResponse[];
    jobOfferSeats: CreateJobOfferSeatInput[];
    setJobOfferSeats: Dispatch<SetStateAction<CreateJobOfferSeatInput[]>>;
    addJobOfferSeat: (data: IJobOfferData) => void;
    attachments: IFileState[];
    setAttachments: Dispatch<SetStateAction<IFileState[]>>;
    documentsModal: boolean;
    setDocumentsModal: Dispatch<SetStateAction<boolean>>;
    documentsModalIndexOnOpen: number;
    setDocumentsModalIndexOnOpen: Dispatch<SetStateAction<number>>;
    selectedTab: string;
    setSelectedTab: Dispatch<SetStateAction<string>>;
    disabledButton: boolean;
    loading: boolean;
    products: CreateProductInput[];
    setProducts: Dispatch<SetStateAction<CreateProductInput[]>>;
    materials: CreateMaterialInput[];
    setMaterials: Dispatch<SetStateAction<CreateMaterialInput[]>>;
    companyConfigurationOverrideWorkers: boolean;
    visitConfigurationUuid: string;
    visitConfigurations: CreateCompanyConfigurationInput[];
    setVisitConfigurations: Dispatch<SetStateAction<CreateCompanyConfigurationInput[]>>;
    save: (startAnother?: boolean) => void;
    baseInputs: IInputsData;
    setBaseInputs: Dispatch<SetStateAction<IInputsData>>;
    checkboxes: IInputsData;
    setCheckboxes: Dispatch<SetStateAction<IInputsData>>;
    inputsTab1: IInputsData;
    setInputsTab1: Dispatch<SetStateAction<IInputsData>>;
    inputsTab2: IInputsData;
    setInputsTab2: Dispatch<SetStateAction<IInputsData>>;
    inputsTab3: IInputsData;
    setInputsTab3: Dispatch<SetStateAction<IInputsData>>;
    resetForms: number;
    jobData: JobDetailResponse | undefined;
    setJobId: Dispatch<SetStateAction<string | undefined>>;
    inputsAssignJob: IInputsData;
    setInputsAssignJob: Dispatch<SetStateAction<IInputsData>>;
    visitsForConflicts: IVisitEvent[] | NullOrUndefined;
    visitOfferSeatsForConflicts: JobOfferSeatResponse[] | NullOrUndefined;
    visitsNewEvents: IVisitEvent[] | NullOrUndefined;
    recalculateTimesheets: VoidPromiseCallback;
}

/**
 * SignIn screen body contents.
 */
function Body(props: IBodyProps) {
    const {
        formInitialized,
        visitIdInParams,
        jobId,
        kindOfRoute,
        isMobile,
        isSmallScreen,
        clientId,
        setClientId,
        clientData,
        updateContacts,
        updateForms,
        setClientContactIds,
        setChooseContacts,
        clientContactIds,
        contactsData,
        locationId,
        setLocationId,
        jobForms,
        setJobForms,
        locationPlaceId,
        setLocationPlaceId,
        responsiblePerson,
        setResponsiblePerson,
        responsiblePersonData,
        requiredEmployeeIds,
        setRequiredEmployeeIds,
        employeeTimesheets,
        setEmployeeTimesheets,
        locationData,
        placesData,
        requiredEmployeesData,
        jobOffersEmployeesData,
        combinedJobForms,
        jobOfferSeats,
        setJobOfferSeats,
        addJobOfferSeat,
        attachments,
        setAttachments,
        documentsModal,
        setDocumentsModal,
        documentsModalIndexOnOpen,
        setDocumentsModalIndexOnOpen,
        selectedTab,
        setSelectedTab,
        disabledButton,
        products,
        setProducts,
        materials,
        setMaterials,
        companyConfigurationOverrideWorkers,
        visitConfigurationUuid,
        visitConfigurations,
        setVisitConfigurations,
        save,
        baseInputs,
        setBaseInputs,
        checkboxes,
        setCheckboxes,
        inputsTab1,
        setInputsTab1,
        inputsTab2,
        setInputsTab2,
        inputsTab3,
        setInputsTab3,
        resetForms,
        setJobId,
        jobData,
        inputsAssignJob,
        setInputsAssignJob,
        loading,
        visitsForConflicts,
        visitOfferSeatsForConflicts,
        visitsNewEvents,
        recalculateTimesheets,
    } = props;

    const {cx, classes} = useStyles();
    const appDataContext = useContext(AppDataContext);
    const {storage, companyId, userPreferences} = appDataContext;

    const [bottomSheetClients, setBottomSheetClients] = useState<boolean>(false);
    const [createClientModal, setCreateClientModal] = useState<boolean>(false);
    const [createContactModal, setCreateContactModal] = useState<boolean>(false);
    const [bottomSheetResponsiblePerson, setBottomSheetResponsiblePerson] = useState<boolean>(false);
    const [createWorkerModal, setCreateWorkerModal] = useState<boolean>(false);
    const [bottomSheetWorkers, setBottomSheetWorkers] = useState<boolean>(false);
    const [bottomSheetLocations, setBottomSheetLocations] = useState<boolean>(false);
    const [bottomSheetCreateLocation, setBottomSheetCreateLocation] = useState<boolean>(false);
    const [bottomSheetPlaces, setBottomSheetPlaces] = useState<boolean>(false);
    const [bottomSheetCreatePlace, setBottomSheetCreatePlace] = useState<boolean>(false);
    const [createJobFormModal, setCreateJobFormModal] = useState<boolean>(false);
    const [bottomSheetJobForms, setBottomSheetJobForms] = useState<boolean>(false);
    const [bottomSheetOffers, setBottomSheetOffers] = useState<boolean>(false);
    const [bottomSheetWorkersConfiguration, setBottomSheetWorkersConfiguration] = useState<boolean>(false);

    const [calendarSwitch, setCalendarSwitch] = useState<IInputsData>({
        calendar: {
            testId: 'createvisitCalendarSwitchTestId',
            type: InputType.Switch,
            switchVariant: "Android12Switch",
            label: tt('common.calendar').toUpperCase(),
            value: false,
        },
        displayOtherEvents: {
            testId: 'createvisitDisplayOtherEventsSwitchTestId',
            type: InputType.Switch,
            switchVariant: "Android12Switch",
            label: tt('common.displayOtherEvents').toUpperCase(),
            value: true,
        },
    });

    useEffect(() => {
        const newJobCalendarVisible = userPreferences.find(item => item.key === kUserPreferencesNewJobCalendarVisible);

        setCalendarSwitch(prev => {
            return {
                ...prev,
                calendar: {
                    ...prev.calendar,
                    value: isSmallScreen ? false : (newJobCalendarVisible ? newJobCalendarVisible.valueBool : true),
                },
            };
        });
    }, []);

    useEffect(() => {
        setCalendarSwitch(prev => {
            return {
                ...prev,
                displayOtherEvents: {
                    ...prev.displayOtherEvents,
                    hidden: !calendarSwitch.calendar.value,
                },
            };
        });
    }, [calendarSwitch.calendar.value]);

    const tab1ContentJSX = (
        <Box className={classes.tabContainer}>
            <VisitForm
                selectedTab={selectedTab}
                inputs={inputsTab1}
                setInputs={setInputsTab1}
                resetForms={resetForms}
            />
        </Box>
    );

    const tab2ContentJSX = (
        <Box className={classes.tabContainer}>
            <VisitForm
                selectedTab={selectedTab}
                inputs={inputsTab2}
                setInputs={setInputsTab2}
                isRecurringVisit={true}
                resetForms={resetForms}
            />
        </Box>
    );

    const tab3ContentJSX = (
        <Box className={classes.tabContainer}>
            <VisitForm
                selectedTab={selectedTab}
                inputs={inputsTab3}
                setInputs={setInputsTab3}
                customVisit={true}
                resetForms={resetForms}
            />
        </Box>
    );

    const placeJSX = useMemo(() => {
        return (
            <PermissionValid
                permission={kPermissionsClients}
            >
                <Divider/>
                <HeadlineWithButton
                    title={tt('common.place')}
                    iconJSX={<Icons8Here/>}
                    onClick={() => setBottomSheetPlaces(true)}
                    buttonText={placesData?.content.length ? tt('common.change') : tt('common.select')}
                    icon2JSX={<Icons8Plus/>}
                    button2OnClick={() => setBottomSheetCreatePlace(true)}
                    button2Text={tt('newVisit.screen.button.addNewPlaceShort')}
                    permission2={kPermissionsClients}
                    requiredPermissions2={[kActionUpdate]}
                />
                {
                    placesData ?
                        placesData!.content.map(place => (
                            <Box
                                pb={2}
                                key={place.id}
                                className={classes.removeVerticalMargins}>
                                <NewJobPlaceItem data={place} onDelete={(placeId: number) => {
                                    setLocationPlaceId(undefined);

                                    updateContacts({locationId: locationId});

                                    updateForms({locationId: locationId});
                                }
                                }/>
                            </Box>
                        ))
                        : null
                }
            </PermissionValid>
        );
    }, [placesData]);

    const locationsJSX = useMemo(() => {
        return clientId ? (
            <PermissionValid
                permission={kPermissionsClients}
            >
                <Box>
                    <HeadlineWithButton
                        title={tt('common.jobLocation')}
                        iconJSX={<Icons8Location/>}
                        onClick={() => setBottomSheetLocations(true)}
                        buttonText={tt('common.change')}
                        icon2JSX={<Icons8Plus/>}
                        button2OnClick={() => setBottomSheetCreateLocation(true)}
                        button2Text={tt('newVisit.screen.button.addNewLocationShort')}
                        permission2={kPermissionsClients}
                        requiredPermissions2={[kActionUpdate]}
                    />
                </Box>
                {
                    locationData ?
                        <>
                            <NewJobLocationItem
                                locationData={locationData}
                                onDelete={(locationId: number) => {
                                    setLocationId(undefined);

                                    updateContacts({clientId: clientId});
                                }}
                            />

                            {placeJSX}
                        </>
                        : null
                }

                <Divider/>
            </PermissionValid>
        ) : null;
    }, [clientId, locationData, placeJSX]);

    const jobFormJSX = (
        <PermissionValid
            permission={kPermissionsForms}
        >
            <Box>
                <HeadlineWithButton
                    title={tt('common.jobForm')}
                    iconJSX={<Icons8ClipBoardList/>}
                    buttonText={tt('common.select')}
                    onClick={() => setBottomSheetJobForms(true)}
                    icon2JSX={<Icons8Plus/>}
                    button2Text={tt('newJob.screen.button.newJobForm.label')}
                    button2OnClick={() => setCreateJobFormModal(true)}
                    permission2={kPermissionsForms}
                    requiredPermissions2={[kActionCreate]}
                />

                {jobForms
                    .filter((jobForm) => jobForm.input)
                    .map((create, index) => {
                        return (
                            <JobFormItem
                                key={index}
                                isNewJobCreation={true}
                                input={create.input!}
                                onDelete={() => {
                                    const newJobForms = [
                                        ...jobForms.filter((item) => item.jobFormId),
                                    ];

                                    const jobFormInputs = jobForms.filter((item) => item.input);

                                    jobFormInputs.splice(index, 1);

                                    setJobForms([...newJobForms, ...jobFormInputs]);
                                }}
                                canEdit={true}
                                onRename={(newName: string) => {
                                    setJobForms(jobForms.map((item, i) => {
                                        if (i === index) {
                                            return {
                                                ...item,
                                                name: newName,
                                            };
                                        }

                                        return item;
                                    }));
                                }}
                                overrideName={create.name}
                            />
                        );
                    })}

                {jobForms
                    .filter((jobForm) => jobForm.jobFormId)
                    .map((create, index) => {
                        const jobFormData = combinedJobForms.find((item) => item.id === create.jobFormId);

                        if (!jobFormData) {
                            return null;
                        }

                        return (
                            <JobFormItem
                                key={index}
                                isNewJobCreation={true}
                                data={jobFormData}
                                onDelete={() => {
                                    const newJobForms = [
                                        ...jobForms.filter((item) => item.input),
                                    ];

                                    const jobFormIds = jobForms.filter((item) => item.jobFormId);

                                    jobFormIds.splice(index, 1);

                                    setJobForms([...newJobForms, ...jobFormIds]);
                                }}
                                canEdit={true}
                                onRename={(newName: string) => {
                                    setJobForms(jobForms.map((item, i) => {
                                        if (i === index) {
                                            return {
                                                ...item,
                                                name: newName,
                                            };
                                        }

                                        return item;
                                    }));
                                }}
                                overrideName={create.name}
                            />
                        );
                    })}
            </Box>

            <Divider/>
        </PermissionValid>
    );

    const responsiblePersonJSX = kindOfRoute === NewJobAndVisitRouteKind.default && responsiblePersonData ? (
        <AppListItem
            profileImage={
                UserPhotoUrl(responsiblePersonData.employee.user, responsiblePersonData?.customPhotoFile ? [responsiblePersonData?.customPhotoFile] : undefined, storage.publicUrlsForFiles)
            }
            variant={"smaller-title"}
            title={UserFullName(responsiblePersonData.employee?.name || responsiblePersonData.employee?.user?.name, responsiblePersonData.employee?.surname || responsiblePersonData.employee?.user?.surname)}
            description={UserRoleTitle(responsiblePersonData.employee.role)}
            actionWidget={<></>}
        />
    ) : null;

    const requiredEmployeesJSX = useMemo(() => {
        return (
            <>
                {requiredEmployeesData?.content.map((worker, index) => {
                    return (
                        <NewJobChosenWorkerItem
                            key={worker.id}
                            onDelete={
                                (workerId: number) => {
                                    const newListOfWorkers = requiredEmployeeIds.filter((id) => id !== workerId);

                                    setRequiredEmployeeIds(newListOfWorkers);
                                }
                            }
                            data={worker}
                            newJobExistingData={employeeTimesheets.filter((item) => item.employeeId === worker.id)}
                            onUpdateNewJobExistingData={setEmployeeTimesheets}
                            visitsForConflicts={visitsForConflicts}
                            visitOfferSeatsForConflicts={visitOfferSeatsForConflicts}
                            visitsNewEvents={visitsNewEvents}
                            files={requiredEmployeesData?.files}
                            onResetConfigurationOverrideActive={recalculateTimesheets}
                        />
                    );
                })}
            </>
        );
    }, [requiredEmployeesData, requiredEmployeeIds, employeeTimesheets, visitsForConflicts, visitOfferSeatsForConflicts, visitsNewEvents, storage.publicUrlsForFiles]);

    const jobOfferSeatsJSX = useMemo(() => {
        return (
            <>
                {jobOfferSeats.map((seat, index) => {
                    return (
                        <VisitOfferItem
                            key={index}
                            isNewJobCreation={true}
                            index={index}
                            onDelete={() => {
                                const newJobOfferSeats = jobOfferSeats.filter((item) => item !== seat);

                                setJobOfferSeats(newJobOfferSeats);
                            }}
                            onDuplicate={() => addJobOfferSeat({
                                type: seat.paymentType,
                                fixedPrice: seat.paymentType === PaymentType.Fixed ? (seat.fixedPrice || 0) : 0,
                                hours: seat.paymentType === PaymentType.Fixed ? 0 : (seat.hours || 0),
                                minutes: seat.paymentType === PaymentType.Fixed ? 0 : (seat.minutes || 0),
                                hourRate: seat.paymentType === PaymentType.Fixed ? 0 : (seat.hourRate || 0),
                                employeeIds: seat.employeeIds,
                                substituteCount: seat.substituteCount,
                                sendNotifications: false,
                            })}
                            onUpdate={(data: IJobOfferData) => {
                                const newJobOfferSeats = jobOfferSeats.map((item) => {
                                    if (item === seat) {
                                        return {
                                            paymentType: data.type,
                                            fixedPrice: data.type === PaymentType.Fixed ? data.fixedPrice : 0,
                                            hours: data.type === PaymentType.Fixed ? 0 : data.hours,
                                            minutes: data.type === PaymentType.Fixed ? 0 : data.minutes,
                                            hourRate: data.type === PaymentType.Fixed ? 0 : data.hourRate,
                                            employeeIds: data.employeeIds,
                                            substituteCount: data.substituteCount,
                                        };
                                    }

                                    return item;
                                });

                                setJobOfferSeats(newJobOfferSeats);
                            }}
                            newJobExistingData={seat}
                            excludeEmployeeIds={requiredEmployeeIds}
                            jobEmployeeData={jobOffersEmployeesData?.content}
                            files={jobOffersEmployeesData?.files}
                        />
                    );
                })}
            </>
        );
    }, [jobOfferSeats, requiredEmployeeIds, jobOffersEmployeesData]);

    const workersJSX = (
        <PermissionValid
            permission={kPermissionsWorkers}
        >
            <HeadlineWithButton
                title={tt('newJob.screen.label.workers')}
                iconJSX={<SettingsIcon/>}
                buttonText={tt('newJob.screen.workers.configuration')}
                onClick={companyConfigurationOverrideWorkers ? () => {
                    setBottomSheetWorkersConfiguration(true);
                } : undefined}
                permission={kPermissionsCompanyConfiguration}
                requiredPermissions={[kActionUpdate]}
                icon2JSX={<Icons8Contacts/>}
                button2Text={tt('newJob.screen.button.select')}
                button2OnClick={() => {
                    setBottomSheetWorkers(true);
                }}
                icon3JSX={<Icons8Reseller/>}
                button3Text={selectedTab !== '1' ? tt('newJob.screen.button.offer') : undefined}
                button3OnClick={selectedTab !== '1' ? () => {
                    setBottomSheetOffers(true);
                } : undefined}
                icon4JSX={<Icons8Plus/>}
                button4Text={tt('newJob.screen.button.addWorker')}
                button4OnClick={() => setCreateWorkerModal(true)}
                permission4={kPermissionsWorkers}
                requiredPermissions4={[kActionCreate]}
            />

            {requiredEmployeesJSX}

            {jobOfferSeatsJSX}

            {requiredEmployeesData?.content.length || jobOfferSeats.length ?
                <VisitEmployeesTotalSection
                    timesheets={employeeTimesheets.filter(timesheet => requiredEmployeeIds.includes(timesheet.employeeId))}
                    jobOfferSeats={jobOfferSeats}
                />
                : null}
        </PermissionValid>
    );

    const attachmentsJSX = (
        <PermissionValid
            permission={kPermissionsVisitAttachments}
        >
            <HeadlineWithButton
                title={tt('common.attachment')}
                iconFileUploadJSX={<Icons8Upload/>}
                fileUploadText={tt('common.upload')}
                fileAccept={'image/*,application/pdf'}
                fileChosen={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const htmlInput = e.target;

                    if (htmlInput.files && htmlInput.files.length > 0) {
                        const newAttachments = [...attachments];

                        for (let i = 0; i < htmlInput.files.length; i++) {
                            newAttachments.push({
                                type: IFileStateType.Select,
                                uuid: uuidv4(),
                                companyId: companyId,
                                category: kStorageCategoryJobDocuments,
                                fileAccept: 'image/*,application/pdf',
                                inputFile: htmlInput.files[i],
                            });
                        }

                        setAttachments(newAttachments);
                    }
                }}
                fileMultiple={true}
                filePermission={kPermissionsVisitAttachments}
                fileRequiredPermissions={[kActionCreate]}
            />

            {attachments.map((document, index) => {
                return (
                    <AttachmentListItem
                        key={index}
                        isNewJobCreation={true}
                        canEdit={true}
                        fileState={document}
                        onUpdateFileState={(updateFileState: (fileState: IFileState) => IFileState, fileUploadFinished?: boolean) => {
                            setAttachments(prev => {
                                return prev.map((item) => {
                                    if (item.uuid === document.uuid) {
                                        return updateFileState(item);
                                    }

                                    return item;
                                });
                            });
                        }}
                        onDelete={(uuid: string) => {
                            setAttachments(prev => {
                                return prev.filter((item) => item.uuid !== uuid);
                            });
                        }}
                        onPreview={(uuid: string) => {
                            const index = attachments.findIndex((item) => item.uuid === uuid);

                            setDocumentsModalIndexOnOpen(index);

                            setDocumentsModal(true);
                        }}
                    />
                );
            })}

            <Divider/>
        </PermissionValid>
    );

    let calendarInputs = selectedTab === '0' ? inputsTab1 : inputsTab2;
    calendarInputs = selectedTab === '2' ? inputsTab3 : calendarInputs;

    const clientJSX = useMemo(() => {
        return (
            <PermissionValid
                permission={kPermissionsClients}
            >
                {kindOfRoute === NewJobAndVisitRouteKind.default ? (
                    <HeadlineWithButton
                        title={tt('common.client')}

                        buttonText={tt('common.change')}
                        iconJSX={<Icons8Contacts/>}
                        onClick={() => setBottomSheetClients(true)}
                        button2OnClick={() => setCreateClientModal(true)}
                        button2Text={tt('newJobScreen.button.addNewClientShort')}
                        icon2JSX={<Icons8Plus/>}
                        permission2={kPermissionsClients}
                        requiredPermissions2={[kActionCreate]}
                    />
                ) : (
                    <HeadlineWithButton
                        title={tt('common.client')}
                    />
                )}

                {
                    clientData ?
                        <AppListItem
                            key={clientData.id}
                            customAvatar={clientData.company ? <Icons8Company/> : <UserIcon/>}
                            // description={clientData?.company ? tt('common.companyClient') : undefined}
                            description={displayClientTaxInfoAsText({
                                client: clientData,
                                ignoreTaxId: true,
                                displayBillingAddress: true
                            })}
                            title={clientData.name}
                            variant={"smaller-title"}
                            actionWidget={
                                <>
                                    {clientData.phoneNumber ?
                                        <PhoneMenu phone={clientData.phoneNumber}/> : null}
                                </>
                            }
                        /> : null
                }
            </PermissionValid>
        );
    }, [clientData, kindOfRoute]);

    const contactsJSX = contactsData && clientId ? (
        <PermissionValid
            permission={kPermissionsClients}
        >
            <HeadlineWithButton
                title={tt('newJob.screen.label.contactPersons')}
                buttonText={tt('newJob.screen.button.select')}
                iconJSX={<Icons8Contacts/>}
                onClick={() => setChooseContacts(true)}
                icon2JSX={<Icons8Plus/>}
                button2Text={tt('newJob.screen.button.newContactPerson.label')}
                button2OnClick={() => setCreateContactModal(true)}
                permission2={kPermissionsClients}
                requiredPermissions2={[kActionUpdate]}
            />

            {
                contactsData?.content.map(contact => (
                    <Box key={contact.id}>
                        <NewJobContactPerson key={contact.id} data={contact} onDelete={
                            (contactId: number) => {
                                const newIds = clientContactIds.filter((id) => id !== contactId);

                                setClientContactIds(newIds);
                            }
                        }/>
                    </Box>
                ))
            }
            <Divider/>
        </PermissionValid>
    ) : null;

    let backRoute = getBackRoute(jobDetailRoute({
        jobId: jobId!,
    }));

    if (kindOfRoute === NewJobAndVisitRouteKind.fromClientDetail) {
        backRoute = getBackRoute(clientDetailRoute(clientId!));
    }

    let hideBackButton = !(kindOfRoute === NewJobAndVisitRouteKind.fromJobDetail || kindOfRoute === NewJobAndVisitRouteKind.fromClientDetail);

    if (isSmallScreen) {
        backRoute = getBackRoute(kDashboardRoute);
        hideBackButton = false;
    }

    const leftContentClassName = cx(
        classes.leftContentPaper,
        !isSmallScreen && calendarSwitch.calendar.value ? classes.leftContentPaperCalendarOpen : undefined,
    )

    return (
        <>
            <ScreenContent
                appBar={!isMobile}
                navigationDrawer={!isMobile}
                noContentPadding={isMobile}
                centerHorizontally={true}
            >
                <Box className={classes.screenContentInner}>
                    <AppPaper className={leftContentClassName}>
                        <PaperAppbar
                            noMarginBottom={true}
                            isMobile={isMobile}
                            title={tt('newVisit.screen.title')}
                            backRoute={backRoute}
                            hideBackButton={hideBackButton}
                            cancelIconBackButton={true}
                        />

                        <Box pl={2} pr={2} pt={2}>
                            <DebouncedForm
                                inputs={baseInputs}
                                setInputs={setBaseInputs}
                                initialized={formInitialized}
                            />
                        </Box>

                        <Divider/>

                        {clientJSX}

                        <Divider/>

                        {contactsJSX}

                        <AssignToExistingJobSection
                            client={clientData}
                            jobData={jobData}
                            jobId={jobId}
                            setJobId={setJobId}
                            setInputs={setInputsAssignJob}
                            inputs={inputsAssignJob}
                            kindOfRoute={kindOfRoute}
                        />

                        {locationsJSX}

                        {attachmentsJSX}

                        {jobFormJSX}

                        <AppTabsComponent
                            controlledValue={selectedTab}
                            variant={"fullWidth"}
                            scrollButtons={false}
                            fullWidthTabs={true}
                            data={[
                                {
                                    label: `${tt('newVisit.screen.tabTitle.oneOrMoreTimeVisits')}`,
                                    content: tab1ContentJSX
                                },
                                {
                                    icon: <Icons8Reset/>,
                                    label: `${tt('newVisit.screen.tabTitle.recurringVisits')}`,
                                    content: tab2ContentJSX
                                },
                                {
                                    label: `${tt('newVisit.screen.tabTitle.customVisits')}`,
                                    content: tab3ContentJSX
                                }
                            ]} onTabChange={setSelectedTab}
                        />

                        <Box className={classes.dashedDivider}/>

                        <Box className={classes.switchesContainer} pr={2} pl={2} pt={1} pb={0.5}>
                            <FormBuilder inputs={calendarSwitch} setInputs={setCalendarSwitch}/>
                        </Box>

                        {isSmallScreen ? (
                            <NewVisitCalendar
                                isSmallScreen={isSmallScreen}
                                isOpen={calendarSwitch.calendar.value}
                                baseInputs={baseInputs}
                                selectedTab={selectedTab}
                                inputs={calendarInputs}
                                currentJobId={jobId}
                                displayOtherEvents={calendarSwitch.displayOtherEvents.value}
                            />
                        ) : null}

                        <Divider/>

                        {workersJSX}

                        <PermissionValid
                            hasSomePermissionsParams={[
                                {permission: kPermissionsProducts, requiredPermissions: [kActionView]},
                                {permission: kPermissionsMaterials, requiredPermissions: [kActionView]},
                            ]}
                        >
                            <>
                                <Divider/>

                                <NewJobProductMaterialsSection
                                    products={products}
                                    materials={materials}
                                    setProducts={setProducts}
                                    setMaterials={setMaterials}
                                />
                            </>
                        </PermissionValid>

                        <VisitTotalSection
                            requiredEmployeeIds={requiredEmployeeIds}
                            timesheets={employeeTimesheets}
                            jobOfferSeats={jobOfferSeats}
                            products={products}
                            materials={materials}
                            multiplier={selectedTab === '2' && inputsTab3.datesPicker?.value?.length ? inputsTab3.datesPicker.value.length : null}
                        />

                        {kindOfRoute === NewJobAndVisitRouteKind.default && !inputsAssignJob.assignToJob.value ? (
                            <>
                                <Divider/>

                                <HeadlineWithButton
                                    title={tt('newJob.screen.label.responsiblePerson')}
                                    iconJSX={<Icons8Change/>}
                                    buttonText={tt('common.change')}
                                    onClick={() => {
                                        setBottomSheetResponsiblePerson(true);
                                    }}
                                />

                                {responsiblePersonJSX}
                            </>
                        ) : undefined}

                        <Divider/>

                        <Box pt={2} pl={2} pr={2}>
                            <GreyLabel text={tt('common.jobSettings')}/>

                            <FormBuilder inputs={checkboxes} setInputs={setCheckboxes}/>
                        </Box>

                        <Box sx={{pb: 3}}/>

                        <Box pl={2} pr={2}>
                            {visitIdInParams ? (
                                <AppButton
                                    variant="contained"
                                    fullWidth={true}
                                    disabled={disabledButton}
                                    isLoading={loading}
                                    onClick={() => {
                                        save(false);
                                    }}
                                >
                                    {tt('common.save')}
                                </AppButton>
                            ) : (
                                <SplitButton
                                    isLoading={loading}
                                    onChange={
                                        (index) => {
                                            save(true);
                                        }
                                    }
                                    disabled={disabledButton}
                                    onClick={
                                        (index, value) => {
                                            save(false);
                                        }
                                    }
                                    text={tt('common.create')}
                                    options={[
                                        tt('newVisit.screen.splitButtonOption.createAndStartAnother'),
                                    ]}
                                />
                            )}
                        </Box>

                        <Box sx={{pb: 2}}/>
                    </AppPaper>

                    {isSmallScreen ? null : (
                        <NewVisitCalendar
                            isSmallScreen={isSmallScreen}
                            isOpen={calendarSwitch.calendar.value}
                            baseInputs={baseInputs}
                            selectedTab={selectedTab}
                            inputs={calendarInputs}
                            currentJobId={jobId}
                            displayOtherEvents={calendarSwitch.displayOtherEvents.value}
                        />
                    )}
                </Box>
            </ScreenContent>

            <ChooseClientModalBottomSheet
                open={bottomSheetClients}
                setOpen={setBottomSheetClients}
                onSave={(id: number) => {
                    setClientId(id);

                    updateContacts({clientId: id});
                }}
                existingChosenClientId={clientId || undefined}
            />

            <CreateClientModal
                open={createClientModal}
                setOpen={setCreateClientModal}
                navigation={false}
                onCreate={(id: number) => {
                    setTimeout(() => {
                        setClientId(id);

                        updateContacts({clientId: id});
                    }, 400);
                }}
            />

            {clientId ? (
                <CreateContactPersonModal
                    open={createContactModal}
                    setOpen={setCreateContactModal}
                    clientId={clientId}
                    navigation={false}
                    onCreate={(id: number) => {
                        setTimeout(() => {
                            setClientContactIds([...clientContactIds, id]);
                        }, 400);
                    }}
                />
            ) : null}

            <CreateLocationModal
                clientId={clientId || 0}
                open={bottomSheetCreateLocation}
                setOpen={setBottomSheetCreateLocation}
                navigation={false}
                onCreate={(id: number) => {
                    setTimeout(() => {
                        setLocationId(id);

                        updateContacts({locationId: id});

                        updateForms({locationId: id});
                    }, 400);
                }}
            />

            <ChooseLocationModalBottomSheet
                clientId={clientId || 0}
                open={bottomSheetLocations}
                setOpen={setBottomSheetLocations}
                onSave={(id: number) => {
                    setLocationId(id);

                    updateContacts({locationId: id});

                    updateForms({locationId: id});
                }}
                existingLocationId={locationId}
            />

            <CreateLocationPlaceModal
                onCreate={(ids: number[]) => {
                    setLocationPlaceId(ids[0]);

                    updateContacts({placesId: ids[0]});

                    updateForms({placesId: ids[0]});
                }}
                open={bottomSheetCreatePlace}
                setOpen={setBottomSheetCreatePlace}
                locationId={locationId || 0}
                clientId={clientId || 0}
                navigation={false}/>

            <ChoosePlaceModalBottomSheet
                multipleSelect={false}
                clientId={clientId || 0}
                locationId={locationId || 0}
                existingLocationPlaceIds={locationPlaceId ? [locationPlaceId] : []}
                open={bottomSheetPlaces}
                setOpen={setBottomSheetPlaces}
                onSave={(ids: number[]) => {
                    setLocationPlaceId(ids[0]);

                    updateContacts({placesId: ids[0]});

                    updateForms({placesId: ids[0]});
                }}
            />

            <CreateJobFormModal
                open={createJobFormModal}
                setOpen={setCreateJobFormModal}
                onCreate={(params: INewJobFormModalPropsOnCreateParams) => {
                    setJobForms(prev => {
                        const newForms = [...prev];

                        if (params.id) {
                            newForms.push({
                                jobFormId: params.id,
                            });
                        } else if (params.input) {
                            newForms.push({
                                input: params.input,
                            })
                        }

                        return newForms;
                    });
                }}
                saveAsSingleOrTemplate={true}
            />

            <ChooseJobFormModalBottomSheet
                open={bottomSheetJobForms}
                setOpen={setBottomSheetJobForms}
                onSave={(params: IChooseJobFormModalBottomSheetOnSaveParams) => {
                    setJobForms(prev => {
                        const newForms = [...prev];

                        if (params.ids) {
                            newForms.push(...params.ids.map((id) => {
                                return {
                                    jobFormId: id,
                                };
                            }))
                        } else if (params.input) {
                            newForms.push({
                                input: params.input,
                            });
                        }

                        return newForms;
                    });
                }}
                saveAsSingleOrTemplate={true}
            />

            <ChooseResponsiblePersonModalBottomSheet
                open={bottomSheetResponsiblePerson}
                setOpen={setBottomSheetResponsiblePerson}
                onSave={setResponsiblePerson}
                existingChosenPerson={responsiblePerson}
            />

            <CreateWorkerModal
                setOpen={setCreateWorkerModal}
                open={createWorkerModal}
                navigation={false}
                onCreate={(id: number) => {
                    setRequiredEmployeeIds([...requiredEmployeeIds, id]);
                }}
            />

            <ChooseWorkersModalBottomSheet
                open={bottomSheetWorkers}
                setOpen={setBottomSheetWorkers}
                existingEmployeeIds={requiredEmployeeIds}
                onSave={setRequiredEmployeeIds}
                canSaveToClear={true}
                employeeTimesheetsCreateInput={employeeTimesheets}
            />

            <JobOfferModalBottomSheet
                open={bottomSheetOffers}
                setOpen={setBottomSheetOffers}
                onSave={addJobOfferSeat}
                existingEmployeeIds={[]}
                excludeEmployeeIds={requiredEmployeeIds}
            />

            <PreviewFileModal
                onClose={() => setDocumentsModal(false)}
                open={documentsModal}
                fileStates={attachments}
                indexOnOpen={documentsModalIndexOnOpen}
            />

            <NewVisitConfigurationsModalBottomSheet
                open={bottomSheetWorkersConfiguration}
                setOpen={setBottomSheetWorkersConfiguration}
                uuid={visitConfigurationUuid}
                visitConfigurations={visitConfigurations}
                setVisitConfigurations={setVisitConfigurations}
            />
        </>
    );
}

/**
 * Calculate Visit events based on inputs.
 */
export function NewVisitEvents(
    selectedTab: string,
    frequency: VisitRepeating | undefined,
    inputs: IInputsData,
    baseInputs: IInputsData | undefined,
    data?: CreateVisitInput,
): IVisitEvent[] {
    const scheduleLater = data ? data.scheduleLater : inputs.scheduleLater?.value;

    if (frequency && !scheduleLater) {
        const startDate = DateTime.fromMillis(data?.startDate || inputs.startDate.value);
        let endDate = data ? DateTime.fromMillis(data?.repeatEndDate || data?.endDate || startDate.toMillis())
            : DateTime.fromMillis(inputs.endDate.value);

        const neverEnding = data ? data.repeatEndDate === undefined : inputs.neverEnding.value;
        if (neverEnding) {
            endDate = startDate.plus({years: 10}).endOf('year');
        }

        let startTime = DateTime.fromMillis(data?.startTime || inputs.startTime.value);
        let endTime = DateTime.fromMillis(data?.endTime || inputs.endTime.value);

        const repeating = data ? data.repeating : selectedTab === '1' ? inputs.frequency.value : undefined;
        const isSingle = !repeating || repeating === VisitRepeating.Never;

        const repeatWeekDays = data ? data.repeatWeekDays : inputs.weekdayPicker.value.map((day: string) => parseInt(day));

        if (repeating === VisitRepeating.Weekly && repeatWeekDays.length < 1) {
            return [];
        }

        const monthDays = data ? data.monthDays : inputs.monthDayPicker.value.map((day: string) => parseInt(day));
        const monthWeekDays = data ? data.monthWeekDays : inputs.monthWeekDayPicker.value;

        let repeatModifier = data ? data.repeatModifier : undefined;
        let scheduleLater = data ? data.scheduleLater : false;
        if (!data) {
            if (selectedTab === '0' && inputs.skipWeekends.value) {
                repeatModifier = VisitRepeatModifier.SkipWeekends;
            }

            if (selectedTab === '0') {
                scheduleLater = inputs.scheduleLater.value;
            }

            if (selectedTab === '1' && repeating === VisitRepeating.Daily && inputs.skipWeekends.value) {
                repeatModifier = VisitRepeatModifier.SkipWeekends;
            }

            if (selectedTab === '2') {
                repeatModifier = VisitRepeatModifier.CustomDates;
            }
        }
        if (!data && repeating === VisitRepeating.Monthly) {
            if (monthDays && inputs.monthDaysType.value === VisitRepeatModifier.DayOfMonth) {
                if (monthDays.length > 1) {
                    repeatModifier = monthDays.includes(-1) ? VisitRepeatModifier.DayOfMonthOrLastDay : VisitRepeatModifier.DayOfMonth;
                } else {
                    repeatModifier = monthDays.includes(-1) ? VisitRepeatModifier.LastDayOfMonth : VisitRepeatModifier.DayOfMonth;
                }

                if (monthDays.length < 1) {
                    return [];
                }
            }

            if (monthWeekDays && inputs.monthDaysType.value === VisitRepeatModifier.DayOfWeek) {
                repeatModifier = VisitRepeatModifier.DayOfWeek;

                if (monthWeekDays.length < 1) {
                    return [];
                }
            }
        }

        let theStartTime = data ? data.startTime : inputs.startTime.value;
        let theEndTime = data ? data.endTime : inputs.endTime.value;
        let theStartDate = data ? data.startDate : startDate.set({
            hour: startTime.hour,
            minute: startTime.minute,
            second: startTime.second,
            millisecond: startTime.millisecond,
        }).toMillis();
        let theEndDate = data ? data.endDate : (isSingle ? endDate.set({
            hour: endTime.hour,
            minute: endTime.minute,
            second: endTime.second,
            millisecond: endTime.millisecond,
        }).toMillis() : undefined);
        let theRepeatEndDate = data ? data.repeatEndDate : (inputs.neverEnding.value ? undefined : inputs.endDate.value);
        let theWholeDay = data ? data.wholeDay : inputs.wholeDay.value;
        let theSkipDays = data ? data.skipDays : inputs.skipOtherDays.value;

        if (theWholeDay) {
            theStartTime = DateTime.fromMillis(theStartTime).startOf('day').toMillis();
            theEndTime = DateTime.fromMillis(theEndTime).endOf('day').toMillis();

            theStartDate = DateTime.fromMillis(theStartDate).startOf('day').toMillis();

            if (theEndDate) {
                theEndDate = DateTime.fromMillis(theEndDate).endOf('day').toMillis();
            }

            if (theRepeatEndDate) {
                theRepeatEndDate = DateTime.fromMillis(theRepeatEndDate).endOf('day').toMillis();
            }
        }

        let visit: VisitListPureResponse = {
            jobId: 0,
            name: data?.name || baseInputs?.name.value || undefined,
            startTime: theStartTime,
            endTime: theEndTime,
            startDate: theStartDate,
            endDate: theEndDate,
            repeatEndDate: theRepeatEndDate,
            repeating: repeating,
            repeatModifier: repeatModifier,
            repeatWeekDays: repeatWeekDays,
            every: data ? data.every : inputs.every.value,
            monthDays: monthDays,
            monthWeekDays: monthWeekDays,
            customDates: data ? data.customDates : selectedTab === '2' ? inputs.datesPicker.value : undefined,
            status: scheduleLater ? VisitStatus.ScheduleLater : VisitStatus.Scheduled,
            wholeDay: theWholeDay,
            skipDays: theSkipDays,
            id: 0,
            uuid: '',
            sequenceId: 1,
            siblingIds: [],
            companyId: 0,
            clientId: 0,
            locationId: 0,
            clientContactIds: [],
            createdAt: DateTime.now(),
            createdEmployeeId: 0,
            createdUserId: 0,
            deleted: false,
            employeeIds: [],
            requiredEmployeeIds: [],
            ended: false,
            visitDefectsCount: 0,
            visitProtocolsCount: 0,
            outputDocumentsCount: 0,
        };

        return VisitsProcess({
            nonRepeating: isSingle ? [visit] : [],
            repeating: !isSingle ? [visit] : [],
            visitRepeatDays: [],
            from: startDate.startOf('day'),
            to: endDate.endOf('day'),
        });
    }

    return [];
}
