import ResponsiveContainer from "../screens/ResponsiveContainer";
import {useContext} from "react";
import BannerTextAndButtonV2 from "../banners/BannerTextAndButtonV2";
import Icons8SandClock from "../../../icons/Icons8SandClock";
import {tt} from "../../../core/Localization";
import Icons8ShoppingCart from "../../../icons/Icons8ShoppingCart";
import ScreenContent from "../screens/ScreenContent";
import MobileChoseCompanyAppBar from "../MobileChoseCompanyAppBar";
import {AppDataContext} from "../../../AppData";
import {hasPermission} from "../permissions/PermissionValid";
import {kActionCreate, kPermissionsCompanySubscription} from "../../../core/constants";
import {Box, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {openTrialExtensionRequestForm} from "../../../service/SubscriptionService";
import Icons8SandWatch from "../../../icons/Icons8SandWatch";
import {trackDataLayerEvent} from "../../../service/AnalyticsService";
import {kSimpleShopRoute} from "../../screens/company/subscription/SimpleShopScreen";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles()((theme: Theme) => ({
    mobileAppBarContainer: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
    },
}));

export interface IInvalidCompanySubscriptionWidgetProps {
    isTrial: boolean;
}

/**
 * Component to display screen content as widget about invalid Company Subscription.
 */
export default function InvalidCompanySubscriptionWidget(props: IInvalidCompanySubscriptionWidgetProps) {
    const {isTrial} = props;

    return (<ResponsiveContainer
            smallPhoneScreen={<Body isMobile={true} isTrial={isTrial}/>}
            largePhoneScreen={<Body isMobile={true} isTrial={isTrial}/>}
            tabletScreen={<Body isTrial={isTrial}/>}
            smallDesktopScreen={<Body isTrial={isTrial}/>}
            largeDesktopScreen={<Body isTrial={isTrial}/>}
            extraLargeDesktopScreen={<Body isTrial={isTrial}/>}/>
    );
}

interface IBodyProps {
    isMobile?: boolean;
    isTrial: boolean;
}

function Body(props: IBodyProps) {
    const {isMobile, isTrial} = props;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap, companyData, company} = appDataContext;

    const {classes} = useStyles();
    const navigate = useNavigate();

    const permission = hasPermission(kPermissionsCompanySubscription, [kActionCreate], employeePermissionsMap);

    return (
        <ScreenContent appBar={!isMobile} centerContent={true} navigationDrawer={!isMobile} bottomBar={isMobile}>
            {isMobile ? (
                <Box className={classes.mobileAppBarContainer}><MobileChoseCompanyAppBar/></Box>
            ) : null}

            {isTrial ? (
                <BannerTextAndButtonV2
                    topDecoration={<Icons8SandClock/>}
                    title={tt('banner.trialVersionEnded.title')}

                    buttonText={tt('banner.trialVersionEnded.button')}
                    buttonIcon={<Icons8ShoppingCart/>}
                    onTap={permission ? () => {
                        trackDataLayerEvent('purchase_page_after_trial_ends_btn_click');

                        navigate(kSimpleShopRoute);
                    } : undefined}
                    buttonText2={tt('banner.trialVersionEnded.button.extend')}
                    buttonIcon2={<Icons8SandWatch/>}
                    onTap2={permission ? () => {
                        openTrialExtensionRequestForm(
                            company!,
                            companyData.employee!,
                        );
                    } : undefined}
                />
            ) : (
                <BannerTextAndButtonV2
                    topDecoration={<Icons8SandClock/>}
                    title={tt('banner.paidVersionEnded.title')}
                    buttonText={tt('banner.paidVersionEnded.button')}
                    buttonIcon={<Icons8ShoppingCart/>}
                    onTap={permission ? () => {
                        trackDataLayerEvent('purchase_btn_click');

                        navigate(kSimpleShopRoute);
                    } : undefined}
                />
            )}
        </ScreenContent>
    );
}
