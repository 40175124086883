import {Box, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {useContext, useEffect, useRef, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {GetSubscriptionDaysLeft, GetSubscriptionSecondsFromStart} from "../../../service/SubscriptionService";
import Icons8SandClock from "../../../icons/Icons8SandClock";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {tt} from "../../../core/Localization";
import {useNavigate} from "react-router-dom";
import {kSimpleShopRoute} from "../../screens/company/subscription/SimpleShopScreen";
import {hasPermission} from "../permissions/PermissionValid";
import {kActionCreate, kPermissionsCompanySubscription} from "../../../core/constants";
import Icons8Gift from "../../../icons/Icons8Gift";
import {trackDataLayerEvent} from "../../../service/AnalyticsService";

export const kDiscountDuration = 5 * 24 * 60 * 60 * 1000;
export const kDiscountPercent = 30;
export const kDiscountMonths = 3;

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: "flex",
        width: '100%',
        height: 42,
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        background: "black",
    },
    containerMobile: {
        flexDirection: "column",
        height: "auto",
        paddingTop: 8,
        paddingBottom: 16,
    },
    contentDummy: {
        display: "block",
        width: '100%',
        height: 42,
        minHeight: 42,
    },
    firstLine: {
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
    },
    icon: {
        color: kAppColors.trial.main,
        marginRight: 8,
    },
    title: {
        color: 'white',
        fontSize: 16,
        fontWeight: 600,
        marginRight: 8,
    },
    link: {
        color: kAppColors.primary.main,
        fontSize: 16,
        fontWeight: 600,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

export interface IBuySubscriptionBannerProps {
    className?: string;
}

/**
 * Banner component to display banner with cta to buy subscription.
 * BuySubscriptionBannerDummy needs to be used correctly to move content.
 */
export default function BuySubscriptionBanner(props: IBuySubscriptionBannerProps) {
    const {className} = props;

    const appDataContext = useContext(AppDataContext);
    const {isMobile, companyData, employeePermissionsMap, companySubscriptionStatus} = appDataContext;

    const subscription = companySubscriptionStatus?.subscription;

    const permission = hasPermission(kPermissionsCompanySubscription, [kActionCreate], employeePermissionsMap);
    const displayBanner = companyData.subscriptionIsTrial && permission;

    const daysLeft = GetSubscriptionDaysLeft(companySubscriptionStatus?.subscription);
    const [secondsFromStart, setSecondsFromStart] = useState(GetSubscriptionSecondsFromStart(subscription));
    const secondsLeftForDiscount = kDiscountDuration - secondsFromStart * 1000;

    const countDownInterval = useRef<NodeJS.Timeout>();

    const hasDiscount = secondsFromStart * 1000 < kDiscountDuration && companyData.subscriptionIsValid;

    const {classes, cx} = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        setSecondsFromStart(GetSubscriptionSecondsFromStart(subscription));
    }, [subscription]);

    useEffect(() => {
        if (countDownInterval.current) {
            clearInterval(countDownInterval.current!);
        }

        if (hasDiscount) {
            countDownInterval.current = setInterval(() => {
                setSecondsFromStart(prev => prev + 1);
            }, 1000);
        }
    }, [hasDiscount]);

    useEffect(() => {
        return () => {
            if (countDownInterval.current) {
                clearInterval(countDownInterval.current!);
            }
        };
    }, []);

    if (!displayBanner) {
        return null;
    }

    const displaySecondsLeftForDiscount = Math.round(secondsLeftForDiscount / 1000);
    const displayMinutesLeftForDiscount = Math.floor(displaySecondsLeftForDiscount / 60);
    const displayHoursLeftForDiscount = Math.floor(displayMinutesLeftForDiscount / 60);
    const displayDaysLeftForDiscount = Math.floor(displayHoursLeftForDiscount / 24);

    const displayDaysLeftForDiscountString = displayDaysLeftForDiscount > 0 ? displayDaysLeftForDiscount : 0;
    const displayHoursLeftForDiscountString = displayHoursLeftForDiscount % 24 > 0 ? displayHoursLeftForDiscount % 24 : 0;
    const displayMinutesLeftForDiscountString = displayMinutesLeftForDiscount % 60 > 0 ? displayMinutesLeftForDiscount % 60 : 0;

    const containerClassName = cx(
        classes.container,
        isMobile ? classes.containerMobile : undefined,
        className,
    );

    return (
        <Box className={containerClassName} id="buy-subscription-banner">
            {!hasDiscount ? (
                <>
                    <Box className={classes.firstLine}>
                        <Box className={classes.icon}>
                            <Icons8SandClock/>
                        </Box>

                        <Typography
                            className={classes.title}>
                            {tt('buySubscriptionBanner.title.trial').replace('{days}', daysLeft)}
                        </Typography>
                    </Box>

                    <Typography
                        className={classes.link}
                        onClick={() => {
                            trackDataLayerEvent('purchase_btn_click');

                            navigate(kSimpleShopRoute);
                        }}
                    >
                        {tt('buySubscriptionBanner.buy')}
                    </Typography>
                </>
            ) : (
                <>
                    <Box className={classes.firstLine}>
                        <Box className={classes.icon}>
                            <Icons8Gift/>
                        </Box>

                        <Typography
                            className={classes.title}>
                            {tt('buySubscriptionBanner.title.buyDiscounted')
                                .replace('{discount}', kDiscountPercent)
                                .replace('{months}', kDiscountMonths)
                                .replace('{days}', displayDaysLeftForDiscountString)
                                .replace('{minutes}', displayHoursLeftForDiscountString)
                                .replace('{seconds}', displayMinutesLeftForDiscountString)}
                        </Typography>
                    </Box>

                    <Typography
                        className={classes.link}
                        onClick={() => {
                            trackDataLayerEvent('30_off_5_days_stripe_btn_click');

                            navigate(`${kSimpleShopRoute}?discount=30_off_5`);
                        }}
                    >
                        {tt('buySubscriptionBanner.buyDiscounted')}
                    </Typography>
                </>
            )}
        </Box>
    );
}

/**
 * Dummy component to create space in main content for this banner.
 */
export function BuySubscriptionBannerDummy() {
    const appDataContext = useContext(AppDataContext);
    const {companyData} = appDataContext;

    const displayBanner = companyData.subscriptionIsTrial;

    const {classes} = useStyles();

    if (!displayBanner) {
        return null;
    }

    return <Box className={classes.contentDummy}/>;
}
