import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import IFileState, {IFileStateType} from "../model/FileState";
import {ErrorToast} from "./ToastService";
import {tt} from "../core/Localization";
import {CreateFileInput, FileResponse} from "../generated/graphql/graphql";
import {kIsLocal} from "../flavor-config";
import {getFileExtension} from "../utils/Utils";
import {Dispatch, SetStateAction} from "react";
import "jimp";
import INotification from "../model/firestore/Notification";
import { ICombinedFile } from "../model/CombinedFile";
import { Buffer } from 'buffer';

declare global {
    interface Window {
        Jimp: any;
    }
}

export const kStorageCategoryJobDocuments = 'job_documents';
export const kStorageCategoryJobProtocols = 'job_protocols';
export const kStorageCategoryCompanyLogo = 'company_logo';
export const kStorageCategoryUserPhoto = 'user_photo';
export const kStorageCategoryUserSignature = 'user_signature';
export const kStorageCategoryOutputDocument = 'output_document';

const kMaxImageSize = 2560;
const kImageQuality = 60;

export interface IStoreFileToFirebaseParams {
    fileState: IFileState;
    onUpdateFileState: (updateFileState: (fileState: IFileState) => IFileState) => void;
    onDelete: (uuid: string) => void;
    onComplete: (input: CreateFileInput) => void;
}

/**
 * Upload file to Firebase Storage.
 * With callback for progress.
 */
export async function storeFileToFirebase(
    params: IStoreFileToFirebaseParams,
) {
    const {fileState, onUpdateFileState, onDelete, onComplete} = params;

    const file = fileState.inputFile;

    if (file) {
        const storage = getStorage();

        let fileName = fileState.uuid + '.' + getFileExtension(file.name);

        if (fileState.category) {
            fileName = fileState.category + '/' + fileName;
        }

        if (fileState.companyId) {
            fileName = `company_${fileState.companyId}` + '/' + fileName;
        }

        if (kIsLocal) {
            fileName = 'local/' + fileName;
        }

        let resizedImage: Buffer | undefined;

        if (isImageFile(fileName)) {
            try {
                const buffer = Buffer.from(fileState.overrideFileUpdateData ? await blobToArrayBuffer(fileState.overrideFileUpdateData) : await file.arrayBuffer());

                resizedImage = await resizeEncodeJPG(buffer);
            } catch (e) {
                console.error(e);
            }
        }

        const fileRef = ref(storage, fileName);

        const uploadTask = uploadBytesResumable(
            fileRef,
            resizedImage || fileState.overrideFileUpdateData || file,
        );

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                onUpdateFileState((fileState) => ({
                    ...fileState,
                    type: IFileStateType.Upload,
                    progress,
                    totalBytes: snapshot.totalBytes,
                }));
            },
            (error) => {
                console.error(error);
                ErrorToast(tt('common.upload.error'));

                onDelete(fileState.uuid);
            },
            () => {
                onComplete({
                    category: fileState.category,
                    companyId: fileState.companyId,
                    name: file.name,
                    path: fileName,
                    uuid: fileState.uuid,
                });
            },
        );
    } else {
        onDelete(fileState.uuid);
    }
}

/**
 * Convert Blob to ArrayBuffer.
 * @param blob
 */
export function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
    });
}

/**
 * Resize and reEncode image to JPG.
 */
export async function resizeEncodeJPG(data: Buffer): Promise<Buffer> {
    const image = await window.Jimp.read(data);

    if (image.getWidth() > kMaxImageSize) {
        image.resize(kMaxImageSize, window.Jimp.AUTO);
    } else if (image.getHeight() > kMaxImageSize) {
        image.resize(window.Jimp.AUTO, kMaxImageSize);
    }

    image.quality(kImageQuality);

    return await image.getBufferAsync(window.Jimp.MIME_JPEG);
}

/**
 * Get public urls as list of ICombinedFiles.
 */
export async function getPublicUrlsCombined(data: FileResponse[]): Promise<ICombinedFile[]> {
    const storage = getStorage();

    const files: ICombinedFile[] = [];

    try {
        for (const file of data) {
            const fileRef = ref(storage, file.path);

            if (!files.includes(file.id)) {
                files.push({
                    file,
                    publicUrl: await getDownloadURL(fileRef),
                });
            }
        }
    } catch (e) {
        console.error(e);
        ErrorToast(tt('common.storage.getDownloadURL.error'));
    }

    return files;
}

/**
 * Get public urls in object for fileStates.
 * Mapped for uuid.
 */
export async function getPublicUrls(data: FileResponse[]): Promise<Record<string, string>> {
    const storage = getStorage();

    const urls: Record<string, string> = {};

    try {
        for (const file of data) {
            if (Object.keys(urls).includes(file.uuid)) {
                continue;
            }

            const fileRef = ref(storage, file.path);

            urls[file.uuid] = await getDownloadURL(fileRef);
        }
    } catch (e) {
        console.error(e);
        ErrorToast(tt('common.storage.getDownloadURL.error'));
    }

    return urls;
}

/**
 * Get public urls in object for notifications.
 * Mapped for uuid.
 */
export async function getPublicUrlsOfNotifications(data: INotification[]): Promise<Record<string, string>> {
    const storage = getStorage();

    const urls: Record<string, string> = {};

    const theData = data.filter((notification) => notification.firebaseFileUuid && notification.firebaseFilePath);

    try {
        for (const notification of theData) {
            if (Object.keys(urls).includes(notification.firebaseFileUuid!)) {
                continue;
            }

            const fileRef = ref(storage, notification.firebaseFilePath!);

            urls[notification.firebaseFileUuid!] = await getDownloadURL(fileRef);
        }
    } catch (e) {
        console.error(e);
        ErrorToast(tt('common.storage.getDownloadURL.error'));
    }

    return urls;
}

/**
 * Get public urls in object for fileStates.
 * Mapped for id.
 */
export async function getPublicUrlsById(data: FileResponse[]): Promise<Record<number, string>> {
    const storage = getStorage();

    const urls: Record<number, string> = {};

    try {
        for (const file of data) {
            const fileRef = ref(storage, file.path);

            urls[file.id] = await getDownloadURL(fileRef);
        }
    } catch (e) {
        console.error(e);
        ErrorToast(tt('common.storage.getDownloadURL.error'));
    }

    return urls;
}

/**
 * Check and request camera permission.
 */
export function checkAndRequestCameraPermission(setCanUseCamera: Dispatch<SetStateAction<boolean>>) {
    // @ts-ignore
    navigator.permissions.query({name: "camera"})
        .then(res => {
            setCanUseCamera(res.state === "granted");

            if (res.state === "prompt") {
                navigator.mediaDevices.getUserMedia({video: true})
                    .then(() => {
                        setCanUseCamera(true);
                    })
                    .catch(() => {
                        setCanUseCamera(false);
                    });
            }
        })
        .catch(err => console.error(err));
}

/**
 * Based on file path determine if File is image.
 */
export function isImageFile(path: string) {
    const ext = getFileExtension(path).toLowerCase();

    return ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif' || ext === 'webp';
}

export function isPDFFile(path: string) {
    const ext = getFileExtension(path).toLowerCase();

    return ext === 'pdf';
}
