import React, {useContext, useId} from "react";
import {MenuItem, Theme} from "@mui/material";
import {usePopupState} from "material-ui-popup-state/hooks";
import AppListItem from "../listItems/AppListItem";
import AppIconButton from "../buttons/AppIconButton";
import {tt} from "../../../core/Localization";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import CloseIcon from "../../../icons/CloseIcon";
import Icons8Done from "../../../icons/Icons8Done";
import {DateTime} from "luxon";
import {AppDataContext} from "../../../AppData";
import INotification, {
    kNotificationEventsForEmployeeTab,
    kNotificationEventsForFormsTab, kNotificationEventsForOutputDocumentsTab,
    kNotificationEventsForProtocolsTab,
    NotificationEvent
} from "../../../model/firestore/Notification";
import {notificationEventToString, saveNotification} from "../../../service/NotificationService";
import {processQueryError} from "../../../service/ErrorService";
import {useNavigate} from "react-router-dom";
import {routeWithCurrentAsParam} from "../../../utils/Utils";
import {workerDetailRoute} from "../../screens/workers/WorkerDetailScreen";
import {getVisitStatusIcon} from "../../../service/VisitService";
import {GetVisitInput, VisitResponse, VisitStatus} from "../../../generated/graphql/graphql";
import {SetVisitDetailModal} from "../modals/AppModals";
import {isJobDetailScreen} from "../../screens/jobs/JobDetailScreen";
import {hasPermission} from "../permissions/PermissionValid";
import {
    kActionView,
    kPermissionsForms, kPermissionsJobs,
    kPermissionsMaterials,
    kPermissionsProducts, kPermissionsVisitOutputDocuments,
    kPermissionsVisitProtocols,
    kPermissionsWorkers
} from "../../../core/constants";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {newJobAndVisitRoute, NewJobAndVisitRouteKind} from "../../screens/jobs/NewJobAndVisitScreen";
import {UserPhotoUrlOfNotification} from "../../../service/UserService";

const useStyles = makeStyles()((theme: Theme) => ({
    listItemStyle: {
        borderRadius: 0 + ' !important',
        minWidth: 300,
        paddingLeft: 16 + 'px !important',
        paddingRight: 24 + 'px !important',
    },
    showUnread: {
        '.MuiListItemText-root .MuiTypography-body1': {
            fontWeight: 600,
        },
        position: "relative",
        '&:after': {
            borderRadius: 8,
            content: '""',
            display: "block",
            width: 8,
            height: 8,
            background: kAppColors.red.confirmButton,
            position: "absolute",
            right: 8,
            top: '50%',
            transform: 'translateY(-50%)',
        },
        '.MuiTypography-body2': {
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
        }
    },
    read: {
        '.MuiTypography-body1': {
            color: kAppColors.grey.link
        }
    }
}));

export interface INotificationListItemProps {
    extraPaddingRight?: boolean;
    notification: INotification;
    closeMenu?: VoidFunction;
    isTooltip: boolean;
}

export default function NotificationListItem(props: INotificationListItemProps) {
    const {
        notification,
        closeMenu,
        isTooltip,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const {classes, cx} = useStyles();
    const navigate = useNavigate();

    const appDataContext = useContext(AppDataContext);
    const {language, employeePermissionsMap, storage} = appDataContext;

    const theListItemClass = cx(
        classes.listItemStyle,
        !notification.read ? classes.showUnread : classes.read,
    );

    /**
     * Save the notification as read or unread.
     */
    const onReadToggle = async (read: boolean) => {
        notification.read = read;

        try {
            await saveNotification(notification);
        } catch (error) {
            processQueryError(appDataContext, error);
        }
    };

    /**
     * Action on notification.
     */
    const onNotificationAction = async () => {
        const {event, read, visitId, repeatingDay, sourceEmployeeId} = notification;

        if (!notification.read) {
            await onReadToggle(true);
        }

        if (visitId) {
            if (hasPermission(kPermissionsJobs, [kActionView], employeePermissionsMap)
                && event !== NotificationEvent.DeleteVisit) {
                let preselectSelectedTab: string | undefined = undefined;
                let theHasPermission = true;

                if (kNotificationEventsForEmployeeTab.includes(event)) {
                    preselectSelectedTab = 'workers';
                    theHasPermission = hasPermission(kPermissionsWorkers, [kActionView], employeePermissionsMap);
                } else if (kNotificationEventsForFormsTab.includes(event)) {
                    preselectSelectedTab = 'jobForm';
                    theHasPermission = hasPermission(kPermissionsForms, [kActionView], employeePermissionsMap);
                } else if (kNotificationEventsForProtocolsTab.includes(event)) {
                    preselectSelectedTab = 'protocol';
                    theHasPermission = hasPermission(kPermissionsVisitProtocols, [kActionView], employeePermissionsMap);
                } else if (kNotificationEventsForOutputDocumentsTab.includes(event)) {
                    preselectSelectedTab = 'outputDocuments';
                    theHasPermission = hasPermission(kPermissionsVisitOutputDocuments, [kActionView], employeePermissionsMap);
                } else if (event === NotificationEvent.AddMaterialToVisit) {
                    preselectSelectedTab = 'materials';
                    theHasPermission = hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap);
                } else if (event === NotificationEvent.AddProductToVisit) {
                    preselectSelectedTab = 'products';
                    theHasPermission = hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap);
                }

                if (theHasPermission) {
                    closeMenu?.();

                    restApiGet({
                        uri: '/job/visit',
                        params: {
                            visitId: visitId,
                            repeatingDay: repeatingDay,
                        } as GetVisitInput,
                        fetchPolicy: FetchPolicy.NetworkOnly,
                        onData: (data: VisitResponse) => {
                            if (data) {
                                if (data.status === VisitStatus.ScheduleLater) {
                                    navigate(routeWithCurrentAsParam(
                                        newJobAndVisitRoute({
                                            jobId: data.jobId,
                                            visitId: data.id,
                                            kind: NewJobAndVisitRouteKind.fromJobDetail,
                                        })
                                    ));
                                } else {
                                    const canNavigateToJob = !!notification.jobId && !isJobDetailScreen(notification.jobId);

                                    SetVisitDetailModal(appDataContext, {
                                        open: true,
                                        visitId: visitId,
                                        repeatingDay: repeatingDay,
                                        canNavigateToJob,
                                        preselectSelectedTab,
                                    });
                                }
                            }
                        },
                        onError: (error) => {
                            processQueryError(appDataContext, error);
                        },
                    });
                }
            }
        } else {
            switch (event) {
                case NotificationEvent.LayoffEmployeeBySelf:
                case NotificationEvent.ConsumeInvite:
                    if (sourceEmployeeId && hasPermission(kPermissionsWorkers, [kActionView], employeePermissionsMap)) {
                        if (!isTooltip) {
                            navigate(
                                routeWithCurrentAsParam(workerDetailRoute(sourceEmployeeId)),
                            );
                        } else {
                            navigate(workerDetailRoute(sourceEmployeeId));
                        }
                    }

                    break;
            }
        }
    };

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const createdAt = DateTime.fromMillis(notification.createdAt);

    let description = createdAt.diffNow().toMillis() > -1000 ? tt('notificationListItem.date.now') : createdAt.toRelative({locale: language});
    const notificationEvent = notificationEventToString(notification);
    if (notificationEvent) {
        description = `${notificationEvent}\n${description}`;
    }
    if (notification.visitName) {
        if (notification.jobName) {
            description = `${notification.jobName} · ${notification.visitName}\n${description}`;
        } else {
            description = `${notification.visitName}\n${description}`;
        }
    } else if (notification.jobName) {
        description = `${notification.jobName}\n${description}`;
    }

    if (notification.targetDeleted) {
        description = `${tt('notificationListItem.targetDeleted')}\n${description}`;
    }

    const customAvatarInCircle = notification.event === NotificationEvent.JobOfferSeatNotFilledNotification
        ? getVisitStatusIcon(VisitStatus.JobOffer)
        : undefined;

    return (
        <>
            <AppListItem
                className={theListItemClass}
                profileImage={
                    UserPhotoUrlOfNotification(notification, storage.publicUrlsForFiles)
                }
                customAvatarInCircle={customAvatarInCircle}
                title={notification.title}
                description={description}
                variant={"smaller-title"}
                onClick={notification.targetDeleted ? undefined : onNotificationAction}
                actionWidget={
                    <>
                        <AppIconButton
                            variant={"greyBg"}
                            placement={"right"}
                            tooltip={tt('common.more')}
                            {...bindTrigger(settingsPopupState)}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>
                    </>
                }
            />
            <Menu {...bindMenu(settingsPopupState)}>

                {!notification.read ? (
                    <MenuItem key={'notificationMenuMarkAsRead' + notification.id
                    } onClick={(e) => {
                        e.stopPropagation();

                        settingsPopupState.close();

                        onReadToggle(true);
                    }}>
                        <Icons8Done/>
                        {tt('notification.menu.markAsRead')}
                    </MenuItem>
                ) : <MenuItem key={'notificationMenuMarkAsUnread' + notification.id
                } onClick={(e) => {
                    e.stopPropagation();

                    settingsPopupState.close();

                    onReadToggle(false);
                }}>
                    <CloseIcon/>
                    {tt('notification.menu.markAsUnread')}
                </MenuItem>}
            </Menu>
        </>
    );
}
