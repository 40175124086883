import {GetVisitOutputDocumentsInput, JobUpdateRepeats, OutputDocumentResponsePage} from "../generated/graphql/graphql";
import {IRestApiClientContext, IRestApiSubscription} from "../core/RestApiProvider";
import {IAppDataContext} from "../AppData";
import {kActionUpdate, kTopicVisits} from "../core/constants";
import {processQueryError} from "./ErrorService";
import IEventSystemNotification from "../model/firestore/EventSystemNotification";

/**
 * Using restApi subscribe to OutputDocumentResponsePage for Visit.
 */
export function subscribeToOutputDocumentResponsePageVisit(
    restApiClientContext: IRestApiClientContext,
    appDataContext: IAppDataContext,
    params: {
        input: GetVisitOutputDocumentsInput;
        setLoading?: (loading: boolean) => void;
        onData: (data: OutputDocumentResponsePage | NullOrUndefined) => void;
    },
): IRestApiSubscription {
    const {subscribe} = restApiClientContext;
    const {input, setLoading, onData} = params;

    return subscribe(
        kTopicVisits,
        {
            uri: '/output-document/visit-search',
            params: input,
            setLoading,
            onData,
            onError: (error) => {
                processQueryError(appDataContext, error);
            },
        },
        (notifications: IEventSystemNotification[]) => {
            return notifications.some((notification) => {
                if (notification.action !== kActionUpdate) {
                    return false;
                }

                const dataId = notification.data['id'];
                const dataRepeatingDay = notification.data['repeatingDay'];
                const theRepeats = notification.data['repeats'];

                return dataId === input.visitId && (dataRepeatingDay === input.repeatingDay || (!dataRepeatingDay && !input.repeatingDay)) ||
                    theRepeats === JobUpdateRepeats.ThisAndAllFuture;
            });
        },
    );
}
