import React, {Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from "react";
import ModalBottomSheet from "../../../ModalBottomSheet";
import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import {FetchPolicy, RestApiClientContext} from "../../../../../core/RestApiProvider";
import {AppDataContext} from "../../../../../AppData";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import {tt} from "../../../../../core/Localization";
import AppButton from "../../../buttons/AppButton";
import {
    AddOutputDocumentToVisitInput,
    GetOutputDocumentTemplatesInput,
    OutputDocumentResponse,
    OutputDocumentResponsePage
} from "../../../../../generated/graphql/graphql";
import {processMutationError, processQueryError} from "../../../../../service/ErrorService";
import ListShimmer from "../../../shimmers/ListShimmer";
import {kListMaxDisplayItemsLimit} from "../../../../../core/constants";
import AppListItem from "../../../listItems/AppListItem";
import Icons8Document from "../../../../../icons/Icons8Document";
import {SuccessToast} from "../../../../../service/ToastService";
import {CheckCircle} from "@mui/icons-material";
import BannerTextAndButton from "../../../banners/BannerTextAndButton";
import TextModal from "../../TextModal";
import {FlavorByEnvironment} from "../../../../../flavor-config";
import {trackDataLayerEvent} from "../../../../../service/AnalyticsService";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: '100vh',
        overflow: "auto",
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
}));

export interface ICreateVisitOutputDocumentModalBottomSheetProps {
    visitId: number;
    repeatingDay?: number;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modalAboveModals?: boolean;
}

/**
 * Modal bottom sheet component to choose OutputDocument template to add to Visit.
 */
export default function CreateVisitOutputDocumentModalBottomSheet(props: ICreateVisitOutputDocumentModalBottomSheetProps) {
    const {visitId, repeatingDay, open, setOpen, modalAboveModals} = props;

    const {classes} = useStyles();

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet, restApiPost} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, language} = appDataContext;

    const [selectedItem, setSelectedItem] = useState<OutputDocumentResponse>();
    const [buyCustomModal, setBuyCustomModal] = useState(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<OutputDocumentResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (open) {
            restApiGet({
                uri: '/output-document/template/search',
                params: {
                    companyId: companyId!,
                } as GetOutputDocumentTemplatesInput,
                setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, companyId]);

    const [submitLoading, setSubmitLoading] = useState(false);
    /**
     * Create Output Document to Visit to BE.
     */
    const createOutputDocument = () => {
        restApiPost({
            uri: '/output-document/add-to-visit',
            params: {
                visitId: visitId,
                repeatingDay: repeatingDay,
                templateId: selectedItem!.id,
                language: language,
            } as AddOutputDocumentToVisitInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setSubmitLoading,
            onData: (data) => {
                if (data) {
                    SuccessToast(tt('createVisitOutputDocument.modal.success'));
                }

                setOpen(false);
            },
            onError: (error) => processMutationError(error),
        });
    };

    const [itemsJSX, setItemsJSX] = useState<ReactNode[]>();
    useEffect(() => {
        if (data) {
            let newOutputDocuments = [...data.content];

            newOutputDocuments.sort((a, b) => b.createdAt - a.createdAt);

            newOutputDocuments = newOutputDocuments.slice(0, kListMaxDisplayItemsLimit);

            setItemsJSX(newOutputDocuments.map((outputDocument) => {
                return (
                    <AppListItem
                        key={outputDocument.id}
                        variant={"smaller-title"}
                        customAvatarInCircle={
                            <Icons8Document/>
                        }
                        title={tt(outputDocument.translationKey)}
                        actionWidget={
                            selectedItem?.id === outputDocument.id ? <CheckCircle color={"primary"}/> : <></>
                        }
                        onClick={() => {
                            setSelectedItem(outputDocument);
                        }}
                    />
                );
            }));
        } else {
            setItemsJSX(undefined);
        }
    }, [data, language, selectedItem]);

    const config = FlavorByEnvironment()!;

    return (
        <>
            <ModalBottomSheet
                tallOnMobile={true}
                blurBackdrop={true}
                modalAboveModals={modalAboveModals}
                open={open}
                setOpen={setOpen}
                hideHeader={true}
            >
                <Box className={classes.container}>
                    <BottomSheetModalAppbar
                        onClose={() => setOpen(false)}
                        title={tt('createVisitOutputDocument.modal.title')}
                        bottomContent={
                            <Box sx={{ml: -2, mr: -2}}>
                                <BannerTextAndButton
                                    variant={'trial'}
                                    text={tt('createVisitOutputDocument.modal.buyCustom')}
                                    buttonText={tt('createVisitOutputDocument.modal.buyCustom.purchase')}
                                    onTap={() => {
                                        trackDataLayerEvent('new_output_document_btn_click');

                                        setBuyCustomModal(true);
                                    }}
                                />
                            </Box>
                        }
                    />

                    {loading && !data ? (
                        <ListShimmer items={2}/>
                    ) : itemsJSX}

                    <Box sx={{pb: 10}}/>

                    <Box className={classes.buttonContainer}>
                        <AppButton
                            variant={"contained"}
                            disabled={loading || !selectedItem}
                            isLoading={submitLoading}
                            fullWidth={true}
                            onClick={() => {
                                createOutputDocument();
                            }}>
                            {tt('common.save.create')}
                        </AppButton>
                    </Box>
                </Box>
            </ModalBottomSheet>

            <TextModal
                open={buyCustomModal}
                setOpen={setBuyCustomModal}
                title={tt('createVisitOutputDocument.modal.buyCustom.title')}
                text={tt('createVisitOutputDocument.modal.buyCustom.text')}
                buttonText={tt('createVisitOutputDocument.modal.buyCustom.email').replace('{email}', config.customOutputDocumentEmail)}
                onClick={() => {
                    trackDataLayerEvent('new_output_document_btn_click_email');

                    const subject = tt('createVisitOutputDocument.modal.buyCustom.subject');
                    const mailToLink = `mailto:${config.customOutputDocumentEmail}?subject=${subject}`;

                    window.open(mailToLink, '_blank');

                    setBuyCustomModal(false);
                }}
                modalAboveModals={true}
            />
        </>
    );
}
