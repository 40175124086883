import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8SandClockProps {
    className?: string;
}

export default function Icons8Gift(props: IIcons8SandClockProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px">
                <path d="M 19 3 C 15.428062 3 12.5 5.9280619 12.5 9.5 C 12.5 10.815038 12.994068 11.973486 13.673828 13 L 8.5 13 C 7.1364058 13 6 14.136406 6 15.5 L 6 22.5 C 6 23.670167 6.9008442 24.530047 8 24.791016 L 8 37.5 C 8 41.071938 10.928062 44 14.5 44 L 33.5 44 C 37.071938 44 40 41.071938 40 37.5 L 40 24.791016 C 41.099156 24.530047 42 23.670167 42 22.5 L 42 15.5 C 42 14.136406 40.863594 13 39.5 13 L 34.326172 13 C 35.005932 11.973486 35.5 10.815038 35.5 9.5 C 35.5 5.9280619 32.571938 3 29 3 C 26.951606 3 25.194111 4.0224818 24 5.5175781 C 22.805889 4.0224818 21.048394 3 19 3 z M 19 6 C 20.950062 6 22.5 7.5499381 22.5 9.5 L 22.5 13 L 19 13 C 17.049938 13 15.5 11.450062 15.5 9.5 C 15.5 7.5499381 17.049938 6 19 6 z M 29 6 C 30.950062 6 32.5 7.5499381 32.5 9.5 C 32.5 11.450062 30.950062 13 29 13 L 25.5 13 L 25.5 9.5 C 25.5 7.5499381 27.049938 6 29 6 z M 9 16 L 19 16 L 22.5 16 L 22.5 22 L 9 22 L 9 16 z M 25.5 16 L 29 16 L 39 16 L 39 22 L 25.5 22 L 25.5 16 z M 11 25 L 22.5 25 L 22.5 41 L 14.5 41 C 12.549938 41 11 39.450062 11 37.5 L 11 25 z M 25.5 25 L 37 25 L 37 37.5 C 37 39.450062 35.450062 41 33.5 41 L 25.5 41 L 25.5 25 z"/>
            </svg>
        </span>
    );
}
