import ModalBottomSheet from "../../../ModalBottomSheet";
import {Box, InputAdornment, Theme, Tooltip} from "@mui/material";
import {tt} from "../../../../../core/Localization";
import AppListItem from "../../../listItems/AppListItem";
import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {makeStyles} from "tss-react/mui";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import SearchIcon from "../../../../../icons/SearchIcon";
import CreateClientModal from "../../../clients/CreateClientModal";
import {AppDataContext} from "../../../../../AppData";
import AppButton from "../../../buttons/AppButton";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import {AddOutlined, CheckCircle} from "@mui/icons-material";
import {ClientPureResponsePage, GetClientsInput} from "../../../../../generated/graphql/graphql";
import Icons8Company from "../../../../../icons/Icons8Company";
import UserIcon from "../../../../../icons/UserIcon";
import ListShimmer from "../../../shimmers/ListShimmer";
import EmptyListText from "../../../textComponents/EmptyListText";
import {processQueryError} from "../../../../../service/ErrorService";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";
import PeopleIcon from "../../../../../icons/PeopleIcon";
import PermissionValid from "../../../permissions/PermissionValid";
import {
    kActionCreate,
    kPermissionsClients,
    kUserPreferencesSelectClientListContactInfo
} from "../../../../../core/constants";
import AppChip from "../../../chips/AppChip";
import PhoneIcon from "../../../../../icons/PhoneIcon";
import Icons8EnvelopeOutlined from "../../../../../icons/Icons8EnvelopeOutlined";
import Icons8Location from "../../../../../icons/Icons8Location";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import { locationsPureForClient } from "../../../../../service/LocationService";
import {addressToSingleLine} from "../../../../../utils/AddressUtils";
import {displayClientTaxInfoAsText} from "../../../../../service/ClientService";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: '100vh',
        overflow: "auto",
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    singleChipContainer: {
        marginBottom: 4,
        marginRight: 4,
    },
    locationsCount: {
        borderRadius: 200,
        border: `1px solid ${kAppColors.border(theme.palette.mode === 'dark')}`,
        minWidth: 20,
        height: 20,
        textAlign: "center",
        fontWeight: 400,
        fontSize: 10,
        marginLeft: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    chipInner: {
        display: "flex",
        alignItems: "center",
    }
}));

export interface IChooseClientModalBottomsheeetProps {
    open: boolean;
    showAllClientsOption?: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onSave: (id: number) => void;
    existingChosenClientId?: number;
    validClientIds?: number[];
}

export default function ChooseClientModalBottomSheet(props: IChooseClientModalBottomsheeetProps) {
    const {
        open,
        setOpen,
        onSave,
        existingChosenClientId,
        showAllClientsOption,
        validClientIds,
    } = props;

    const {classes} = useStyles();

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {userPreferences} = appDataContext;

    const [chosenClientId, setChosenClientId] = useState<number>();
    const [createModal, setCreateModal] = useState<boolean>(false);
    const {companyId, clientsFullTextSearch, setClientsFullTextSearch} = appDataContext;

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            placeholder: tt('clients.screen.search.placeholder.searchClient'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: clientsFullTextSearch || '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });

    const selectClientListContactInfo = userPreferences.find(item => item.key === kUserPreferencesSelectClientListContactInfo);

    const [optionsInputs, setOptionsInputs] = useState<IInputsData>({
        contactInfo: {
            type: InputType.Switch,
            switchVariant: "Android12Switch",
            label: tt('clients.modal.switch.contactInfo'),
            value: selectClientListContactInfo ? selectClientListContactInfo.valueBool : false,
        },
    });

    const contactInfo = optionsInputs.contactInfo.value;

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<ClientPureResponsePage | undefined>(undefined);
    useEffect(() => {
        if (open && !createModal) {
            restApiGet({
                uri: '/client/search-pure',
                params: {
                    companyId: companyId!,
                    search: inputs.search.value.trim(),
                    includeLocations: contactInfo,
                } as GetClientsInput,
                setLoading: setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, inputs.search.value, createModal, contactInfo]);

    useEffect(() => {
        if (open) {
            const selectClientListContactInfo = userPreferences.find(item => item.key === kUserPreferencesSelectClientListContactInfo);

            if (selectClientListContactInfo) {
                setOptionsInputs({
                    ...optionsInputs,
                    contactInfo: {
                        ...optionsInputs.contactInfo,
                        value: selectClientListContactInfo ? selectClientListContactInfo.valueBool : false,
                    }
                });
            }
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            setInputs(prev => {
                    return {
                        ...prev,
                        search: {
                            ...prev.search,
                            value: '',
                        }
                    };
                }
            );
            setChosenClientId(existingChosenClientId);
        }
    }, [open]);

    useEffect(() => {
        if (inputs.search.value !== clientsFullTextSearch) {
            setClientsFullTextSearch(inputs.search.value);
        }
    }, [inputs]);

    const clientsJSX = useMemo(() => {
        return [...(data?.content || [])]
            .filter((client) => {
                return validClientIds ? validClientIds.includes(client.id) : true;
            })
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(client => {
                const locations = locationsPureForClient(client.id, data?.locations || []);

                return (
                    <AppListItem
                        key={client.id}
                        customAvatarInCircle={client.company ? <Icons8Company /> : <UserIcon />}
                        description={displayClientTaxInfoAsText({client, ignoreTaxId: true})}
                        title={client.name}
                        variant={"smaller-title"}
                        onClick={() => {
                            setChosenClientId(client.id);
                        }
                        }
                        actionWidget={
                            chosenClientId === client.id ? <CheckCircle color={"primary"} /> : <></>
                        }
                        customBottomWidget={contactInfo ? (
                            <Box className={classes.chipsContainer}>
                                {client.phoneNumber ? (
                                    <Box className={classes.singleChipContainer}>
                                        <Tooltip
                                            title={tt('clientList.client.phone')}
                                        >
                                            <Box>
                                                <AppChip
                                                    chipstyle={"outlined"}
                                                    label={client.phoneNumber}
                                                    icon={<PhoneIcon />} />
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                ) : null}

                                {client.email ? (
                                    <Box className={classes.singleChipContainer}>
                                        <Tooltip
                                            title={tt('clientList.client.email')}
                                        >
                                            <Box>
                                                <AppChip
                                                    chipstyle={"outlined"}
                                                    label={client.email}
                                                    icon={<Icons8EnvelopeOutlined />} />
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                ) : null}

                                {locations.length > 0 ? (
                                    <Box className={classes.singleChipContainer}>
                                        <Tooltip
                                            title={tt('clientList.client.locations')}
                                        >
                                            <Box>
                                                <AppChip
                                                    chipstyle={"outlined"}
                                                    label={
                                                        <Box className={classes.chipInner}>
                                                            {addressToSingleLine(locations[0]!.address)} {locations.length > 1 ? <span className={classes.locationsCount}>+{locations.length - 1}</span> : null}
                                                        </Box>
                                                    }
                                                    icon={<Icons8Location />}
                                                />
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                ) : null}
                            </Box>
                        ) : null}
                    />
                );
            });
    }, [data, chosenClientId, contactInfo, validClientIds]);

    return (
        <ModalBottomSheet
            tallOnMobile={true}
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}>
            <Box className={classes.container}>
                <BottomSheetModalAppbar
                    title={showAllClientsOption ? tt('common.client') : null}
                    onClose={() => setOpen(false)}
                    bottomContent={
                        <>
                            <FormBuilder inputs={inputs} setInputs={setInputs}/>
                            <FormBuilder inputs={optionsInputs} setInputs={setOptionsInputs}/>
                        </>
                    }
                >
                    {showAllClientsOption ? null : (
                        <PermissionValid
                            permission={kPermissionsClients}
                            requiredPermissions={[kActionCreate]}
                        >
                            <AppButton
                                onClick={() => {
                                    setCreateModal(true);
                                }}
                                variant={"textThin"}
                            >
                                <AddOutlined sx={{mr: 0.25}}/>
                                {tt('common.newClient')}
                            </AppButton>
                        </PermissionValid>
                    )}
                </BottomSheetModalAppbar>

                {loading && !data ?
                    <ListShimmer items={3} showIcon={false}/>
                    : (!clientsJSX || clientsJSX.length === 0 ?
                            <EmptyListText text={tt('clientList.emptyListMessage')}/>
                            :
                            <>
                                {
                                    showAllClientsOption ?
                                        <AppListItem
                                            customAvatarInCircle={<PeopleIcon/>}
                                            title={tt('common.allClients')}
                                            variant={"smaller-title"}
                                            onClick={() => {
                                                setChosenClientId(undefined);
                                            }
                                            }
                                            actionWidget={
                                                chosenClientId ? <></> : <CheckCircle color={"primary"}/>
                                            }
                                        /> : null
                                }
                                {clientsJSX}
                            </>
                    )}

                <Box sx={{pb: 10}}/>

                <Box className={classes.buttonContainer}>
                    <AppButton
                        variant={"contained"}
                        disabled={chosenClientId === null}
                        fullWidth={true}
                        onClick={() => {
                            onSave(chosenClientId!);

                            setOpen(false);
                        }}>
                        {tt('common.save')}
                    </AppButton>
                </Box>

                <CreateClientModal
                    open={createModal}
                    setOpen={setCreateModal}
                    navigation={false}
                    onCreate={(id: number) => {
                        setTimeout(() => {
                            onSave(id);

                            setOpen(false);
                        }, 400);
                    }}
                />
            </Box>
        </ModalBottomSheet>
    );
}
