import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../../App";
import ResponsiveContainer from "../../../components/screens/ResponsiveContainer";
import ScreenContent from "../../../components/screens/ScreenContent";
import {tt} from "../../../../core/Localization";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {Box, Tabs, Theme} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import {makeStyles} from "tss-react/mui";
import VisitsCardsTab from "../../../components/jobs/visits/visitsScreenTabs/VisitsCardsTab";
import VisitsCalendar, {JobsCalendarVariant} from "../../../components/jobs/visits/VisitsCalendar";
import {useSearchParams} from "react-router-dom";
import CalendarIcon from "../../../../icons/CalendarIcon";
import TimelineIcon from "../../../../icons/TimelineIcon";
import Grid3Icon from "../../../../icons/Grid3Icon";
import CardIcon from "../../../../icons/CardIcon";
import {TooltipTab} from "../../../components/tabs/TooltipTab";
import {AppDataContext} from "../../../../AppData";
import {
    kUserPreferencesCalendarTab,
    kUserPreferencesCalendarTabValues,
    kUserPreferencesCalendarView
} from "../../../../core/constants";
import {BuySubscriptionBannerDummy} from "../../../components/banners/BuySubscriptionBanner";

export const kVisitsRoute = '/visits';

export const useStyles = makeStyles()((theme: Theme) => ({
    tabContainer: {
        position: "fixed",
        top: 0,
        zIndex: 1202,
        '.MuiTab-root': {
            height: 64,
        },
        "@media (max-width: 991px)": {
            left: '25%',
        },
        "@media (max-width: 768px)": {
            left: "unset",
            marginRight: 0,
            '.MuiTab-root': {
                height: "unset",
            },
            position: "relative",
            top: 0,
            zIndex: 100,
            backgroundColor: kAppColors.background.mobileAppbar(theme.palette.mode === "dark"),
            backdropFilter: `saturate(180%) blur(10px)`,
            transition: 'all 0.1s linear',
            width: '100%',
            display: "flex",
            justifyContent: "center",
            borderBottom: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`,
            marginBottom: 16,
        },
    },
    paper: {
        border: "none",
    },
    tabPanel: {
        width: '100%',
        padding: 0,
    },
    scrollPadding: {
        scrollPaddingTop: 59,
    }
}));

export default function VisitsScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    useEffect(() => {
        setTitle(tt('visits.screen.title'));
        document.body.classList.add('jobsScreen');

        return () => {
            document.body.classList.remove('jobsScreen');
        }
    }, []);

    return (
        <ResponsiveContainer
            smallPhoneScreen={<Body isMobile={true}/>}
            largePhoneScreen={<Body isMobile={true}/>}
            tabletScreen={<Body/>}
            smallDesktopScreen={<Body/>}
            largeDesktopScreen={<Body/>}
            extraLargeDesktopScreen={<Body/>}
        />
    );
}

interface IBodyProps {
    isMobile?: boolean;
}

function Body(props: IBodyProps) {
    const {isMobile} = props;

    const appDataContext = useContext(AppDataContext);
    const {userPreferences} = appDataContext;

    const [searchParams, setSearchParams] = useSearchParams();

    const {classes, cx} = useStyles();

    let defaultTab = searchParams.get('tab');
    const defaultCalendarTab = userPreferences.find(item => item.key === kUserPreferencesCalendarTab)?.valueString;
    const defaultCalendarView = userPreferences.find(item => item.key === kUserPreferencesCalendarView)?.valueString;

    if (!defaultTab && defaultCalendarTab) {
        switch (defaultCalendarTab) {
            case 'timeline':
                defaultTab = '0';
                break;
            case 'timeGrid':
                defaultTab = '1';
                break;
            case 'calendar':
                defaultTab = '2';
                break;
            case 'cards':
                defaultTab = '3';
                break;
        }
    }

    const [tabValue, setTabValue] = useState<string>(defaultTab || '0');

    useEffect(() => {
        if (!searchParams.get('tab')) {
            setSearchParams(prev => {
                prev.set('tab', tabValue);
                return prev;
            });
        }
    }, []);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);

        setSearchParams(prev => {
            prev.set('tab', newValue);
            return prev;
        });
    };

    return (
        <TabContext value={tabValue}>
            <ScreenContent
                className={classes.scrollPadding}
                appBar={!isMobile}
                noContentPadding={isMobile}
                navigationDrawer={!isMobile}
                bottomBar={isMobile}
                centerHorizontally={true}
            >
                <Box className={classes.tabContainer}>
                    {!isMobile ? <BuySubscriptionBannerDummy/> : null}

                    <Tabs
                        variant={isMobile ? 'scrollable' : 'standard'}
                        value={tabValue}
                        onChange={handleTabChange}
                        scrollButtons={"auto"}
                    >
                        <TooltipTab
                            key={0}
                            value={'0'}
                            title={tt('jobsScreen.tab.timeline')}
                            label={<TimelineIcon/>}
                            onChange={handleTabChange}
                            tabValue={tabValue}
                        />

                        <TooltipTab
                            key={1}
                            value={'1'}
                            title={tt('jobsScreen.tab.timeGrid')}
                            label={<Grid3Icon/>}
                            onChange={handleTabChange}
                            tabValue={tabValue}
                        />

                        <TooltipTab
                            key={2}
                            value={'2'}
                            title={tt('jobsScreen.tab.calendar')}
                            label={<CalendarIcon/>}
                            onChange={handleTabChange}
                            tabValue={tabValue}
                        />

                        <TooltipTab
                            key={3}
                            value={'3'}
                            title={tt('jobsScreen.tab.cards')}
                            label={<CardIcon/>}
                            onChange={handleTabChange}
                            tabValue={tabValue}
                        />
                    </Tabs>
                </Box>

                <TabPanel className={classes.tabPanel} value={'0'}>
                    <VisitsCalendar
                        variant={JobsCalendarVariant.Timeline}
                        defaultCalendarView={defaultCalendarTab === kUserPreferencesCalendarTabValues[0] ? defaultCalendarView : undefined}
                    />
                    <Box sx={{pb: 0.5}}/>
                </TabPanel>

                <TabPanel className={classes.tabPanel} value={'1'}>
                    <VisitsCalendar
                        variant={JobsCalendarVariant.TimeGrid}
                        defaultCalendarView={defaultCalendarTab === kUserPreferencesCalendarTabValues[1] ? defaultCalendarView : undefined}
                    />
                    <Box sx={{pb: 0.5}}/>
                </TabPanel>

                <TabPanel className={classes.tabPanel} value={'2'}>
                    <VisitsCalendar
                        variant={JobsCalendarVariant.Calendar}
                        defaultCalendarView={defaultCalendarTab === kUserPreferencesCalendarTabValues[2] ? defaultCalendarView : undefined}
                    />
                    <Box sx={{pb: 0.5}}/>
                </TabPanel>

                <TabPanel className={classes.tabPanel} value={'3'}>
                    <VisitsCardsTab isMobile={isMobile}/>
                </TabPanel>
            </ScreenContent>
        </TabContext>
    );
}
