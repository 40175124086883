import {
    FileResponse,
    JobUpdateRepeats,
    VisitDetailResponse,
    VisitStatus
} from "../../../../../generated/graphql/graphql";
import {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {IOnUpdateVisitIdParams} from "../../../modals/job/visits/VisitDetailModal";
import {tt} from "../../../../../core/Localization";
import Icons8Plus from "../../../../../icons/Icons8Plus";
import {kActionCreate, kActionView, kPermissionsMaterials, kPermissionsProducts} from "../../../../../core/constants";
import HeadlineWithButton from "../../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import {AppDataContext} from "../../../../../AppData";
import {
    combineProductMaterials,
    convertProductMaterial,
    displayProductMaterialListTitle
} from "../../../../../service/ProductMaterialService";
import {hasPermission, hasSomePermissions} from "../../../permissions/PermissionValid";
import ProductMaterialListItem from "../../../productMaterials/ProductMaterialListItem";
import IProductMaterial, {IProductMaterialByTemplate} from "../../../../../model/ProductMaterial";
import EditProductMaterialModalBottomSheet from "../../../modals/productMaterial/EditProductMaterialModalBottomSheet";
import AddProductMaterialModalBottomSheet from "../../../modals/productMaterial/AddProductMaterialModalBottomSheet";
import VisitProductMaterialsTotalSection from "../../newJob/VisitProductMaterialsTotalSection";

export interface IVisitDetailProductMaterialTabProps {
    canEdit: boolean;
    visitId: number;
    repeatingDay?: number;
    setRecurringConfirmActionCallback: (recurringConfirmActionCallback: ((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined) => void;
    setRecurringEditModal: Dispatch<SetStateAction<boolean>>;
    isRepeating?: boolean;
    repeats: JobUpdateRepeats;
    setRepeats: Dispatch<SetStateAction<JobUpdateRepeats>>;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    data?: VisitDetailResponse | NullOrUndefined;
    files?: FileResponse[] | NullOrUndefined;
}

/**
 * Component for combine Product/Material tab for Visit detail content.
 */
export default function VisitDetailProductMaterialTab(props: IVisitDetailProductMaterialTabProps) {
    const {
        canEdit,
        visitId,
        repeatingDay,
        setRecurringConfirmActionCallback,
        setRecurringEditModal,
        isRepeating,
        repeats,
        setRepeats,
        onUpdateVisitId,
        data,
        files,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap} = appDataContext;

    const [addProductMaterialModal, setAddProductMaterialModal] = useState(false);
    const [editCombined, setEditCombined] = useState<IProductMaterialByTemplate>();
    const [editCombinedModal, setEditCombinedModal] = useState(false);

    const visitData = data?.visit;
    const repeatingDayData = data?.visit.repeatingDayData;
    const theVisitStatus = repeatingDayData?.status || visitData?.status || VisitStatus.Scheduled;

    /**
     * Open modal for edit combined Product/Material.
     */
    const handleEditCombined = (combined: IProductMaterialByTemplate) => {
        if (setRecurringConfirmActionCallback && setRepeats && setRecurringEditModal) {
            setRecurringConfirmActionCallback((repeats: JobUpdateRepeats) => {
                setEditCombined(combined);
                setEditCombinedModal(true);
            });

            if (isRepeating) {
                setRepeats(JobUpdateRepeats.Single);
                setRecurringEditModal(true);
            } else {
                setEditCombined(combined);
                setEditCombinedModal(true);
            }
        } else {
            setEditCombined(combined);
            setEditCombinedModal(true);
        }
    };

    const canAdd = useMemo(() => {
        return hasSomePermissions([
            {permission: kPermissionsProducts, requiredPermissions: [kActionCreate]},
            {permission: kPermissionsMaterials, requiredPermissions: [kActionCreate]},
        ], employeePermissionsMap);
    }, [employeePermissionsMap]);

    const alreadyUsedProductTemplates = useMemo(() => {
        return data?.products
            .filter((product) => product.templateId);
    }, [data]);
    const alreadyUsedMaterialTemplates = useMemo(() => {
        return data?.materials
            .filter((material) => material.templateId);
    }, [data]);

    const combined = useMemo(() => {
        const productMaterials: IProductMaterial[] = [];

        if (data) {
            if (hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap)) {
                productMaterials.push(
                    ...data.products.map((product) => convertProductMaterial({product}))
                );
            }

            if (hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap)) {
                productMaterials.push(
                    ...data.materials.map((material) => convertProductMaterial({material}))
                );
            }
        }

        return combineProductMaterials({
            productMaterials,
            employees: data?.employees || [],
        });
    }, [data]);

    useEffect(() => {
        if (editCombinedModal && editCombined) {
            const updatedCombined = combined.find((item) => {
                return (item.productMaterialId && item.productMaterialId === editCombined.productMaterialId) || (item.productMaterialUuid && item.productMaterialUuid === editCombined.productMaterialUuid);
            });

            if (updatedCombined) {
                setEditCombined(updatedCombined);
            } else {
                setEditCombined(undefined);
                setEditCombinedModal(false);
            }
        }
    }, [combined]);

    const itemsJSX = useMemo(() => {
        if (combined.length > 0) {
            return combined
                .sort((a, b) => a.items[0].createdAt - b.items[0].createdAt)
                .map((item, index) => {
                    return (
                        <ProductMaterialListItem
                            key={index}
                            combined={item}
                            onEditCombined={canEdit ? handleEditCombined : undefined}
                            files={files}
                        />
                    );
                });
        }

        return undefined;
    }, [combined, employeePermissionsMap, canEdit, files]);

    return (
        <>
            <HeadlineWithButton
                title={displayProductMaterialListTitle(employeePermissionsMap)}
                iconJSX={<Icons8Plus/>}
                onClick={canEdit && canAdd ? () => {
                    if (setRecurringConfirmActionCallback && setRepeats && setRecurringEditModal) {
                        setRecurringConfirmActionCallback((repeats: JobUpdateRepeats) => setAddProductMaterialModal(true));

                        if (isRepeating) {
                            setRepeats(JobUpdateRepeats.Single);
                            setRecurringEditModal(true);
                        } else {
                            setAddProductMaterialModal(true);
                        }
                    } else {
                        setAddProductMaterialModal(true);
                    }
                } : undefined}
                buttonText={tt('visitDetail.productMaterialTab.addItem')}
            />

            {itemsJSX}

            {theVisitStatus !== VisitStatus.Canceled ? (
                <VisitProductMaterialsTotalSection
                    visitData={data}
                />
            ) : undefined}

            <AddProductMaterialModalBottomSheet
                open={addProductMaterialModal}
                setOpen={setAddProductMaterialModal}
                visitDetail={{
                    visitId,
                    repeatingDay,
                    repeats,
                    onUpdateVisitId,
                }}
                alreadyUsedProductTemplates={alreadyUsedProductTemplates}
                alreadyUsedMaterialTemplates={alreadyUsedMaterialTemplates}
            />

            <EditProductMaterialModalBottomSheet
                open={editCombinedModal}
                setOpen={setEditCombinedModal}
                data={editCombined}
                visitDetail={{
                    visitId,
                    repeatingDay,
                    repeats,
                    onUpdateVisitId,
                }}
                files={files}
            />
        </>
    );
}
