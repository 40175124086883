import {IAppDataContext} from "../AppData";
import {
    kActionView,
    kPermissionsMaterials,
    kPermissionsProducts,
    kTopicMaterialTemplates,
    kTopicProductTemplates
} from "../core/constants";
import {tt} from "../core/Localization";
import {IRestApiClientContext, IRestApiSubscription} from "../core/RestApiProvider";
import {
    AddVisitMaterialInput,
    AddVisitProductInput,
    CreateMaterialInput,
    CreateProductInput,
    EmployeeJoinedUserResponse,
    GetProductMaterialsByCompanyInput,
    MaterialResponse,
    ProductMaterialResponsePage,
    ProductResponse,
    ProductType
} from "../generated/graphql/graphql";
import ICreateProductMaterialInput from "../model/CreateProductMaterialInput";
import IProductMaterial, {IProductMaterialByTemplate, ProductMaterialType} from "../model/ProductMaterial";
import {hasPermission} from "../ui/components/permissions/PermissionValid";
import {GetVatAmount} from "./CompanyService";
import {processQueryError} from "./ErrorService";
import {uniqueArrayByPredicate} from "../utils/Utils";
import IAddProductMaterialToVisitInput from "../model/AddProductMaterialToVisitInput";

/**
 * Using restApi subscribe to ProductMaterialResponsePage.
 */
export function subscribeToProductMaterialResponsePage(
    restApiClientContext: IRestApiClientContext,
    appDataContext: IAppDataContext,
    params: {
        input: GetProductMaterialsByCompanyInput;
        setLoading?: (loading: boolean) => void;
        onData: (data: ProductMaterialResponsePage | NullOrUndefined) => void;
    },
): IRestApiSubscription {
    const {subscribe} = restApiClientContext;
    const {input, setLoading, onData} = params;

    return subscribe(
        [kTopicProductTemplates, kTopicMaterialTemplates],
        {
            uri: '/product-material/search-by-company',
            params: input,
            setLoading,
            onData,
            onError: (error) => {
                processQueryError(appDataContext, error);
            },
        },
        () => true,
    );
}

/**
 * Convert ProductMaterialType to text for display.
 */
export function productMaterialTypeDisplay(type: string | undefined, all?: boolean): string {
    if (!type && all) {
        return tt('productMaterials.type.all');
    }

    switch (type) {
        case ProductMaterialType.Product:
            return tt('productMaterials.type.product');
        case ProductMaterialType.Service:
            return tt('productMaterials.type.service');
        case ProductMaterialType.Material:
            return tt('productMaterials.type.material');
        default:
            return '';
    }
}

/**
 * Convert single ProductResponse or MaterialResponse into IProductMaterial.
 * You have to provide product or material.
 */
export function convertProductMaterial(params: {
    product?: ProductResponse | NullOrUndefined;
    material?: MaterialResponse | NullOrUndefined;
}): IProductMaterial {
    const {product, material} = params;

    if (product) {
        let type = ProductMaterialType.Product;

        if (product.type === ProductType.Service) {
            type = ProductMaterialType.Service;
        }

        return {
            type: type,
            product,
            id: product.id,
            name: product.name,
            price: product.price || 0,
            vatRate: product.vatRate || 0,
            createdAt: product.createdAt,
            cost: product.cost || 0,
        };
    } else {
        return {
            type: ProductMaterialType.Material,
            material: material!,
            id: material!.id,
            name: material!.name,
            price: material!.price || 0,
            vatRate: material!.vatRate || 0,
            createdAt: material!.createdAt,
            cost: material!.cost || 0,
        };
    }
}

/**
 * Convert list of Products/Materials into list of IProductMaterials.
 */
export function convertProductMaterials(params: {
    products: ProductResponse[];
    materials: MaterialResponse[];
}): IProductMaterial[] {
    return [
        ...params.products.map((product) => convertProductMaterial({product})),
        ...params.materials.map((material) => convertProductMaterial({material})),
    ].sort((a, b) => a.name.localeCompare(b.name));
}

/**
 * Convert ProductMaterialResponsePage into list of IProductMaterials.
 */
export function convertProductMaterialResponsePage(params: {
    productMaterialResponsePage: ProductMaterialResponsePage | NullOrUndefined;
    orderAlphabetically?: boolean;
    filterByType: ProductMaterialType | undefined;
}): IProductMaterial[] {
    const {productMaterialResponsePage, orderAlphabetically, filterByType} = params;

    const converted: IProductMaterial[] = [];

    if (productMaterialResponsePage) {
        if (!filterByType || filterByType === ProductMaterialType.Material) {
            for (const material of productMaterialResponsePage.materials) {
                converted.push(convertProductMaterial({material}));
            }
        }

        if (!filterByType || filterByType !== ProductMaterialType.Material) {
            const products = productMaterialResponsePage.products
                .filter((product) => {
                    if (!filterByType) {
                        return true;
                    }

                    if (filterByType === ProductMaterialType.Product && product.type === ProductType.Product) {
                        return true;
                    }

                    if (filterByType === ProductMaterialType.Service && product.type === ProductType.Service) {
                        return true;
                    }

                    return false;
                });

            for (const product of products) {
                converted.push(convertProductMaterial({product}));
            }
        }

        if (orderAlphabetically) {
            converted.sort((a, b) => a.name.localeCompare(b.name));
        } else {
            converted.sort((a, b) => b.createdAt - a.createdAt);
        }
    }

    return converted;
}

/**
 * Convert single CreateProductInput or CreateMaterialInput into ICreateProductMaterialInput.
 * You have to provide product or material.
 */
export function convertCreateProductMaterialInput(params: {
    product?: CreateProductInput;
    material?: CreateMaterialInput;
}): ICreateProductMaterialInput {
    const {product, material} = params;

    if (product) {
        let type = ProductMaterialType.Product;

        if (product.type === ProductType.Service) {
            type = ProductMaterialType.Service;
        }

        return {
            type: type,
            product,
            name: product.name,
            description: product.description,
        };
    } else {
        return {
            type: ProductMaterialType.Material,
            material: material!,
            name: material!.name,
            description: material!.description,
        };
    }
}

/**
 * Convert lists of CreateProductInput and CreateMaterialInput into combined list of ICreateProductMaterialInput.
 */
export function convertCreateProductMaterialInputs(params: {
    products: CreateProductInput[];
    materials: CreateMaterialInput[];
}): ICreateProductMaterialInput[] {
    const {products, materials} = params;

    const convertedMaterials = materials.map((material) => {
        return convertCreateProductMaterialInput({material});
    });

    const convertedProducts = products.map((product) => {
        return convertCreateProductMaterialInput({product});
    });

    return [
        ...convertedMaterials,
        ...convertedProducts,
    ];
}

/**
 * Convert single AddVisitProductInput or AddVisitMaterialInput into IAddProductMaterialToVisitInput.
 * You have to provide product or material.
 */
export function convertAddProductMaterialToVisitInput(params: {
    product?: Partial<AddVisitProductInput>;
    material?: Partial<AddVisitMaterialInput>;
}): IAddProductMaterialToVisitInput {
    const {product, material} = params;

    if (product) {
        let type = ProductMaterialType.Product;

        if (product.type === ProductType.Service) {
            type = ProductMaterialType.Service;
        }

        return {
            product,
            type,
            price: product.price || 0,
            vatRate: product.vatRate || 0,
            cost: product.cost || 0,
        };
    } else {
        return {
            material: material!,
            type: ProductMaterialType.Material,
            price: material!.price || 0,
            vatRate: material!.vatRate || 0,
            cost: material!.cost || 0,
        };
    }
}

/**
 * Convert lists of AddVisitProductInput and AddVisitMaterialInput into combined list of IAddProductMaterialToVisitInput.
 */
export function convertAddProductMaterialToVisitInputs(params: {
    products: AddVisitProductInput[];
    materials: AddVisitMaterialInput[];
}): IAddProductMaterialToVisitInput[] {
    const {products, materials} = params;

    const convertedMaterials = materials.map((material) => {
        return convertAddProductMaterialToVisitInput({material});
    });

    const convertedProducts = products.map((product) => {
        return convertAddProductMaterialToVisitInput({product});
    });

    return [
        ...convertedMaterials,
        ...convertedProducts,
    ];
}

/**
 * Combine ProductMaterials to groups by "template".
 */
export function combineProductMaterials(params: {
    productMaterials?: IProductMaterial[];
    createInputs?: ICreateProductMaterialInput[];
    employees: EmployeeJoinedUserResponse[];
}): IProductMaterialByTemplate[] {
    const {productMaterials, createInputs, employees} = params;

    const combined: IProductMaterialByTemplate[] = [];

    if (productMaterials) {
        for (const productMaterial of productMaterials) {
            const templateId = productMaterial.product?.templateId || productMaterial.material?.templateId;
            const employeeId = productMaterial.product?.employeeId || productMaterial.material?.employeeId;
            const employee = employees.find(e => e.id === employeeId);

            if (templateId) {
                const existing = combined.find((item) => {
                    return item.productMaterialId === templateId && item.type === productMaterial.type;
                });

                if (existing) {
                    existing.items.push(productMaterial);

                    if (employee) {
                        existing.employees.push(employee);

                        existing.employees = uniqueArrayByPredicate(existing.employees, (a, b) => a.id === b.id);
                    }
                } else {
                    combined.push({
                        productMaterialId: templateId,
                        type: productMaterial.type,
                        name: productMaterial.name,
                        unitName: productMaterial.product?.unitName || productMaterial.material?.unitName || '',
                        description: productMaterial.product?.description || productMaterial.material?.description,
                        items: [productMaterial],
                        inputs: [],
                        stats: [],
                        employees: employee ? [employee] : [],
                    });
                }
            } else {
                combined.push({
                    productMaterialUuid: (productMaterial.product?.uuid || productMaterial.material?.uuid)!,
                    type: productMaterial.type,
                    name: productMaterial.name,
                    unitName: productMaterial.product?.unitName || productMaterial.material?.unitName || '',
                    description: productMaterial.product?.description || productMaterial.material?.description,
                    items: [productMaterial],
                    inputs: [],
                    stats: [],
                    employees: employee ? [employee] : [],
                });
            }
        }
    }

    if (createInputs) {
        for (const createInput of createInputs) {
            const templateId = createInput.product?.templateId || createInput.material?.templateId;
            const employeeId = createInput.product?.employeeId || createInput.material?.employeeId;
            const employee = employees.find(e => e.id === employeeId);

            if (templateId) {
                const existing = combined.find((item) => {
                    return item.productMaterialId === templateId && item.type === createInput.type;
                });

                if (existing) {
                    existing.inputs.push(createInput);

                    if (employee) {
                        existing.employees.push(employee);

                        existing.employees = uniqueArrayByPredicate(existing.employees, (a, b) => a.id === b.id);
                    }
                } else {
                    combined.push({
                        productMaterialId: templateId,
                        type: createInput.type,
                        name: createInput.name,
                        unitName: createInput.product?.unitName || createInput.material?.unitName || '',
                        description: createInput.description,
                        items: [],
                        inputs: [createInput],
                        stats: [],
                        employees: employee ? [employee] : [],
                    });
                }
            } else {
                combined.push({
                    productMaterialUuid: createInput.product?.uuid || createInput.material?.uuid,
                    type: createInput.type,
                    name: createInput.name,
                    unitName: createInput.product?.unitName || createInput.material?.unitName || '',
                    description: createInput.description,
                    items: [],
                    inputs: [createInput],
                    stats: [],
                    employees: employee ? [employee] : [],
                });
            }
        }
    }

    for (const combinedOf of combined) {
        for (const item of combinedOf.items) {
            const employeeId = item.product?.employeeId || item.material?.employeeId;
            const employee = combinedOf.employees.find(e => e.id === employeeId);
            const unitName = item.product?.unitName || item.material?.unitName;
            const unitCount = item.product?.unitCount || item.material?.unitCount;

            const priceByCount = item.price * (unitCount || 0);
            const costByCount = item.cost * (unitCount || 0);

            const identifier = `${item.price}-${item.vatRate}-${item.cost}`;

            const existingStat = combinedOf.stats.find((stat) => {
                return stat.identifier === identifier;
            });

            if (existingStat) {
                existingStat.unitCount += unitCount || 0;
                existingStat.price += priceByCount;
                existingStat.cost += costByCount;

                if (employee) {
                    existingStat.employees.push(employee);

                    existingStat.employees = uniqueArrayByPredicate(existingStat.employees, (a, b) => a.id === b.id);
                }
            } else {
                combinedOf.stats.push({
                    identifier: identifier,
                    unitCount: unitCount || 0,
                    unitName: unitName || '',
                    price: priceByCount,
                    vatRate: item.vatRate,
                    cost: costByCount,
                    employees: employee ? [employee] : [],
                });
            }
        }

        for (const input of combinedOf.inputs) {
            const unitName = input.product?.unitName || input.material?.unitName;
            const unitCount = input.product?.unitCount || input.material?.unitCount;
            const price = input.product?.price || input.material?.price;
            const vatRate = input.product?.vatRate || input.material?.vatRate;
            const cost = input.product?.cost || input.material?.cost;

            const priceByCount = (price || 0) * (unitCount || 0);
            const costByCount = (cost || 0) * (unitCount || 0);

            const identifier = `${price}-${vatRate}-${cost}`;

            const existingStat = combinedOf.stats.find((stat) => {
                return stat.identifier === identifier;
            });

            if (existingStat) {
                existingStat.unitCount += unitCount || 0;
                existingStat.price += priceByCount;
                existingStat.cost += costByCount;
            } else {
                combinedOf.stats.push({
                    identifier: identifier,
                    unitCount: unitCount || 0,
                    unitName: unitName || '',
                    price: priceByCount,
                    vatRate: vatRate || 0,
                    cost: costByCount,
                    employees: combinedOf.employees,
                });
            }
        }
    }

    return combined;
}

/**
 * Calculate profit stats for ProductMaterial.
 */
export function calculateProductMaterialProfit(productMaterial: IProductMaterial) {
    return calculateProductMaterialProfitRaw(
        productMaterial.price,
        productMaterial.cost,
        productMaterial.vatRate,
    );
}

/**
 * Calculate profit stats for raw values.
 */
export function calculateProductMaterialProfitRaw(
    price: number,
    cost: number,
    vatRate: number,
) {
    let profit = price - cost;
    let profitPercent = 0;
    let profitVat = 0;
    let isNegative = false;

    if (profit >= 0) {
        if (price > 0 && cost > 0) {
            profitPercent = (profit / price) * 100;
        } else if (price > 0) {
            profitPercent = 100;
        }

        profitVat = GetVatAmount(profit, vatRate);
    } else {
        isNegative = true;
        profit = Math.abs(profit);

        if (price > 0 && cost > 0) {
            profitPercent = (profit / price) * 100;
        } else if (price > 0) {
            profitPercent = 100;
        }
    }

    return {
        profit,
        profitRaw: price - cost,
        profitVat,
        profitPercent,
        isNegative,
    };
}

/**
 * Combined Product/Material title based on permissions.
 */
export function displayProductMaterialListTitle(employeePermissionsMap: Record<string, string[]> | NullOrUndefined, singular?: boolean) {
    const products = hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap);
    const materials = hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap);

    if (products && materials) {
        return singular ? tt('productMaterials.title.singular') : tt('productMaterials.title');
    } else if (products) {
        return singular ? tt('productMaterials.title.onlyProducts.singular') : tt('productMaterials.title.onlyProducts');
    } else {
        return singular ? tt('productMaterials.title.onlyMaterials.singular') : tt('productMaterials.title.onlyMaterials');
    }
}
