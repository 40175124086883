import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme} from "@mui/material";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {
    EmployeeJoinedUserResponse, FileResponse,
    JobEmployeeDataResponse,
    JobEmployeeStatus
} from "../../../generated/graphql/graphql";
import ModalBottomSheet from "../ModalBottomSheet";
import BottomSheetModalAppbar from "./BottomSheetModalAppbar";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";
import AppListItem from "../listItems/AppListItem";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../service/UserService";
import {
    getEmployeeJobStatusIcon,
    getEmployeeJobStatusTextColor,
    getEmployeeJobStatusTitle
} from "../../../service/VisitService";
import {CheckCircle} from "@mui/icons-material";
import {AppDataContext} from "../../../AppData";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        overflow: "auto",
    },
    buttonContainer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
    }
}));


export interface IWorkerEditJobStatusModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    employeeData: EmployeeJoinedUserResponse;
    files: FileResponse[] | NullOrUndefined;
    employeeJobData: JobEmployeeDataResponse;
    onSave: (employeeId: number, status: JobEmployeeStatus) => void | Promise<void>;
}

export default function WorkerEditJobStatusModalBottomSheet(props: IWorkerEditJobStatusModalBottomSheetProps) {
    const {
        employeeData,
        files,
        employeeJobData,
        open,
        setOpen,
        onSave,
    } = props;

    const {classes} = useStyles();

    const appDataContext = useContext(AppDataContext);
    const {darkMode, storage} = appDataContext;

    const [jobStatus, setJobStatus] = useState<JobEmployeeStatus>(employeeJobData.status);

    useEffect(() => {
        if (open) {
            setJobStatus(employeeJobData.status);
        }
    }, [open]);

    const statuses: Array<JobEmployeeStatus> = [
        JobEmployeeStatus.Scheduled,
        JobEmployeeStatus.Travelling,
        JobEmployeeStatus.InProgress,
        JobEmployeeStatus.Done,
        JobEmployeeStatus.CanceledByWorker,
        JobEmployeeStatus.NotArrived,
    ];

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            children={
                <Box className={classes.container}>
                    <BottomSheetModalAppbar
                        onClose={() => setOpen(false)}
                        title={tt('workerEditVisitStatusModal.visitStatus')}
                        noBorderBottom={true}
                    />

                    <AppListItem
                        profileImage={
                            UserPhotoUrl(employeeData?.user, files, storage.publicUrlsForFiles)
                        }
                        key={employeeData.id}
                        variant={"smaller-title"}
                        title={UserFullName(employeeData?.name || employeeData?.user?.name, employeeData?.surname || employeeData?.user?.surname)}
                        description={UserRoleTitle(employeeData.role)}
                        actionWidget={<></>}
                    />
                    <Divider></Divider>
                    {
                        statuses.map((status) => {
                            return <AppListItem
                                key={status}
                                title={getEmployeeJobStatusTitle(status, true)}
                                customAvatar={<Box
                                    sx={{color: getEmployeeJobStatusTextColor(status, darkMode)}}>{getEmployeeJobStatusIcon(status)}</Box>}
                                variant={"smaller-title"}
                                onClick={() => setJobStatus(status)}
                                actionWidget={jobStatus === status ?
                                    <CheckCircle color={"primary"}/>
                                    : <></>}
                                noMarginBottom={true}
                            />;
                        })
                    }
                    <Box pb={10}></Box>
                    <Box className={classes.buttonContainer} p={2}>
                        <AppButton
                            variant={"contained"}
                            disabled={employeeJobData.status === jobStatus}
                            fullWidth={true}
                            onClick={() => {
                                onSave(employeeData.id, jobStatus);

                                setOpen(false);
                            }}>
                            {tt('workerEditJobStatusModal.button.changeStatus')}
                        </AppButton>
                    </Box>
                </Box>
            }
        />
    );

}
