import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {AppContext} from "../../../../App";
import {tt} from "../../../../core/Localization";
import ResponsiveContainer from "../../../components/screens/ResponsiveContainer";
import ScreenContent from "../../../components/screens/ScreenContent";
import {
    GetVisitsInput, GetVisitsJoinedOthersInput,
    VisitJoinedOthersResponsePage, VisitListPureJoinedOthersResponsePage, VisitListPureResponse,
    VisitStatus,
    VisitStatusModifier
} from "../../../../generated/graphql/graphql";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {DateTime} from "luxon";
import {
    filterJobEmployeeData,
    FilterJobEmployeeTimesheetItems,
    filterJobOfferSeatsForVisit,
    getVisitStatusModifierTitle,
    getVisitStatusTitle,
    IVisitsDay,
    kVisitsListDisplayLimitPage,
    ProcessVisitsEventsToDays,
    VisitsProcess,
    VisitsProcessScheduleLater
} from "../../../../service/VisitService";
import {AppDataContext} from "../../../../AppData";
import {Box, Theme, Typography} from "@mui/material";
import VisitItemWithBackgroundShimmer from "../../../components/shimmers/VisitItemWithBackgroundShimmer";
import {kAppColors, kContentWidthMedium} from "../../../../styles/AppThemeProcessor";
import {makeStyles} from "tss-react/mui";
import AppPaper from "../../../components/paper/AppPaper";
import {kDashboardRoute} from "../../dashboard/DashboardScreen";
import PaperAppbar from "../../../components/paper/PaperAppbar";
import JobsDateHeadlineShimmer from "../../../components/shimmers/JobsDateHeadlineShimmer";
import AppButton from "../../../components/buttons/AppButton";
import {boxContentStyles} from "../../../../styles/UtilStyles";
import {processQueryError} from "../../../../service/ErrorService";
import {isJobOfferSeatFull} from "../../../../service/JobService";
import VisitListItem from "../../../components/jobs/jobDetail/VisitListItem";
import EmptyListText from "../../../components/textComponents/EmptyListText";
import AppIconButton from "../../../components/buttons/AppIconButton";
import ArrowLeftIcon from "../../../../icons/ArrowLeftIcon";
import {filterJobFormsPureByVisit} from "../../../../service/JobFormService";
import {filterProductsForVisit} from "../../../../service/ProductService";
import {filterMaterialsForVisit} from "../../../../service/MaterialService";
import {kTopicVisits} from "../../../../core/constants";
import {RestApiClientContext} from "../../../../core/RestApiProvider";
import {getPublicUrls} from "../../../../service/StorageService";

export const kVisitsForStatusRoute = '/visit-status/:status';

/**
 * Construct the route for the Jobs screen filtered by status.
 */
export function visitsForStatusRoute(status: VisitStatus | string) {
    return kVisitsForStatusRoute
        .replace(':status', status);
}

const useStyles = makeStyles()((theme: Theme) => ({
    cardsContainer: {
        marginRight: "auto",
        marginLeft: "auto",
        border: "none",
        width: '100%',
    },
    dateHeader: {
        zIndex: 1,
        position: "sticky",
        top: -16,
        fontSize: 18,
        textAlign: "center",
        fontWeight: 600,
        margin: 0,
        paddingTop: 16,
        paddingBottom: 16,
        marginLeft: -16,
        marginRight: -16,
        backgroundColor: kAppColors.background.bodyBackground(theme.palette.mode === "dark"),
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        "@media (max-width: 768px)": {
            top: 56,
            marginLeft: 0,
            marginRight: 0,
        }
    },
    topPaper: {
        padding: 8,
        display: "flex",
        alignItems: "center",
    },
    title: {
        flexGrow: 1,
        marginBottom: 0,
        fontSize: 20,
        fontWeight: 600,
    },
    appbarContainer: {
        width: '100%',
        position: "fixed",
        zIndex: 100,
    },
    scrollPadding: {
        scrollPaddingTop: 59,
    },
    singleItemContainer: {
        marginBottom: 16,
        overflow: "clip",
        cursor: "pointer",
        transition: 'background-color 0.3s linear',
        background: theme.palette.mode === "dark" ? '#202020' : "white",
        borderRadius: 8,
        "@media (max-width: 767px)": {
            border: "none",
            borderRadius: 0,
        }
    },
    noMarginBottom: {
        marginBottom: 0,
    }
}));

/**
 * Screen component for Jobs screen filtered by status.
 */
export default function VisitsForStatusScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const {status, statusModifier} = useParams();
    const theVisitStatus = status as VisitStatus;

    const appDataContext = useContext(AppDataContext);
    const {companyId, language, storage, setStorage} = appDataContext;

    const [searchParams, setSearchParams] = useSearchParams();

    const theVisitStatusModifier = searchParams.get('statusModifier') ? searchParams.get('statusModifier')! as VisitStatusModifier : null;
    const theIncludeAllStatusModifiers = searchParams.get('includeAllStatusModifiers') ? searchParams.get('includeAllStatusModifiers')! === 'true' : false;

    const {classes, cx} = useStyles();
    const {classes: boxContentClasses} = boxContentStyles();

    const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | null>(searchParams.get('employeeId') ? parseInt(searchParams.get('employeeId')!) : null);
    const [fromDate, setFromDate] = useState<DateTime>(searchParams.get('fromDate') ? DateTime.fromMillis(parseInt(searchParams.get('fromDate')!)) : DateTime.now().startOf("day"));
    const [toDate, setToDate] = useState<DateTime>(searchParams.get('toDate') ? DateTime.fromMillis(parseInt(searchParams.get('toDate')!)) : DateTime.now().endOf("day"));
    const [daysData, setDaysData] = useState<IVisitsDay[] | undefined>(undefined);
    const [jobsCount, setJobsCount] = useState<number>(0);
    const [jobsDisplayLimit, setJobsDisplayLimit] = useState<number>(searchParams.get('jobsDisplayLimit') ? parseInt(searchParams.get('jobsDisplayLimit')!) : kVisitsListDisplayLimitPage);

    const navigateToItemRef = useRef<HTMLDivElement | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<VisitListPureJoinedOthersResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicVisits,
                {
                    uri: '/job/visit/search-list-pure-joined-others',
                    params: {
                        companyId: companyId!,
                        fromDate: fromDate.toMillis(),
                        toDate: toDate.toMillis(),
                        includeScheduleLater: theVisitStatus === VisitStatus.ScheduleLater,
                        includeJobEmployeeData: true,
                    } as GetVisitsJoinedOthersInput,
                    setLoading,
                    onData: setData,
                    onError: (error) => {
                        processQueryError(appDataContext, error);
                    },
                },
                (notifications) => true,
            );

            return () => subscription.cancel();
        }
    }, [companyId, fromDate, toDate, theVisitStatus]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    useEffect(() => {
        setTitle(tt('visitsForStatus.screen.title'));
        document.body.classList.add('jobsScreen');

        return () => {
            document.body.classList.remove('jobsScreen');
        }
    }, []);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('jobsDisplayLimit', jobsDisplayLimit.toString());
            return prev;
        });
    }, [jobsDisplayLimit]);

    useEffect(() => {
        if (data) {
            let visitsProcessed = VisitsProcess({
                nonRepeating: data.nonRepeating,
                repeating: data.repeating,
                visitRepeatDays: data.repeatingDayData,
                from: fromDate,
                to: toDate,
                sortDesc: theVisitStatus === VisitStatus.Done || theVisitStatus === VisitStatus.Closed || theVisitStatus === VisitStatus.Canceled,
                multipleEventsForMultiDay: true,
            });

            const theJobOfferSeats = data.jobOfferSeats;

            visitsProcessed = theVisitStatus !== VisitStatus.ScheduleLater ? visitsProcessed.filter(visit => {
                const jobOfferSeats = filterJobOfferSeatsForVisit(visit, theJobOfferSeats);

                const status = visit.visitRepeatDay?.status || visit.status;
                const statusModifier = visit.visitRepeatDay?.statusModifier || visit.statusModifier;

                let statusFilter = !theVisitStatus || status === theVisitStatus;

                if (!statusFilter && jobOfferSeats) {
                    const notFull = jobOfferSeats.find(seat => !isJobOfferSeatFull(seat));

                    if (notFull && theVisitStatus == VisitStatus.JobOffer && status !== VisitStatus.Canceled && status !== VisitStatus.Done && status !== VisitStatus.Closed) {
                        statusFilter = true;
                    }
                }

                if (theVisitStatus === VisitStatus.Closed && !theVisitStatusModifier && statusModifier && statusModifier !== VisitStatusModifier.None && !theIncludeAllStatusModifiers) {
                    statusFilter = false;
                }
                if (theVisitStatus === VisitStatus.Closed && theVisitStatusModifier && theVisitStatusModifier !== statusModifier) {
                    statusFilter = false;
                }

                const employeeIds = visit.visitRepeatDay?.employeeIds || visit.employeeIds;

                let employeeFilter = !selectedEmployeeId || employeeIds.includes(selectedEmployeeId);

                if (selectedEmployeeId && !employeeFilter && jobOfferSeats) {
                    const jobOfferSeatForEmployee = jobOfferSeats.find(jobOfferSeat => jobOfferSeat.employeeIds.includes(selectedEmployeeId));

                    if (jobOfferSeatForEmployee) {
                        employeeFilter = true;
                    }
                }

                return statusFilter && employeeFilter;
            }) : VisitsProcessScheduleLater(data.scheduleLater || []);

            setDaysData(
                ProcessVisitsEventsToDays(
                    visitsProcessed,
                    theVisitStatus === VisitStatus.Done || theVisitStatus === VisitStatus.Closed || theVisitStatus === VisitStatus.Canceled,
                ),
            );

            setJobsCount(visitsProcessed.length);
        }
    }, [data, theVisitStatus, theVisitStatusModifier]);

    /**
     * Scroll to job and clear.
     */
    const ScrollToJob = () => {
        setTimeout(() => {
            navigateToItemRef.current?.scrollIntoView({behavior: 'smooth'});

            setSearchParams(prev => {
                prev.set('id', '');
                return prev;
            });
        }, 1);
    };

    const items = useMemo(() => {
        if (!daysData) {
            return undefined;
        }

        let visitsDisplaying = 0;

        return (
            <>
                {daysData.map((day, daysDataIndex) => {
                    if (jobsDisplayLimit < visitsDisplaying && visitsDisplaying !== 0) {
                        return null;
                    }

                    visitsDisplaying += day.visits.length;

                    return (
                        <div key={day.date.toMillis()}>
                            {theVisitStatus !== VisitStatus.ScheduleLater ? (
                                <Typography
                                    paragraph={true}
                                    className={classes.dateHeader}
                                >
                                    {day.date.toFormat('EEEE d MMMM yyyy', {locale: language})}
                                </Typography>
                            ) : undefined}

                            {day.visits.map((visit, index) => {
                                const navigateToId = searchParams.get('id');

                                if (navigateToId && navigateToId == (visit.dynamicId || visit.id)) {
                                    ScrollToJob();
                                }

                                const employees = data?.employees || [];

                                const job = data?.jobs.find(job => job.id === visit.jobId);
                                const client = data?.clients.find(client => client.id === visit.clientId);
                                const locationId = visit.visitRepeatDay?.locationId || visit.locationId;
                                const location = locationId ? data?.locations.find(location => location.id === locationId) : undefined;
                                const jobForms = filterJobFormsPureByVisit(visit.id, visit.repeatingDay, data?.jobForms || []);
                                const timesheets = FilterJobEmployeeTimesheetItems({
                                    jobEmployeeTimesheetItems: data?.timesheets,
                                    repeatingDay: visit.repeatingDay,
                                    filterByVisitId: visit.id,
                                });
                                const jobEmployeeData = filterJobEmployeeData({
                                    jobEmployeeData: data?.jobEmployeeData,
                                    repeatingDay: visit.repeatingDay,
                                    filterByVisitId: visit.id,
                                });
                                const products = filterProductsForVisit({
                                    products: data?.products,
                                    visitId: visit.id,
                                    repeatingDay: visit.repeatingDay,
                                });
                                const materials = filterMaterialsForVisit({
                                    materials: data?.materials,
                                    visitId: visit.id,
                                    repeatingDay: visit.repeatingDay,
                                });
                                const jobOfferSeats = filterJobOfferSeatsForVisit(visit, data?.jobOfferSeats);

                                const theEmployeeIds = visit.visitRepeatDay?.employeeIds || visit.employeeIds;
                                const theEmployees = employees.filter(employee => {
                                    const jobOfferSeatForEmployee = jobOfferSeats?.find(jobOfferSeat => jobOfferSeat.acceptedIds.includes(employee.id));

                                    return theEmployeeIds.includes(employee.id) || jobOfferSeatForEmployee;
                                });

                                return (
                                    <div key={visit.dynamicId || visit.id}
                                         ref={(navigateToId && navigateToId == (visit.dynamicId || visit.id)) ? navigateToItemRef : undefined}>
                                        <Box
                                            className={cx(classes.singleItemContainer, index === day.visits.length - 1 && visit.status != VisitStatus.ScheduleLater ? classes.noMarginBottom : null)}>
                                            <VisitListItem
                                                key={visit.dynamicId || visit.id}
                                                data={visit}
                                                job={job}
                                                client={client}
                                                location={location}
                                                employees={theEmployees}
                                                jobForms={jobForms}
                                                timesheets={timesheets}
                                                employeeJobData={jobEmployeeData || []}
                                                products={products}
                                                materials={materials}
                                                jobOfferSeats={jobOfferSeats}
                                                visitDetailCanNavigateToJob={true}
                                                files={data?.files}
                                            />
                                        </Box>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}

                {jobsDisplayLimit < jobsCount ? (
                    <>
                        <Box sx={{pb: 2}}/>

                        <Box className={boxContentClasses.centered}>
                            <AppButton variant="outlined" color="primary" disabled={loading}
                                       onClick={() => setJobsDisplayLimit(jobsDisplayLimit + kVisitsListDisplayLimitPage)}>
                                {tt('common.loadMore')}
                            </AppButton>
                        </Box>

                        <Box sx={{pb: 2}}/>
                    </>
                ) : undefined}
            </>
        );
    }, [fromDate, toDate, daysData, data, jobsDisplayLimit, storage.publicUrlsForFiles]);

    let visitStatusTitle = getVisitStatusTitle(theVisitStatus);
    if (theVisitStatus === VisitStatus.Closed && theIncludeAllStatusModifiers) {
        visitStatusTitle = tt('dashboardScreen.tab.finished');
    } else if (theVisitStatus === VisitStatus.Closed && theVisitStatusModifier) {
        visitStatusTitle = getVisitStatusModifierTitle(theVisitStatusModifier);
    }

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMobile={isMobile}
                loading={loading}
                items={items}
                jobsCount={jobsCount}
                theVisitStatus={theVisitStatus}
                visitStatusTitle={visitStatusTitle}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}/>
        </>
    );
};

interface IBodyProps {
    isMobile?: boolean;
    loading?: boolean;
    items: React.ReactNode | React.ReactNode[] | undefined;
    jobsCount: number;
    theVisitStatus: VisitStatus;
    visitStatusTitle: string;
}

/**
 * Body component for Jobs screen filtered by status.
 */
function Body(props: IBodyProps) {
    const {
        isMobile,
        loading,
        items,
        theVisitStatus,
        visitStatusTitle,
        jobsCount,
    } = props;

    const {classes} = useStyles();

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}
        >
            {isMobile ? <>
                <Box className={classes.appbarContainer}>
                    <PaperAppbar
                        title={visitStatusTitle}
                        isMobile={true}
                        backRoute={`${kDashboardRoute}${location.search}`}
                    />
                </Box>
                <Box pb={7}/>
            </> : null}

            <Box
                className={classes.cardsContainer}
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>

                {isMobile ? null : <AppPaper className={classes.topPaper}>
                    <AppIconButton
                        onClick={() => navigate(`${kDashboardRoute}${location.search}`)}
                    ><ArrowLeftIcon/></AppIconButton>
                    <Box pl={1}/>
                    <Typography className={classes.title}>{visitStatusTitle}</Typography>
                </AppPaper>}

                {theVisitStatus === VisitStatus.ScheduleLater ? <Box sx={{pb: 2}}/> : null}

                {loading === undefined || items === undefined ? <>
                    <JobsDateHeadlineShimmer/>
                    <VisitItemWithBackgroundShimmer/>
                    <VisitItemWithBackgroundShimmer/>
                </> : (!items || jobsCount === 0 ?
                    <EmptyListText
                        text={theVisitStatus === VisitStatus.JobOffer ? tt('visitsForStatus.screen.offersEmpty') : tt('visits.screen.empty')}/>
                    : items)}
                <Box pb={0.5}/>
            </Box>
        </ScreenContent>
    );
}
