import {makeStyles} from "tss-react/mui";
import {Box, Container, Theme, Toolbar} from "@mui/material";
import NavigationDrawer from "./NavigationDrawer";
import React from "react";
import DefaultAppBar from "./DefaultAppBar";
import BottomBar from "./BottomBar";
import MobileChoseCompanyAppBar from "../MobileChoseCompanyAppBar";
import InfoSection from "../infos/InfoSection";
import BuySubscriptionBanner, {BuySubscriptionBannerDummy} from "../banners/BuySubscriptionBanner";

const useStyles = makeStyles()((theme: Theme) => ({
    box: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        maxWidth: '100%',
    },
    screenContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
        paddingBottom: 40,
        overflow: 'auto',
        '> .MuiPaper-root': {
            paddingTop: 8,
        },
        "@media (max-width: 767px)": {
            '> .MuiPaper-root': {
                paddingTop: 0,
                paddingBottom: 20,
            },
        },
    },
    centerHorizontally: {
        alignItems: "center",
    },
    centerContent: {
        height: '100%',
        justifyContent: "center",
        alignItems: "center",
    },
    centerWithAppbar: {
        paddingBottom: 80
    },
    noContentPadding: {
        padding: 0 + '!important',
    }
}));

export interface IScreenContentProps {
    children?: React.ReactNode;
    appBar?: boolean;
    bottomBar?: boolean;
    navigationDrawer?: boolean;
    centerContent?: boolean;
    centerHorizontally?: boolean;
    noContentPadding?: boolean;
    mobileChoseCompanyWidget?: boolean;
    showBreadCrumb?: boolean;
    className?: string;
    hideInfoSection?: boolean;
}

/**
 * Screen main contents component.
 */
export default function ScreenContent(props: IScreenContentProps) {
    const {
        children,
        appBar,
        navigationDrawer,
        centerContent,
        centerHorizontally,
        bottomBar,
        noContentPadding,
        mobileChoseCompanyWidget,
        showBreadCrumb,
        className,
        hideInfoSection,
    } = props;

    const {classes, cx} = useStyles();

    const containerClassName = cx(
        classes.screenContent,
        centerContent ? classes.centerContent : undefined,
        centerContent && appBar ? classes.centerWithAppbar : undefined,
        centerHorizontally ? classes.centerHorizontally : undefined,
        noContentPadding ? classes.noContentPadding : undefined,
        className,
    );

    return (
        <>
            {appBar ? <DefaultAppBar showBreadCrumb={showBreadCrumb}/> : null}

            {navigationDrawer ? <NavigationDrawer/> : null}

            <Box className={classes.box}>
                {appBar ? (
                    <>
                        <BuySubscriptionBannerDummy/>

                        <Toolbar/>
                    </>
                ) : null}
                {mobileChoseCompanyWidget ? <MobileChoseCompanyAppBar/> : null}

                <Container className={containerClassName} maxWidth={false}>
                    {!appBar ? <BuySubscriptionBanner/> : null}
                    {!hideInfoSection ? <InfoSection/> : null}

                    {children}
                </Container>

                {bottomBar ? <BottomBar/> : null}

                {bottomBar ? <Box pb={7}/> : null}
            </Box>
        </>
    );
}
