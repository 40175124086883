import ScreenContent from "../../../components/screens/ScreenContent";
import {useContext, useEffect, useState} from "react";
import {AppDataContext} from "../../../../AppData";
import {kContentWidthMedium} from "../../../../styles/AppThemeProcessor";
import AppPaper from "../../../components/paper/AppPaper";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import {kDiscountDuration} from "../../../components/banners/BuySubscriptionBanner";
import {GetSubscriptionSecondsFromStart} from "../../../../service/SubscriptionService";
import {hasPermission} from "../../../components/permissions/PermissionValid";
import {kActionCreate, kPermissionsCompanySubscription} from "../../../../core/constants";
import {useSearchParams} from "react-router-dom";

export const kSimpleShopBuyForm = 'https://form.simpleshop.cz/KbML5/buy/';
export const kSimpleShopDiscountedForm = 'https://form.simpleshop.cz/w0QW9/buy/';

export const kSimpleShopRoute = '/settings/company/simple-shop';

export const useStyles = makeStyles()((theme: Theme) => ({
    iframe: {
        width: '100%',
        border: 'none'
    },
    iframeNonMobile: {
        height: 2200,
    },
}));

/**
 * Screen component for SimpleShop iframe to buy subscription.
 */
export default function SimpleShopScreen() {
    const appDataContext = useContext(AppDataContext);
    const {isMobile, companyData, companySubscriptionStatus, employeePermissionsMap} = appDataContext;

    const [searchParams, setSearchParams] = useSearchParams();

    const permission = hasPermission(kPermissionsCompanySubscription, [kActionCreate], employeePermissionsMap);
    const displayForm = companyData.subscriptionIsTrial && permission;

    const secondsFromStart = GetSubscriptionSecondsFromStart(companySubscriptionStatus?.subscription);

    const fromDiscount = searchParams.get('discount');
    const hasDiscount = secondsFromStart * 1000 < kDiscountDuration && companyData.subscriptionIsValid && fromDiscount === '30_off_5';

    const [mobileIframeHeight, setMobileIframeHeight] = useState(0);

    useEffect(() => {
        const callback = () => {
            const bannerHeight = document.getElementById('buy-subscription-banner')?.offsetHeight || 0;
            const bottomBarHeight = document.getElementById('bottom-bar')?.offsetHeight || 0;
            const height = window.innerHeight - bannerHeight - bottomBarHeight;

            setMobileIframeHeight(height);
        };

        setTimeout(() => {
            callback();
        }, 100);

        window.addEventListener('resize', callback);

        return () => {
            window.removeEventListener('resize', callback);
        };
    }, []);

    const {classes, cx} = useStyles();

    const iframeClassName = cx(
        classes.iframe,
        isMobile ? undefined : classes.iframeNonMobile
    );

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}
        >
            {displayForm ? (
                <AppPaper
                    sx={{maxWidth: isMobile ? undefined : kContentWidthMedium, paddingBottom: 0}}>
                    <iframe
                        id="simple-shop-iframe"
                        className={iframeClassName}
                        src={hasDiscount ? kSimpleShopDiscountedForm : kSimpleShopBuyForm}
                        height={isMobile ? `${mobileIframeHeight}px` : undefined}
                    />
                </AppPaper>
            ) : null}
        </ScreenContent>
    );
}
