import {kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import {Box, Theme, Typography} from "@mui/material";
import AppModal from "./AppModal";
import React from "react";
import {makeStyles} from "tss-react/mui";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
    },
}));

export interface ITextModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    text: string;
    buttonText?: string;
    onClick?: VoidCallback;
    modalAboveModals?: boolean;
}

/**
 * Generic text modal component.
 */
export default function TextModal(props: ITextModalProps) {
    const {open, setOpen, title, text, buttonText, onClick, modalAboveModals} = props;

    const {classes} = useStyles();

    const actionsJSX = buttonText && onClick ? (
        <>
            <AppButton
                variant={"contained"}
                fullWidth={true}
                onClick={onClick}>
                {buttonText}
            </AppButton>
        </>
    ) : null;

    return (
        <AppModal
            blurBackdrop={true}
            paperClassName={classes.modalPaper}
            title={title}
            open={open}
            setOpen={setOpen}
            actions={actionsJSX}
            modalAboveModals={modalAboveModals}>
            <Typography>{text}</Typography>

            <Box pb={1.75}/>
        </AppModal>
    );
}
