import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {tt} from "../../../../core/Localization";
import React, {useContext, useMemo} from "react";
import {
    calculateTimesheets,
    CalculateTotalPrice,
    distanceDisplay,
    HoursMinutesDisplayRaw,
    paymentTypeToItemPaymentType,
    PriceDisplay
} from "../../../../service/CompanyService";
import {AppDataContext} from "../../../../AppData";
import {
    CreateJobEmployeeTimesheetItemInput,
    CreateJobOfferSeatInput,
    VisitDetailResponse
} from "../../../../generated/graphql/graphql";
import Icons8Cost from "../../../../icons/Icons8Cost";
import {hasPermission} from "../../permissions/PermissionValid";
import {kActionView, kPermissionsTimesheets} from "../../../../core/constants";

const useStyles = makeStyles()((theme: Theme) => ({
    totalSectionContainerOuter: {
        marginTop: 16,
        borderTop: `dashed 1px ${kAppColors.border(theme.palette.mode === "dark")}`,
        paddingTop: 16,
        paddingBottom: 16,
        display: "flex",
        justifyContent: "stretch",
    },
    totalSectionContainerInner: {
        width: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
    },
    singleRow: {
        width: '100%',
        justifyContent: "end",
        display: "flex",
        paddingLeft: 16,
        paddingRight: 16,
    },
    singleCell: {
        padding: 8,
        width: '33.3%',
        maxWidth: 120,
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        textOverflow: "ellipsis",
    },
    singleCellMobile: {
        maxWidth: '100%',
    },
    singleCellWithArrow: {
        justifyContent: "space-between",
    },
    valuesRow: {
        borderTop: `dashed 1px ${kAppColors.border(theme.palette.mode === "dark")}`,
    },
    label: {
        fontSize: 12,
        textAlign: "end",
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontWeight: 600,
        textTransform: "uppercase",
        marginBottom: 4,
    },
    value: {
        textAlign: "end",
        fontWeight: 600,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
    },
    total: {
        padding: 8,
        fontWeight: 600,
        fontSize: 20,
    },
}));

export interface IVisitEmployeesTotalSectionProps {
    timesheets?: CreateJobEmployeeTimesheetItemInput[],
    jobOfferSeats?: CreateJobOfferSeatInput[],
    visitData?: VisitDetailResponse | NullOrUndefined;
}

export default function VisitEmployeesTotalSection(props: IVisitEmployeesTotalSectionProps) {
    const {
        timesheets,
        jobOfferSeats,
        visitData,
    } = props;

    const {classes, cx} = useStyles();
    const appDataContext = useContext(AppDataContext);
    const {currency, language, isMobile, employeePermissionsMap} = appDataContext;

    return useMemo(() => {
        const employeeIds = visitData?.visit.repeatingDayData?.employeeIds ?? visitData?.visit.employeeIds;

        const theJobOfferSeats = visitData?.jobOfferSeat.filter(seat => !seat.completed && !seat.deleted && !seat.cancelled && seat.acceptedIds.length === 0);

        if ((!visitData || (visitData && (employeeIds?.length || theJobOfferSeats?.length))) && hasPermission(kPermissionsTimesheets, [kActionView], employeePermissionsMap)) {
            const stats = calculateTimesheets({
                timesheets: timesheets || visitData?.employeeTimesheet || [],
                countNotApproved: !visitData,
                filterByEmployeeIds: employeeIds,
                filterByJobEmployeeData: visitData ? visitData.employeeJobData : undefined,
                repeatingDay: visitData?.visit.repeatingDayData?.repeatingDay,
            });

            let totalHours = stats.hours;
            let totalMinutes = stats.minutes;

            jobOfferSeats?.forEach((value) => {
                totalHours += value.hours || 0;
                totalMinutes += value.minutes || 0;
            });
            theJobOfferSeats?.forEach((value) => {
                totalHours += value.hours || 0;
                totalMinutes += value.minutes || 0;
            });

            totalHours += Math.floor(totalMinutes / 60);
            totalMinutes = totalMinutes % 60;

            let totalMoney = stats.totalPrice;

            totalMoney += CalculateTotalPrice({
                timesheets: jobOfferSeats?.map(seat => ({
                    paymentType: paymentTypeToItemPaymentType(seat.paymentType),
                    hours: seat.hours,
                    minutes: seat.minutes,
                    hourRate: seat.hourRate,
                    fixedPrice: seat.fixedPrice,
                    approved: true,
                })) || theJobOfferSeats?.map(seat => ({
                    paymentType: paymentTypeToItemPaymentType(seat.paymentType),
                    hours: seat.hours,
                    minutes: seat.minutes,
                    hourRate: seat.hourRate,
                    fixedPrice: seat.fixedPrice,
                    approved: true,
                })) || [],
                currency,
                language,
            });

            const cellClassName = cx(
                classes.singleCell,
                isMobile ? classes.singleCellMobile : null
            );

            return (
                <Box className={classes.totalSectionContainerOuter}>
                    <Box className={classes.totalSectionContainerInner}>
                        <Box className={classes.singleRow}>
                            <Box className={cellClassName}>
                                <Typography className={classes.label}>
                                    {tt('workersTotalCostSection.time')}
                                </Typography>
                            </Box>

                            <Box className={cellClassName}>
                                <Typography className={classes.label}>
                                    {tt('workersTotalCostSection.distance')}
                                </Typography>
                            </Box>

                            <Box className={cellClassName}>
                                <Typography className={classes.label}>
                                    {tt('workersTotalCostSection.costs')}
                                </Typography>
                            </Box>
                        </Box>

                        <Box className={cx(classes.singleRow, classes.valuesRow)}>
                            {isMobile ? null : (
                                <Box className={cellClassName}>
                                    <Icons8Cost iconSmaller={true} red={true}/>
                                </Box>
                            )}

                            <Box className={cx(cellClassName, isMobile ? classes.singleCellWithArrow : undefined)}>
                                {!isMobile ? null : (
                                    <Icons8Cost iconSmaller={true} red={true}/>
                                )}

                                <Typography
                                    className={classes.value}>
                                    {HoursMinutesDisplayRaw({
                                        hours: totalHours,
                                        minutes: totalMinutes,
                                        returnZeroHoursInsteadOfUndefined: true,
                                        language,
                                    })}
                                </Typography>
                            </Box>

                            <Box className={cellClassName}>
                                <Typography
                                    className={classes.value}>
                                    {distanceDisplay(stats.travel.distance, language, true)}
                                </Typography>
                            </Box>

                            <Box className={cellClassName}>
                                <Typography
                                    className={classes.value}>
                                    {PriceDisplay(totalMoney, currency, language, true)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            );
        }

        return null;
    }, [timesheets, jobOfferSeats, visitData, currency, language, isMobile, employeePermissionsMap]);
}
