import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme} from "@mui/material";
import React, {Dispatch, SetStateAction, useContext, useEffect, useId, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import {AppDataContext} from "../../../AppData";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import {usePopupState} from "material-ui-popup-state/hooks";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppChip from "../../components/chips/AppChip";
import Icons8Download from "../../../icons/Icons8Download";
import TwoLineChip from "../../components/chips/TwoLineChip";
import Menu from "@mui/material/Menu";
import {bindMenu} from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import Icons8XlsExport from "../../../icons/Icons8XlsExport";
import {kTimeSheetsRoute, ViewMode} from "./TimeSheetsScreen";
import AppListItem from "../../components/listItems/AppListItem";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../service/UserService";
import {useParams, useSearchParams} from "react-router-dom";
import {DateTime} from "luxon";
import {
    ClientPureResponse,
    EmployeeJoinedUserResponse,
    GetEmployeeJoinedUserInput,
    GetVisitJoinedOthersTimesheetsInput,
    JobEmployeeDataResponse,
    JobEmployeeStatus,
    JobEmployeeTimesheetItemResponse,
    JobUpdateRepeats,
    VisitJoinedOthersTimesheetsResponsePage, VisitListPureJoinedOthersTimesheetsResponsePage, VisitListPureResponse,
    VisitRepeating,
    VisitStatus,
} from "../../../generated/graphql/graphql";
import {ErrorToast} from "../../../service/ToastService";
import {
    FilterJobEmployeeTimesheetItems,
    IVisitsDay,
    jobEmployeeTimesheetForVisitAndEmployee,
    ProcessVisitsEventsToDays,
    visitDateTimes,
    VisitNameOrSequenceId,
    VisitsProcess
} from "../../../service/VisitService";
import {calculateTimesheets, distanceDisplay, PriceDisplay} from "../../../service/CompanyService";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import AppIconButton from "../../components/buttons/AppIconButton";
import {processQueryError} from "../../../service/ErrorService";
import {
    IProcessTimesheetDataToExcelParams,
    isTravelTimesheet,
    isWorkTimesheet,
    processTimesheetDataToExcelOLD
} from "../../../service/TimesheetService";
import {SetVisitDetailModal} from "../../components/modals/AppModals";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {
    kActionCreate,
    kActionView,
    kPermissionsClients,
    kPermissionsTimesheetExport,
    kTopicCompanyEmployees,
    kTopicVisits,
    kUserPreferencesTimesheetsViewMode
} from "../../../core/constants";
import PermissionValid, {hasPermission} from "../../components/permissions/PermissionValid";
import {getBackRoute, hoursToHoursAndMinutes} from "../../../utils/Utils";
import ScheduleEvent from "../../../icons/ScheduleEvent";
import AppButton from "../../components/buttons/AppButton";
import AppListItemV2 from "../../components/listItems/ApplistItemV2";
import WorkerTimesheetModalBottomSheet from "../../components/modals/timesheet/WorkerTimesheetModalBottomSheet";
import FormBuilder, {IInputsData, InputType} from "../../components/form/FormBuilder";
import TimesheetsTotalChips from "../../components/timesheets/TimesheetsTotalChips";
import Icons8Waiting from "../../../icons/Icons8Waiting";
import IVisitEvent from "../../../model/VisitEvent";

export const kTimeSheetsDetailRoute = '/timesheets/employee/:employeeId';

export interface ITimeSheetsDetailRouteParams {
    employeeId: string | number;
    uriParams?: Record<string, any>;
    clientId?: number | string;
    viewMode?: number | string;
    selectedStatus?: string | null;
}

/**
 * Create this screen route with parameters.
 */
export function timeSheetsDetailRoute(params: ITimeSheetsDetailRouteParams): string {
    const {employeeId, uriParams, clientId, viewMode, selectedStatus} = params;

    let route = kTimeSheetsDetailRoute
        .replace(':employeeId', `${employeeId}`);

    let theUriParams = uriParams || {};

    if (clientId) {
        theUriParams.clientId = clientId;
    }

    if (viewMode) {
        theUriParams.viewMode = viewMode;
    }

    if (selectedStatus) {
        theUriParams.selectedStatus = selectedStatus;
    }

    if (Object.keys(theUriParams).length > 0) {
        route += `?${new URLSearchParams(theUriParams).toString()}`;
    }

    return route;
}

const useStyles = makeStyles()((theme: Theme) => ({
    chipsContainer: {
        display: "flex",
        overflowX: "auto",
        paddingTop: 8,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        'button': {
            flexShrink: 0,
            marginRight: 8,
            marginBottom: 8,
        }
    },
    listItemAmountText: {
        textAlign: "end",
        fontWeight: 600,
        fontSize: 13,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        'strong': {
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
        }
    },
    labelPadding: {
        paddingLeft: 16,
    },
    listItemChipsContainer: {
        paddingLeft: 70,
        display: "flex",
        flexWrap: "wrap",
    },
    singleChipContainer: {
        marginBottom: 4,
        marginRight: 4,
    },
}));

export default function TimeSheetsDetailScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, company, currency, language, setStorage, userPreferences} = appDataContext;

    const {employeeId} = useParams();
    const theEmployeeId = parseInt(employeeId!);

    const [searchParams, setSearchParams] = useSearchParams();
    const today = DateTime.now();
    const year = searchParams.get('year') ? parseInt(searchParams.get('year')!) : today.year;
    const month = searchParams.get('activeItem') ? parseInt(searchParams.get('activeItem')!) + 1 : today.month;
    const clientId = searchParams.get('clientId') ? parseInt(searchParams.get('clientId')!) : undefined;
    const viewMode = searchParams.get('viewMode') ? parseInt(searchParams.get('viewMode')!) as ViewMode : undefined;

    const monthDate = DateTime.fromObject({year, month});

    const [canSetValues, setCanSetValues] = useState<boolean>(true);
    const [viewInput, setViewInput] = useState<IInputsData>({
        viewMode: {
            type: InputType.ChipSwitch,
            label: '',
            labelBefore: tt('timesheetsDetail.screen.form.viewMode'),
            value: viewMode || ViewMode.Time,
            options: [
                {label: tt('timesheets.screen.time'), value: ViewMode.Time},
                {label: currency, value: ViewMode.Currency},
                {label: tt('timesheets.screen.distance'), value: ViewMode.Distance},
            ]
        },
    });
    const [selectedStatus, setSelectedStatus] = useState<JobEmployeeStatus | null>(searchParams.get('selectedStatus') ? searchParams.get('selectedStatus')! as JobEmployeeStatus : null);

    const [employeeLoading, setEmployeeLoading] = useState<boolean>(false);
    const [employeeData, setEmployeeData] = useState<EmployeeJoinedUserResponse | NullOrUndefined>();

    useEffect(() => {
        const subscription = subscribe(
            kTopicCompanyEmployees,
            {
                uri: '/company/employee/joined-user',
                params: {
                    employeeId: theEmployeeId,
                } as GetEmployeeJoinedUserInput,
                setLoading,
                onData: setEmployeeData,
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            (notifications: IEventSystemNotification[]) => {
                return notifications.some((notification) => {
                    return notification.data.id === theEmployeeId;
                })
            },
        );

        return () => {
            subscription.cancel();
        };
    }, [theEmployeeId]);

    const [loading, setLoading] = useState<boolean>(false);
    const [theData, setTheData] = useState<VisitListPureJoinedOthersTimesheetsResponsePage | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicVisits,
            {
                uri: '/job/visit/search-list-pure-joined-others-timesheets',
                params: {
                    companyId: companyId!,
                    fromDate: monthDate.startOf("month").toMillis(),
                    toDate: monthDate.endOf("month").toMillis(),
                } as GetVisitJoinedOthersTimesheetsInput,
                setLoading,
                onData: setTheData,
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            (notifications: IEventSystemNotification[]) => true,
        );

        return () => {
            subscription.cancel();
        };
    }, [companyId, year, month]);

    useEffect(() => {
        if (theData) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(theData.files || []),
                    ],
                };
            });
        }
    }, [theData]);

    useEffect(() => {
        setTitle(tt('timesheetsDetail.screen.title'));
    }, []);

    useEffect(() => {
        if (userPreferences.length > 0) {
            const prefsViewMode = userPreferences.find(item => item.key === kUserPreferencesTimesheetsViewMode);

            if (prefsViewMode && canSetValues) {
                setCanSetValues(false);

                setViewInput(prev => {
                    return {
                        ...prev,
                        viewMode: {
                            ...prev.viewMode,
                            value: viewMode || prefsViewMode.valueInt || ViewMode.Time,
                        },
                    };
                });
            }
        }
    }, [userPreferences]);

    const processedData: ITimesheetsDetailMonthData | undefined = useMemo(() => {
        if (employeeData && theData) {
            let newVisitsIds: number[] = [];
            let newLocationIds: number[] = [];
            let newPlacesIds: number[] = [];

            const visits = VisitsProcess({
                nonRepeating: theData.nonRepeating,
                repeating: theData.repeating,
                visitRepeatDays: theData.repeatingDayData,
                from: monthDate.startOf("month"),
                to: monthDate.endOf("month"),
                multipleEventsForMultiDay: true,
            }).filter((visit) => {
                const employeeIds = visit.visitRepeatDay?.employeeIds || visit.employeeIds;
                const visitStatus = visit.visitRepeatDay?.status || visit.status;

                const clientFilter = !clientId || visit.clientId === clientId;

                return employeeIds.includes(theEmployeeId) && visitStatus !== VisitStatus.Canceled && clientFilter;
            });

            let monthEmployeeData: JobEmployeeDataResponse[] = [];
            let monthTimesheets: JobEmployeeTimesheetItemResponse[] = [];
            let total = 0;
            let done = 0;
            let inProgress = 0;
            let travelling = 0;
            let scheduled = 0;
            let totalHours = 0;
            let doneHours = 0;
            let inProgressHours = 0;
            let onWayHours = 0;
            let scheduledHours = 0;
            let totalDistance = 0;
            let doneDistance = 0;
            let inProgressDistance = 0;
            let onWayDistance = 0;
            let scheduledDistance = 0;
            let visitValues: Record<string, number> = {};
            let workValues: Record<string, number> = {};
            let travelValues: Record<string, number> = {};
            let jobFilterByStatus: Record<string, boolean> = {};
            let employeeHours: Record<string, number> = {};
            let employeeMinutes: Record<string, number> = {};
            let employeeDistance: Record<string, number> = {};
            let visitIdsAnyNotApproved: string[] = [];

            if (visits.length > 0) {
                for (const visitOf of visits) {
                    if (!newVisitsIds.includes(visitOf.id)) {
                        newVisitsIds.push(visitOf.id);
                    } else if (visitOf.repeating === VisitRepeating.Never) {
                        continue;
                    }

                    if (visitOf.locationId && !newLocationIds.includes(visitOf.locationId)) {
                        newLocationIds.push(visitOf.locationId);
                    }

                    if (visitOf.locationPlaceId && !newPlacesIds.includes(visitOf.locationPlaceId)) {
                        newPlacesIds.push(visitOf.locationPlaceId);
                    }

                    const jobOfEmployeeData = theData.jobEmployeeData.filter((data) => {
                        if (data.employeeId !== theEmployeeId) {
                            return false;
                        }

                        if (visitOf.repeatingDay && !data.repeatingDay) {
                            const repeatingDayData = theData.jobEmployeeData.find((otherData) => {
                                return otherData.repeatingDay === visitOf.repeatingDay && otherData.visitId === visitOf.id && otherData.employeeId === theEmployeeId;
                            });

                            if (repeatingDayData) {
                                return false;
                            }
                        } else if (visitOf.repeatingDay && data.repeatingDay) {
                            return visitOf.repeatingDay === data.repeatingDay && data.visitId === visitOf.id;
                        }

                        return data.visitId === visitOf.id;
                    });

                    for (const jobOfEmployeeDataOf of jobOfEmployeeData) {
                        const existing = monthEmployeeData.find((data) => data.id === jobOfEmployeeDataOf.id);

                        if (!existing) {
                            monthEmployeeData.push(jobOfEmployeeDataOf);
                        }
                    }

                    const visitOfTimesheets = FilterJobEmployeeTimesheetItems({
                        jobEmployeeTimesheetItems: theData.timesheets,
                        repeatingDay: visitOf.repeatingDay,
                    }).filter((timesheet) => {
                        if (timesheet.employeeId !== theEmployeeId) {
                            return false;
                        }

                        return timesheet.visitId === visitOf.id;
                    });

                    const employeeData = jobOfEmployeeData.find((data) => data.employeeId === theEmployeeId)!;
                    let visitValue = 0;

                    let filterByStatus = false;
                    if (!selectedStatus) {
                        filterByStatus = true;
                    } else if (selectedStatus === JobEmployeeStatus.Done && employeeData.status === JobEmployeeStatus.Done) {
                        filterByStatus = true;
                    } else if (selectedStatus === JobEmployeeStatus.InProgress && employeeData.status === JobEmployeeStatus.InProgress) {
                        filterByStatus = true;
                    } else if (selectedStatus === JobEmployeeStatus.Scheduled && employeeData.status === JobEmployeeStatus.Scheduled) {
                        filterByStatus = true;
                    } else if (selectedStatus === JobEmployeeStatus.Travelling && employeeData.status === JobEmployeeStatus.Travelling) {
                        filterByStatus = true;
                    }
                    jobFilterByStatus[`${visitOf.dynamicId || visitOf.id}`] = filterByStatus;

                    for (const visitOfTimesheetOf of visitOfTimesheets) {
                        if (!visitOfTimesheetOf.approved) {
                            visitIdsAnyNotApproved.push(`${visitOf.dynamicId || visitOf.id}`);

                            continue;
                        }

                        const existing = monthTimesheets.find((timesheet) => timesheet.id === visitOfTimesheetOf.id);

                        if (employeeData.status !== JobEmployeeStatus.CanceledByWorker && employeeData.status !== JobEmployeeStatus.CanceledByManager) {
                            if (!existing) {
                                monthTimesheets.push(visitOfTimesheetOf);
                            }

                            total += (visitOfTimesheetOf.totalPrice || 0);
                            if (selectedStatus === null) {
                                visitValue += (visitOfTimesheetOf.totalPrice || 0);
                            }

                            switch (employeeData.status) {
                                case JobEmployeeStatus.Done:
                                    done += (visitOfTimesheetOf.totalPrice || 0);
                                    if (selectedStatus === JobEmployeeStatus.Done) {
                                        visitValue += (visitOfTimesheetOf.totalPrice || 0);
                                    }
                                    break;
                                case JobEmployeeStatus.InProgress:
                                    inProgress += (visitOfTimesheetOf.totalPrice || 0);
                                    if (selectedStatus === JobEmployeeStatus.InProgress) {
                                        visitValue += (visitOfTimesheetOf.totalPrice || 0);
                                    }
                                    break;
                                case JobEmployeeStatus.Travelling:
                                    travelling += (visitOfTimesheetOf.totalPrice || 0);
                                    if (selectedStatus === JobEmployeeStatus.Travelling) {
                                        visitValue += (visitOfTimesheetOf.totalPrice || 0);
                                    }
                                    break;
                                case JobEmployeeStatus.Scheduled:
                                    scheduled += (visitOfTimesheetOf.totalPrice || 0);
                                    if (selectedStatus === JobEmployeeStatus.Scheduled) {
                                        visitValue += (visitOfTimesheetOf.totalPrice || 0);
                                    }
                                    break;
                            }

                            employeeHours[`${visitOf.dynamicId || visitOf.id}`] = (employeeHours[`${visitOf.dynamicId || visitOf.id}`] || 0) + (visitOfTimesheetOf.hours || 0);
                            employeeMinutes[`${visitOf.dynamicId || visitOf.id}`] = (employeeMinutes[`${visitOf.dynamicId || visitOf.id}`] || 0) + (visitOfTimesheetOf.minutes || 0);

                            if (employeeMinutes[`${visitOf.dynamicId || visitOf.id}`] >= 60) {
                                employeeHours[`${visitOf.dynamicId || visitOf.id}`] = (employeeHours[`${visitOf.dynamicId || visitOf.id}`] || 0) + 1;
                                employeeMinutes[`${visitOf.dynamicId || visitOf.id}`] = (employeeMinutes[`${visitOf.dynamicId || visitOf.id}`] || 0) - 60;
                            }

                            let timesheetHours = 0;
                            if (employeeData.status === JobEmployeeStatus.Done) {
                                doneHours += visitOfTimesheetOf.hours || 0;
                                doneHours += (visitOfTimesheetOf.minutes || 0) / 60;

                                doneDistance += visitOfTimesheetOf.distance || 0;
                            } else if (employeeData.status === JobEmployeeStatus.InProgress) {
                                inProgressHours += visitOfTimesheetOf.hours || 0;
                                inProgressHours += (visitOfTimesheetOf.minutes || 0) / 60;

                                inProgressDistance += visitOfTimesheetOf.distance || 0;
                            } else if (employeeData.status === JobEmployeeStatus.Travelling) {
                                onWayHours += visitOfTimesheetOf.hours || 0;
                                onWayHours += (visitOfTimesheetOf.minutes || 0) / 60;

                                onWayDistance += visitOfTimesheetOf.distance || 0;
                            } else if (employeeData.status === JobEmployeeStatus.Scheduled) {
                                scheduledHours += visitOfTimesheetOf.hours || 0;
                                scheduledHours += (visitOfTimesheetOf.minutes || 0) / 60;

                                scheduledDistance += visitOfTimesheetOf.distance || 0;
                            }

                            timesheetHours = visitOfTimesheetOf.hours || 0;
                            timesheetHours += (visitOfTimesheetOf.minutes || 0) / 60;
                            totalHours += timesheetHours;
                            totalDistance += visitOfTimesheetOf.distance || 0;

                            const stats = calculateTimesheets({
                                timesheets: [visitOfTimesheetOf],
                            });

                            employeeDistance[`${visitOf.dynamicId || visitOf.id}`] = (employeeDistance[`${visitOf.dynamicId || visitOf.id}`] || 0) + stats.travel.distance;

                            if (isWorkTimesheet(visitOfTimesheetOf.paymentType)) {
                                workValues[`${visitOf.dynamicId || visitOf.id}`] = (workValues[`${visitOf.dynamicId || visitOf.id}`] || 0) + (visitOfTimesheetOf.totalPrice || 0);
                            } else if (isTravelTimesheet(visitOfTimesheetOf.paymentType)) {
                                travelValues[`${visitOf.dynamicId || visitOf.id}`] = (travelValues[`${visitOf.dynamicId || visitOf.id}`] || 0) + (visitOfTimesheetOf.totalPrice || 0);
                            } else {
                                console.error('Unknown payment type', visitOfTimesheetOf.paymentType);
                            }
                        }
                    }

                    visitValues[`${visitOf.dynamicId || visitOf.id}`] = visitValue;
                }
            }

            return {
                days: ProcessVisitsEventsToDays(
                    visits.filter((visit) => {
                        return jobFilterByStatus[`${visit.dynamicId || visit.id}`];
                    }),
                ),
                monthEmployeeData,
                monthTimesheets,
                total,
                done,
                inProgress,
                travelling,
                scheduled,
                visitValues,
                workValues,
                travelValues,
                employeeHours,
                employeeMinutes,
                employeeDistance,
                visitIdsAnyNotApproved,
                totalHours: totalHours,
                doneHours: doneHours,
                onWayHours: onWayHours,
                inProgressHours: inProgressHours,
                scheduledHours: scheduledHours,
                totalDistance,
                doneDistance,
                inProgressDistance,
                onWayDistance,
                scheduledDistance,
            };
        }

        return undefined;
    }, [employeeData, theData, selectedStatus, clientId]);

    /**
     * Export current data to excel.
     */
    const exportXLS = () => {
        const params: IProcessTimesheetDataToExcelParams = {
            year,
            month,
            employee: employeeData,
            jobEmployeeData: theData?.jobEmployeeData,
            monthData: processedData,
            currency,
            clients: theData?.clients,
            locations: theData?.locations,
            places: theData?.places,
            language: language,
            jobs: theData?.jobs,
            companyHasVat: company?.hasVat || false,
        };

        try {
            processTimesheetDataToExcelOLD(params);
        } catch (error) {
            console.error(error);
            ErrorToast(tt('common.export.error'));
        }
    };

    /**
     * Open Visit detail modal for Visit.
     */
    const onOpenVisitDetail = (visitId: number, repeatingDay?: number) => {
        SetVisitDetailModal(appDataContext, {
            open: true,
            visitId: visitId,
            repeatingDay: repeatingDay,
            canNavigateToJob: true,
            preselectSelectedTab: 'workers',
        });
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMobile={isMobile}
                monthDate={monthDate}
                employee={employeeData}
                monthData={processedData}
                data={theData}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                exportXLS={exportXLS}
                onOpenVisitDetail={onOpenVisitDetail}
                loading={(employeeLoading || loading) && !processedData}
                viewInput={viewInput}
                setViewInput={setViewInput}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />
        </>
    );
}

export interface ITimesheetsDetailMonthData {
    days: IVisitsDay[];
    monthEmployeeData: JobEmployeeDataResponse[];
    monthTimesheets: JobEmployeeTimesheetItemResponse[];
    totalHours: number;
    doneHours: number;
    inProgressHours: number;
    onWayHours: number;
    scheduledHours: number;
    total: number;
    done: number;
    inProgress: number;
    scheduled: number;
    travelling: number;
    totalDistance: number;
    doneDistance: number;
    inProgressDistance: number;
    onWayDistance: number;
    scheduledDistance: number;
    visitValues: Record<string, number>;
    workValues: Record<string, number>;
    travelValues: Record<string, number>;
    employeeHours: Record<string, number>;
    employeeMinutes: Record<string, number>;
    employeeDistance: Record<string, number>;
    visitIdsAnyNotApproved: string[];
}

interface IBodyProps {
    isMobile?: boolean;
    loading?: boolean;
    monthDate: DateTime;
    employee?: EmployeeJoinedUserResponse | NullOrUndefined;
    monthData?: ITimesheetsDetailMonthData;
    data: VisitListPureJoinedOthersTimesheetsResponsePage | NullOrUndefined;
    selectedStatus: JobEmployeeStatus | null;
    setSelectedStatus: React.Dispatch<React.SetStateAction<JobEmployeeStatus | null>>;
    exportXLS: VoidFunction;
    onOpenVisitDetail: (visitId: number, repeatingDay?: number) => void;
    viewInput: IInputsData;
    setViewInput: Dispatch<SetStateAction<IInputsData>>;
}

function Body(props: IBodyProps) {
    const {
        isMobile,
        monthDate,
        employee,
        monthData,
        data,
        selectedStatus,
        setSelectedStatus,
        exportXLS,
        onOpenVisitDetail,
        loading,
        viewInput,
        setViewInput,
    } = props;

    const viewMode = parseInt(viewInput.viewMode.value) as ViewMode;

    const downloadMenuState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const appDataContext = useContext(AppDataContext);
    const {currency, language, employeePermissionsMap, storage} = appDataContext;

    const {classes, cx} = useStyles();

    const [timesheetModal, setTimesheetModal] = useState<boolean>(false);
    const [timesheetModalProps, setTimesheetModalProps] = useState<ITimesheetModalProps | undefined>(undefined);

    const itemsJSX = useMemo(() => {
        if (monthData?.days && data) {
            return monthData.days.map((day) => {
                return (
                    <div key={day.date.toMillis()}>
                        {day.visits.map((visit) => {
                            const client = data?.clients.find(client => client.id === visit.clientId);

                            const theClientName = hasPermission(kPermissionsClients, [kActionView], employeePermissionsMap) ? client?.name : undefined;

                            let descriptionJSX = theClientName ? `${theClientName}` : '';
                            const dateTimes = visitDateTimes(visit, visit.visitRepeatDay, visit.repeatingDay, visit);

                            if (!dateTimes.isSingleDay) {
                                descriptionJSX = `${DateTime.fromMillis(visit.startDate).toFormat('d.M.yyyy')} - ${DateTime.fromMillis(visit.endDate).toFormat('d.M.yyyy')}`;

                                if (theClientName) {
                                    descriptionJSX += ` · ${theClientName}`;
                                }
                            } else {
                                descriptionJSX = `${dateTimes.start.toFormat('d.M.yyyy')}`;

                                if (theClientName) {
                                    descriptionJSX += ` · ${theClientName}`;
                                }
                            }

                            const timesheets = jobEmployeeTimesheetForVisitAndEmployee(
                                visit.id,
                                visit.repeatingDay,
                                employee!.id,
                                data.timesheets,
                            );

                            const anyNotApproved = monthData.visitIdsAnyNotApproved.includes(`${visit.dynamicId || visit.id}`);

                            return (
                                <AppListItemV2
                                    key={visit.dynamicId || visit.id}
                                    variant={"smaller-title"}
                                    title={VisitNameOrSequenceId(visit, visit.visitRepeatDay)}
                                    description={descriptionJSX}
                                    customAvatarInCircle={<ScheduleEvent/>}
                                    belowListItemWidget={<Box className={classes.listItemChipsContainer}>
                                        <TimesheetsTotalChips
                                            timesheets={timesheets}
                                            hideZeroType={true}
                                        />

                                        {anyNotApproved ? (
                                            <Box className={classes.singleChipContainer}>
                                                <AppButton
                                                    onClick={(e) => {
                                                        e.stopPropagation();

                                                        setTimesheetModalProps({
                                                            visitId: visit.id,
                                                            repeatingDay: visit.repeatingDay,
                                                            employee: employee!,
                                                            employeeJobData: monthData.monthEmployeeData.find(data => data.id === employee!.id),
                                                            visitEvent: visit,
                                                            client: client,
                                                        });

                                                        setTimesheetModal(true);
                                                    }}
                                                    variant={"containedGreyThin30"}>
                                                    <Box color={kAppColors.primary.main} ml={-1}>
                                                        <Icons8Waiting/>
                                                    </Box>
                                                    {tt('timesheet.status.waitingForApproval')}
                                                </AppButton>
                                            </Box>
                                        ) : undefined}

                                    </Box>}
                                    actionWidget={(<></>)}
                                    onClick={() => onOpenVisitDetail(visit.id, visit.repeatingDay)}
                                />
                            );
                        })}
                    </div>
                );
            });
        }

        return null;
    }, [monthData, selectedStatus, data, employee]);

    const contentJSX = loading ? <DetailScreenShimmer/> : <>

        <AppListItem
            profileImage={
                UserPhotoUrl(employee?.user, data?.files, storage.publicUrlsForFiles)
            }
            title={UserFullName(employee?.name || employee?.user?.name, employee?.surname || employee?.user?.surname)}
            description={UserRoleTitle(employee?.role)}
            actionWidget={<></>}
        />

        <Box sx={{mb: 2}}/>

        <Divider/>

        <Box pr={2} pl={2} pt={1}>
            <FormBuilder inputs={viewInput} setInputs={setViewInput}/>
        </Box>

        <Box className={cx('styledScrollbar', classes.chipsContainer)}>
            {viewMode === ViewMode.Time ? (
                <>
                    <StatusChip
                        status={null}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.total')}
                        price={hoursToHoursAndMinutes(monthData?.totalHours || 0)}
                    />

                    <StatusChip
                        status={JobEmployeeStatus.Done}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.done')}
                        price={hoursToHoursAndMinutes(monthData?.doneHours || 0)}
                    />

                    <StatusChip
                        status={JobEmployeeStatus.InProgress}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.inProgress')}
                        price={hoursToHoursAndMinutes(monthData?.inProgressHours || 0)}
                    />

                    <StatusChip
                        status={JobEmployeeStatus.Travelling}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.travelling')}
                        price={hoursToHoursAndMinutes(monthData?.onWayHours || 0)}
                    />

                    <StatusChip
                        status={JobEmployeeStatus.Scheduled}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.scheduled')}
                        price={hoursToHoursAndMinutes(monthData?.scheduledHours || 0)}
                    />
                </>
            ) : null}

            {viewMode === ViewMode.Currency ? (
                <>
                    <StatusChip
                        status={null}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.total')}
                        price={PriceDisplay(monthData?.total || 0, currency, language, true) || '0'}
                    />

                    <StatusChip
                        status={JobEmployeeStatus.Done}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.done')}
                        price={PriceDisplay(monthData?.done || 0, currency, language, true) || '0'}
                    />

                    <StatusChip
                        status={JobEmployeeStatus.InProgress}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.inProgress')}
                        price={PriceDisplay(monthData?.inProgress || 0, currency, language, true) || '0'}
                    />

                    <StatusChip
                        status={JobEmployeeStatus.Travelling}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.travelling')}
                        price={PriceDisplay(monthData?.travelling || 0, currency, language, true) || '0'}
                    />

                    <StatusChip
                        status={JobEmployeeStatus.Scheduled}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.scheduled')}
                        price={PriceDisplay(monthData?.scheduled || 0, currency, language, true) || '0'}
                    />
                </>
            ) : null}

            {viewMode === ViewMode.Distance ? (
                <>
                    <StatusChip
                        status={null}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.total')}
                        price={distanceDisplay(monthData?.totalDistance || 0, language, true)}
                    />

                    <StatusChip
                        status={JobEmployeeStatus.Done}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.done')}
                        price={distanceDisplay(monthData?.doneDistance || 0, language, true)}
                    />

                    <StatusChip
                        status={JobEmployeeStatus.InProgress}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.inProgress')}
                        price={distanceDisplay(monthData?.inProgressDistance || 0, language, true)}
                    />

                    <StatusChip
                        status={JobEmployeeStatus.Travelling}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.travelling')}
                        price={distanceDisplay(monthData?.onWayDistance || 0, language, true)}
                    />

                    <StatusChip
                        status={JobEmployeeStatus.Scheduled}
                        selectedStatus={selectedStatus}
                        setStatus={setSelectedStatus}
                        text={tt('common.scheduled')}
                        price={distanceDisplay(monthData?.scheduledDistance || 0, language, true)}
                    />
                </>
            ) : null}
        </Box>
        {itemsJSX}
    </>;

    const thisTitle = monthDate.toFormat('LLLL yyyy', {locale: language});

    return (
        <>
            <ScreenContent appBar={!isMobile}
                           noContentPadding={isMobile}
                           navigationDrawer={!isMobile}
                           bottomBar={isMobile}
                           centerHorizontally={true}>
                <AppPaper
                    sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                    <PaperAppbar
                        isMobile={isMobile}
                        title={thisTitle.charAt(0).toUpperCase() + thisTitle.slice(1)}
                        backRoute={getBackRoute(kTimeSheetsRoute)}>
                        <PermissionValid
                            permission={kPermissionsTimesheetExport}
                            requiredPermissions={[kActionCreate]}
                        >
                            {isMobile
                                ?
                                <AppIconButton
                                    variant={"primaryBg"}
                                    key={'downloadTimesheetMobileKey'}
                                    onClick={(e) => downloadMenuState.open(e)}
                                >
                                    <Icons8Download/>
                                </AppIconButton> :
                                <AppChip
                                    onClick={(e) => downloadMenuState.open(e)}
                                    key={'downloadTimesheetKey'}
                                    label={tt('common.download')}
                                    icon={<Icons8Download/>}
                                />}
                        </PermissionValid>
                    </PaperAppbar>

                    {contentJSX}

                    <Box sx={{mb: 2}}/>
                </AppPaper>

                <Menu {...bindMenu(downloadMenuState)}>
                    <PermissionValid
                        permission={kPermissionsTimesheetExport}
                        requiredPermissions={[kActionCreate]}
                    >
                        <MenuItem
                            onClick={() => {
                                downloadMenuState.close();

                                exportXLS();
                            }
                            }>
                            <Icons8XlsExport/>
                            {tt('timesheets.screen.downloadMenu.excel')}
                        </MenuItem>
                    </PermissionValid>
                </Menu>
            </ScreenContent>

            {timesheetModalProps ? (
                <WorkerTimesheetModalBottomSheet
                    variant="edit"
                    open={timesheetModal}
                    setOpen={setTimesheetModal}
                    employee={timesheetModalProps.employee}
                    files={data?.files || []}
                    visitId={timesheetModalProps.visitId}
                    repeatingDay={timesheetModalProps.repeatingDay}
                    repeats={JobUpdateRepeats.Single}
                    employeeJobData={timesheetModalProps.employeeJobData}
                    onUpdateVisitId={() => {
                        //do nothing, should not update chain visits
                    }}
                    canOnlyApprove={true}
                    visitEvent={timesheetModalProps.visitEvent}
                    client={timesheetModalProps.client}
                />
            ) : null}
        </>
    );
}

interface IStatusChipProps {
    status: JobEmployeeStatus | null;
    selectedStatus: JobEmployeeStatus | null;
    setStatus: React.Dispatch<React.SetStateAction<JobEmployeeStatus | null>>;
    text: string;
    price: string;
}

/**
 * Status selection chip component.
 */
function StatusChip(props: IStatusChipProps) {
    const {status, selectedStatus, setStatus, text, price} = props;

    return (
        <TwoLineChip
            onClick={() => setStatus(status)}
            variant={status === selectedStatus ? "primary" : "secondary"}
            text1={text}
            text2={price}
        />
    );
}

export interface ITimesheetModalProps {
    visitId: number;
    repeatingDay: number | undefined;
    employee: EmployeeJoinedUserResponse;
    employeeJobData?: JobEmployeeDataResponse;
    visitEvent: IVisitEvent;
    client?: ClientPureResponse;
}
