import {makeStyles} from "tss-react/mui";
import {BottomNavigation, BottomNavigationAction, Box, Theme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {tt} from "../../../core/Localization";
import React, {useContext, useState} from "react";
import AddIcon from "../../../icons/AddIcon";
import HomeIcon from "../../../icons/HomeIcon";
import Icons8Contacts from "../../../icons/Icons8Contacts";
import Icons8Menu from "../../../icons/Icons8Menu";
import {kDashboardRoute} from "../../screens/dashboard/DashboardScreen";
import {kMobileMenuRoute} from "../../screens/mobile/MobileMenuScreen";
import {kClientsRoute} from "../../screens/clients/ClientsScreen";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import ModalBottomSheet from "../ModalBottomSheet";
import MobileMenuItem from "../MobileMenuItem";
import CreateWorkerModal from "../workers/CreateWorkerModal";
import WorkerIcon from "../../../icons/WorkerIcon";
import UserIcon from "../../../icons/UserIcon";
import HammerIcon from "../../../icons/HammerIcon";
import CloseIcon from "../../../icons/CloseIcon";
import {AppContext} from "../../../App";
import {AppDataContext} from "../../../AppData";
import CreateClientModal from "../clients/CreateClientModal";
import {EmployeeRole} from "../../../generated/graphql/graphql";
import Icons8AddShoppingCart2 from "../../../icons/Icons8AddShoppingCart2";
import {DisplaySubscriptionUpgradeModal} from "../../../service/CompanyService";
import {kVisitsRoute} from "../../screens/jobs/visits/VisitsScreen";
import ScheduleEvent from "../../../icons/ScheduleEvent";
import {newJobAndVisitRoute} from "../../screens/jobs/NewJobAndVisitScreen";
import PermissionValid from "../permissions/PermissionValid";
import {
    kActionCreate,
    kActionUpdate,
    kPermissionsClients,
    kPermissionsCompanySubscription,
    kPermissionsJobs,
    kPermissionsWorkers
} from "../../../core/constants";


const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        borderTop: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`,
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        height: 56,
        zIndex: 5,
    },
    addButton: {
        color: kAppColors.text.tertiary(theme.palette.mode === "dark"),
        background: kAppColors.primary.main,
        borderRadius: 40,
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
    },
    redMenuItem: {
        'svg, span.MuiListItemText-primary': {
            color: kAppColors.red.confirmButton,
        }
    },
    selected: {
        color: kAppColors.primary.main,
    },
    menuItemShowLabel: {
        paddingTop: 0,
        '.MuiBottomNavigationAction-label': {
            opacity: 1,
        }
    }
}));

export default function BottomBar() {
    const appContext = useContext(AppContext);
    const {authUser} = appContext;
    const navigate = useNavigate();

    const appDataContext = useContext(AppDataContext);
    const {companyId, companyData, companySubscriptionStatus} = appDataContext;

    const {classes, cx} = useStyles();

    const [bottomSheet, setBottomSheet] = useState<boolean>(false);
    const [createWorkerModal, setCreateWorkerModal] = useState<boolean>(false);
    const [createClientModal, setCreateClientModal] = useState<boolean>(false);

    const currentUsers = companySubscriptionStatus?.currentUsers || 0;
    const maxUsers = companySubscriptionStatus?.maxUsers || 0;
    const showUpgradeButton = currentUsers >= maxUsers;

    function openCreateClientModal() {
        setBottomSheet(false);
        setCreateClientModal(true);
    }

    const noCompaniesMode = !companyId && authUser && authUser.signInResponse && authUser.signInResponse.data && authUser.signInResponse.data.companyIds.length == 0;

    function isSelected(route: string, exact = false): string | undefined {
        let isSelected = (exact && route === window.location.pathname) || (!exact && window.location.pathname.indexOf(route) === 0);
        return isSelected ? classes.selected : undefined;
    }

    const isWorker = appDataContext.companyEmployeeRole === EmployeeRole.Worker;

    const hasCompanyItemsJSX = noCompaniesMode || isWorker || !companyData.subscriptionIsValid ? null : [
        <PermissionValid
            key={'bottomBarJobsItem'}
            permission={kPermissionsJobs}
        >
            <BottomNavigationAction
                className={cx(isSelected(kVisitsRoute, true), classes.menuItemShowLabel)}
                onClick={() => {
                    navigate(kVisitsRoute)
                }
                }
                label={tt('common.visits')}
                icon={<ScheduleEvent/>}
            />
        </PermissionValid>,
        <BottomNavigationAction
            key={'bottomBarAddItem'}
            onClick={() => {
                setBottomSheet(true);
            }} icon={<Box className={classes.addButton}><AddIcon/></Box>}
        />,
        <PermissionValid
            key={'bottomBarClientsItem'}
            permission={kPermissionsClients}
        >
            <BottomNavigationAction
                className={cx(isSelected(kClientsRoute), classes.menuItemShowLabel)}
                onClick={() => navigate(kClientsRoute)}
                label={tt('common.clients')}
                icon={<Icons8Contacts/>}
            />
        </PermissionValid>
    ];

    return (
        <>
            <BottomNavigation
                showLabels={true}
                className={classes.container}
                id="bottom-bar"
            >
                <BottomNavigationAction
                    className={isSelected(kDashboardRoute, true)}
                    onClick={() => navigate(kDashboardRoute)}
                    label={tt('common.home')}
                    icon={<HomeIcon/>}
                />
                {hasCompanyItemsJSX}
                <BottomNavigationAction
                    className={isSelected(kMobileMenuRoute)}
                    onClick={() => navigate(kMobileMenuRoute)}
                    label={tt('common.menu')}
                    icon={<Icons8Menu/>}/>
            </BottomNavigation>

            <CreateWorkerModal
                setOpen={setCreateWorkerModal} open={createWorkerModal}/>
            <CreateClientModal setOpen={setCreateClientModal} open={createClientModal}/>

            <ModalBottomSheet open={bottomSheet} setOpen={setBottomSheet} children={
                <Box>
                    {showUpgradeButton ? (
                        <PermissionValid
                            permission={kPermissionsCompanySubscription}
                            requiredPermissions={[kActionUpdate]}
                        >
                            <MobileMenuItem
                                title={tt('menu.subscription_upgrade')}
                                icon={<Icons8AddShoppingCart2/>}
                                onClick={() => {
                                    setBottomSheet(false);

                                    DisplaySubscriptionUpgradeModal(
                                        appDataContext,
                                        currentUsers,
                                        maxUsers,
                                    );
                                }}
                                actionWidget={<></>}
                            />
                        </PermissionValid>
                    ) : (
                        <PermissionValid
                            permission={kPermissionsWorkers}
                            requiredPermissions={[kActionCreate]}
                        >
                            <MobileMenuItem
                                title={tt('menu.create_worker')}
                                icon={<WorkerIcon/>}
                                onClick={() => {
                                    setBottomSheet(false);
                                    setCreateWorkerModal(true);
                                }}
                                actionWidget={<></>}
                            />
                        </PermissionValid>
                    )}

                    <PermissionValid
                        permission={kPermissionsJobs}
                        requiredPermissions={[kActionCreate]}
                    >
                        <MobileMenuItem
                            title={tt('menu.create_job')}
                            icon={<HammerIcon/>}
                            onClick={() => {
                                navigate(newJobAndVisitRoute({}));
                            }}
                            actionWidget={<></>}
                        />
                    </PermissionValid>

                    <PermissionValid
                        permission={kPermissionsClients}
                        requiredPermissions={[kActionCreate]}
                    >
                        <MobileMenuItem
                            title={tt('menu.create_client')}
                            icon={<UserIcon/>}
                            onClick={() => {
                                openCreateClientModal();
                            }}
                            actionWidget={<></>}/>
                    </PermissionValid>

                    <MobileMenuItem
                        className={classes.redMenuItem}
                        title={tt('common.close')}
                        icon={<CloseIcon/>}
                        onClick={() => {
                            setBottomSheet(false);
                        }}
                        actionWidget={<></>}
                    />
                </Box>
            }
            />
        </>
    );
}
