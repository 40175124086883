import { FlavorByEnvironment } from "../flavor-config";
import {CompanyResponse, EmployeeJoinedUserResponse, SubscriptionResponse} from "../generated/graphql/graphql";
import {DateTime} from "luxon";
import { UserFullName } from "./UserService";
import { trackDataLayerEvent } from "./AnalyticsService";

/**
 * Check if is trial Subscription.
 * Trial is hard-coded Sub with ID = 1.
 */
export function isTrialSubscription(subscription: SubscriptionResponse | NullOrUndefined): boolean {
    if (!subscription) {
        return false;
    }

    if (subscription.isTemplate && subscription.id === 1) {
        return true;
    }

    return subscription.templateId === 1 && !subscription.isTemplate;
}

/**
 * Check Subscription validFrom and validTo to determine if is valid Subscription.
 */
export function isValidSubscription(subscription: SubscriptionResponse | NullOrUndefined): boolean {
    if (!subscription) {
        return false;
    }

    if (subscription.validFrom && subscription.validTo) {
        const now = DateTime.now();
        const from = DateTime.fromMillis(subscription.validFrom);
        const to = DateTime.fromMillis(subscription.validTo);

        return now >= from && now <= to;
    }

    return false;
}

/**
 * Calculate Subscription left as percentage.
 */
export function GetSubscriptionLeftAsPercentage(start: DateTime, end: DateTime){
    const subscriptionDiff = end.diff(start).milliseconds;
    const subscriptionStartNowDiff = DateTime.now().diff(start).milliseconds;

    const leftAsPercentage = subscriptionDiff > subscriptionStartNowDiff ? (100 - (subscriptionStartNowDiff / (subscriptionDiff / 100))) : 0;

    return Math.min(Math.max(leftAsPercentage, 0), 100);
}

/**
 * Calculate number of days left in Subscription.
 * At minimum 0.
 */
export function GetSubscriptionDaysLeft(subscription: SubscriptionResponse | NullOrUndefined) {
    if (!subscription) {
        return 0;
    }

    if (subscription.validFrom && subscription.validTo) {
        const now = DateTime.now();
        const to = DateTime.fromMillis(subscription.validTo);

        return Math.max(Math.round(to.diff(now, 'days').days), 0);
    }

    return 0;
}

/**
 * Calculate number of seconds from start of Subscription.
 * At minimum 0.
 */
export function GetSubscriptionSecondsFromStart(subscription: SubscriptionResponse | NullOrUndefined) {
    if (!subscription) {
        return 0;
    }

    if (subscription.validFrom && subscription.validTo) {
        const now = DateTime.now();
        const from = DateTime.fromMillis(subscription.validFrom);

        return Math.max(Math.round(now.diff(from, 'seconds').seconds), 0);
    }

    return 0;
}

/**
 * Open subscription buy form in new tab.
 * Add company/user data to the link to prefill fields.
 */
/*export function openSubscriptionBuyForm(company: CompanyResponse, user: EmployeeJoinedUserResponse) {
    trackDataLayerEvent('purchase_btn_click');

    const config = FlavorByEnvironment();

    let url = config!.buySubscriptionForm;

    url = `${url}?entry.881903931=${company.name}`;

    url = `${url}&entry.205046117=${UserFullName(user.user?.name ?? user.name, user.user?.surname ?? user.surname)}`;

    url = `${url}&entry.754157710=${user.user?.phoneNumber ?? ''}`;

    url = `${url}&entry.824666102=${user.user?.contactEmail ?? user.user?.email ?? ''}`;

    window.open(url, '_blank');
}*/

/**
 * Open trial extension request form in browser.
 * Add company/user data to the link to prefill fields.
 */
export function openTrialExtensionRequestForm(company: CompanyResponse, user: EmployeeJoinedUserResponse) {
    trackDataLayerEvent('extend_trial_btn_click');

    const config = FlavorByEnvironment();

    let url = config!.trialExtensionRequestForm;

    url = `${url}?entry.228698363=${company.name}`;

    url = `${url}&entry.717400182=${UserFullName(user.user?.name ?? user.name, user.user?.surname ?? user.surname)}`;

    url = `${url}&entry.1706350070=${user.user?.phoneNumber ?? ''}`;

    url = `${url}&entry.1944718722=${user.user?.contactEmail ?? user.user?.email ?? ''}`;

    window.open(url, '_blank');
}
