import {JobEmployeeStatus} from "../../generated/graphql/graphql";

export default interface INotification {
    id?: string;
    event: NotificationEvent;
    title: string;
    text?: string;
    companyId: number;
    sourceUserId?: number;
    sourceEmployeeId?: number;
    sourceUserFullName?: string;
    forUserId?: number;
    forEmployeeId?: number;
    visitId?: number;
    jobId?: number;
    repeatingDay?: number;
    photoUrl?: string;
    firebaseFileUuid?: string;
    firebaseFilePath?: string;
    read: boolean;
    createdAt: number;
    employeeStatus?: JobEmployeeStatus;
    previousEmployeeStatus?: JobEmployeeStatus;
    jobName?: string;
    visitName?: string;
    data?: any;
    targetDeleted?: boolean;
}

export enum NotificationEvent {
    None,
    EmployeeUpdateEmployeeStatus, // responsible + workers on Visit
    JobFormElementDoneStateChangedByWorker, // responsible + workers on Visit
    EmployeeResponseToJobOfferSeatAccepted, // responsible + workers on Visit
    EmployeeResponseToJobOfferSeatRejected, // responsible + workers on Visit
    RemoveSubstituteFromJobOfferSeat, // responsible + workers on Visit
    AddDefectToJob, // responsible + workers on Visit
    LayoffEmployeeBySelf, // admin + manager
    ConsumeInvite, // admin + manager
    JobOfferSeatNotFilledNotification, // responsible
    AddJobEmployeeTimesheetItemByWorker, // responsible
    UpdateJobEmployeeTimesheetItemByWorker, // responsible
    AddProtocolToJob, // responsible + workers on Visit
    AddMaterialToVisit, // responsible + workers on Visit
    AddProductToVisit, // responsible + workers on Visit
    CreateVisitForJob, // responsible + workers on Visit
    ScheduleVisitForJob, // responsible + workers on Visit
    CancelVisitByManager, // responsible + workers on Visit
    ReactivateVisit, // responsible + workers on Visit
    DeleteVisit, // responsible + workers on Visit
    VisitStatusToSolve, // responsible
    AddOutputDocumentToVisit, // responsible
    DeleteOutputDocumentFromVisit, // responsible
}

export const kNotificationEventsForEmployeeTab = [
    NotificationEvent.EmployeeUpdateEmployeeStatus,
    NotificationEvent.EmployeeResponseToJobOfferSeatAccepted,
    NotificationEvent.EmployeeResponseToJobOfferSeatRejected,
    NotificationEvent.RemoveSubstituteFromJobOfferSeat,
    NotificationEvent.JobOfferSeatNotFilledNotification,
    NotificationEvent.AddJobEmployeeTimesheetItemByWorker,
    NotificationEvent.UpdateJobEmployeeTimesheetItemByWorker,
];

export const kNotificationEventsForFormsTab = [
    NotificationEvent.JobFormElementDoneStateChangedByWorker,
];

export const kNotificationEventsForProtocolsTab = [
    NotificationEvent.AddDefectToJob,
    NotificationEvent.AddProtocolToJob,
];

export const kNotificationEventsForOutputDocumentsTab = [
    NotificationEvent.AddOutputDocumentToVisit,
    NotificationEvent.DeleteOutputDocumentFromVisit,
];
